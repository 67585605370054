










































































































import { computed, defineComponent, PropType, reactive } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import I18n from '@/locales/I18n'
import PasswordWithToggleButtonParts from '@/components/common/form/PasswordWithToggleButtonParts.vue'
import ErrorMessageParts from '@/components/LoginPage/LoginPane/parts/ErrorMessageParts.vue'
import AtomInputEMail from '@/components/atoms/input/AtomInputEMail.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import SecureKeyStore from '@/util/secureKeyStore/SecureKeyStore'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import { LoginType } from '@/components/LoginPage/LoginPane.vue'
import DeviceInfo from '@/util/DeviceInfo'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import AtomLinkButton from '@/components/atoms/AtomLinkButton.vue'

/**
 * ログイン画面のログインフィールドセクション。
 */
export default defineComponent({
  name: 'LoginSection',
  components: {
    AtomLinkButton,
    AtomRouterButton,
    AtomInputButton,
    AtomInputEMail,
    PasswordWithToggleButtonParts,
    ErrorMessageParts,
  },
  props: {
    /**
     * ログインタイプ指定
     */
    loginType: {
      type: String as PropType<LoginType>,
      default: 'login',
    },
    /**
     * ログインエラーメッセージを表示するかどうか
     */
    showLoginError: {
      type: Boolean,
      default: false,
    },
    /**
     * ログイン時にネットワークエラーが発生したかどうか
     */
    showNetworkError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    /** 入力中のログインIDとパスワード */
    const loginSectionState = reactive({
      loginName: '',
      password: '',
    })

    /**
     * セキュアキーストアに保存されている認証情報を取得する。
     * @return credentialType 認証情報
     */
    const loadCredentials = async () => {
      const credential = await SecureKeyStore.getItem('sfgoUserCredential')
      if (credential) {
        loginSectionState.loginName = credential.loginId
        loginSectionState.password = credential.password
      }
      return credential
    }

    const submitText =
      props.loginType === 'login' || props.loginType === 'notification'
        ? I18n.tc('LoginPage.login')
        : I18n.tc('MypagePage.Login')
    const submitButtonTheme =
      props.loginType === 'login' || props.loginType === 'notification' ? 'dark' : 'light'

    /** パスワードリセット画面への遷移設定 */
    const passwordResetUrl = computed(
      () =>
        `${process.env.VUE_APP_BROWSER_BASE_URL}#/password-reset/${
          DeviceInfo.isCordova() ? 'returnApp' : ''
        }`,
    )

    /** SFgo-1040：iOSアプリの場合、ログイン画面から新規会員登録を無くす対応 */
    const isIosDevice = computed(() => DeviceInfo.isiOS())

    const isApp = DeviceInfo.isCordova()

    /** 新規登録画面への遷移設定 */
    const signupUrl = computed(() => `${process.env.VUE_APP_BROWSER_BASE_URL}#/signup/free`)

    /** 新規会員登録ボタンラベルテキスト */
    const signupButtonLabel = computed(() => I18n.tc('LoginPage.signup'))

    return {
      loginSectionState,
      loadCredentials,
      submitText,
      submitButtonTheme,
      passwordResetUrl,
      signupButtonLabel,
      signupUrl,
      isIosDevice,
      isApp,
    }
  },
  watch: {
    loginSectionState: {
      /**
       * ログインIDとパスワードの変更を検知する。
       * ログインIDもしくはパスワードが更新されたら発火する。
       */
      handler() {
        this.$emit('input-change')
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      if ((await this.loadCredentials()) && !LocalStorageAccessor.transitionFromLogout) {
        // 認証情報を読み込めたら自動ログインする。ただし、ログアウトから遷移してきた場合は自動ログインしない
        this.login()
      }
    } finally {
      LocalStorageAccessor.transitionFromLogout = false
    }
  },
  methods: {
    /**
     * ログインする
     */
    async login() {
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      const isValid = await observer?.validate()
      if (!isValid) {
        return
      }
      /**
       * loginイベントを発火する
       * @event login
       * @property { reactive<{ loginName: string; password: string; }> } ログインIDとパスワード
       */
      this.$emit('login', this.loginSectionState)
    },
  },
})
