









































































import {
  computed,
  defineComponent,
  inject,
  onBeforeMount,
  reactive,
  Ref,
} from '@vue/composition-api'
import type VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MailInputSection from '@/components/SignupPage/EmailAuthenticationPane/MailInputSection.vue'
import ServiceButtonListParts from '@/components/AppSignupPage/AppSignupTopPane/parts/ServiceButtonListParts.vue'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import useUser from '@/store/hook/useUser'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import I18n from '@/locales/I18n'
import AdjustTime from '@/util/timeSynchronisation/AdjustTime'
import OAuthCodeFlowManager from '@/util/oauth/OAuthCodeFlowManager'
import useAppleSignInFlow from '@/components/AppSignupPage/hook/useAppleSignInFlow'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import LocationErrorModalSection from '@/components/common/modal/LocationErrorModalSection.vue'

/**
 * アプリ新規会員登録: ワンタイムパス登録確認ペインのコンポーネント
 */
export default defineComponent({
  name: 'AppSignupOneTimePassConfirmPane',
  components: {
    LocationErrorModalSection,
    AtomInputButton,
    CommonModalSection,
    CurrentPlanCardSection,
    ServiceButtonListParts,
    MailInputSection,
    SubHeaderSection,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const {
      oneTimePass,
      oneTimePassPlan,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      fetchTargetAvailableAreaWithoutAuth,
      getTargetAvailableArea,
    } = oneTimePassPageStore
    const { appleOauthFlow } = useAppleSignInFlow()
    const { registerToken } = useUser()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    const state = reactive({
      // メール送信中かどうか
      isSendingMail: false,
      // 位置情報エラーモーダルを出すかどうか
      isShowLocationErrorModal: false,
    })

    /**
     * 入力したワンタイムパスコードに紐づく店舗名またはエリア名
     */
    const oneTimePassAvailableStoreName = computed(() => {
      const area = getTargetAvailableArea(oneTimePass.value?.availableArea?.areaId ?? '')
      return area?.storeOrAreaName
    })

    /**
     * サーキットなどの現地でのみ利用可能なワンタイムパスの場合、利用可能かどうかをチェックする
     */
    const checkCanUseOneTimePass = async () => {
      const oneTimePassAvailableAreaId = oneTimePass.value?.additionalData?.availableArea?.areaId
      if (!oneTimePassAvailableAreaId) {
        // 全エリアで利用できるワンタイムパスの場合、処理をしない
        return true
      }

      // 現地でのみ利用可能なワンタイムパスの場合、以下の処理を行う
      // ワンタイムパスに紐づくエリア情報を取得
      const loader = loading.show()
      const fetchTargetAvailableAreaResult = await fetchTargetAvailableAreaWithoutAuth(
        oneTimePassAvailableAreaId,
      )
      if (!fetchTargetAvailableAreaResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.fetchAvailableAreaDataError.title'),
          message: I18n.tc('common.errors.fetchAvailableAreaDataError.message'),
        })
        return false
      }

      // 利用可能なエリアかどうかをチェック
      const result = await checkIsInAreaCanUseOneTimePass(oneTimePassAvailableAreaId)
      if (!result.isInArea) {
        loader.hide()
        // エリア判定失敗時の処理
        if (result.isDisplayLatLng) {
          // ワンタイムパス利用可能エリア外の場合、緯度経度情報などのエラーを表示する
          // 例外処理が発生した際には別のエラーモーダルが表示されるため、ワンタイムパス入力モーダル表示を切り替えない
          state.isShowLocationErrorModal = true
        }
        return false
      }

      loader.hide()
      return true
    }

    /**
     * Appleの認可を開始する
     */
    const startAppleOauthFlow = async () => {
      // 現地利用限定ワンタイムパスの場合、位置情報チェックをする
      const result = await checkCanUseOneTimePass()
      if (!result) {
        return
      }

      // Apple認証
      appleOauthFlow()
    }

    onBeforeMount(async () => {
      if (!oneTimePass.value) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'SignupPage.AppSignupOneTimePassConfirmPage.errors.getOneTimePassError.title',
          ),
          message: I18n.tc(
            'SignupPage.AppSignupOneTimePassConfirmPage.errors.getOneTimePassError.message',
          ),
        })
        // ワンタイムパス情報が取得できない場合、ワンタイムパス登録画面に遷移
        router.replace({ path: '/signup/one-time-pass' })
      }
    })

    return {
      // AppSignupOneTimePassConfirmPane
      state,
      oneTimePassAvailableStoreName,
      checkCanUseOneTimePass,
      startAppleOauthFlow,
      // useUser
      registerToken,
      // oneTimePassPageStore
      oneTimePass: oneTimePass as Ref<CouponDocument>,
      oneTimePassPlan: oneTimePassPlan as Ref<ContractPlanDocument>,
      currentLocationInfo,
    }
  },
  methods: {
    /**
     * Googleの認可を開始する
     */
    async startGoogleOauthFlow() {
      // 現地利用限定ワンタイムパスの場合、位置情報チェックをする
      const result = await this.checkCanUseOneTimePass()
      if (!result) {
        return
      }

      OAuthCodeFlowManager.createOAuthCodeFlowManager('google')
      const oAuthCodeFlowManager = OAuthCodeFlowManager.getOAuthCodeFlowManager()

      if (oAuthCodeFlowManager?.state) {
        // 認証画面に遷移する前にワンタイムパス情報をローカルストレージに保存
        LocalStorageAccessor.setAppSignupOneTimePass({
          key: oAuthCodeFlowManager?.state,
          couponInfo: this.oneTimePass,
          createdDate: AdjustTime.getAdjustedCurrentTime(),
        })
      }

      // 認可処理を開始する
      oAuthCodeFlowManager?.startOauthCodeFlow(['profile', 'email'])
    },
    /**
     * 認証メール送信処理
     */
    async onFormSubmit(email: string) {
      // 現地利用限定ワンタイムパスの場合、位置情報チェックをする
      const checkOneTimePassResult = await this.checkCanUseOneTimePass()
      if (!checkOneTimePassResult) {
        return
      }

      this.state.isSendingMail = true
      const loader = this.$loading.show()

      // ユーザー登録ページのURLに付与するトークンを生成し、入力したメールアドレス宛てにメール送信
      const result = await this.registerToken(Const.MEMBER_TYPE.FREE, 'MAIL_ADDRESS', email)
      this.state.isSendingMail = false
      loader.hide()
      const oneTimePassKey = result.data?.tokenExpirationDate
        ? String(result.data.tokenExpirationDate)
        : ''
      if (!result.isSuccess) {
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.common.errors.generateTokenError.title'),
          message: this.$tc('SignupPage.common.errors.generateTokenError.message'),
          errorApiResponse: result?.response,
        })
        return
      }

      // ワンタイムパス情報をローカルストレージに保存
      LocalStorageAccessor.setAppSignupOneTimePass({
        key: oneTimePassKey,
        couponInfo: this.oneTimePass,
        createdDate: AdjustTime.getAdjustedCurrentTime(),
      })

      // ボタンが押下されたことを通知する
      this.$emit('formSubmit')
    },
    /**
     * 位置情報エラーモーダルを閉じる
     */
    emitClose() {
      this.state.isShowLocationErrorModal = false
    },
  },
})
