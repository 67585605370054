import { computed, reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useCoupon from '@/store/hook/useCoupon'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlanCoupon from '@/store/hook/useContractInfoPlanCoupon'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import useAvailableArea from '@/store/hook/useAvailableArea'
import Logger from '@/util/logger/Logger'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useGeolocation from '@/components/hook/useGeolocation'
import useErrorHandling from '@/components/hook/useErrorHandling'
import { LocationInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * ワンタイムパス画面の種類の型
 */
export type OneTimePassScreenType = 'AppSignup' | 'Mypage'

/**
 * クーポン情報とキーとなる値を持つデータの型
 */
export type CouponInfoWithKeyType = {
  // UUID
  key: string
  // クーポン情報
  couponInfo: CouponDocument
  // 作成日時
  createdDate: number
}

/**
 * 初期状態
 */
const initialState = {
  /**
   * 取得したワンタイムパス
   */
  oneTimePass: null as null | CouponDocument,
  /**
   * 取得したワンタイムパスのプラン
   */
  oneTimePassPlan: null as null | ContractPlanDocument,
  /**
   * 現在地情報
   */
  currentLocationInfo: null as LocationInfoType | null,
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoのマイページ ワンタイムパス登録画面のStore
 */
class OneTimePassPageStore implements StoreBase {
  createStore() {
    const state = reactive({
      ...initialState,
    })

    // hook
    const { fetchContractInfo, ownContractInfo, clearContractInfo } = useContractInfo()
    const { fetchContractPlans, getCouponPlan, clearContractPlans } = useContractPlan()
    const { fetchContractInfoPlans, contractInfoPlans, clearContractInfoPlans } =
      useContractInfoPlan()
    const { saveContractInfoPlanCoupon } = useContractInfoPlanCoupon()
    const {
      fetchTargetCoupon,
      fetchTargetCouponWithoutAuthorized,
      coupons,
      setCoupon,
      targetOneTimePass,
      clearCoupons,
    } = useCoupon()
    const {
      fetchTargetAvailableArea,
      fetchTargetAvailableAreaWithoutAuth,
      getTargetAvailableArea,
      clearAvailableAreas,
    } = useAvailableArea()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()

    /**
     * 取得したワンタイムパス
     * @return { CouponDocument } ワンタイムパス情報
     */
    const oneTimePass = computed({
      get: (): CouponDocument | null => <CouponDocument | null>state.oneTimePass,
      set: (newOneTimePass: CouponDocument | null) => {
        state.oneTimePass = newOneTimePass
      },
    })

    /**
     * ワンタイムパスのプラン（正しいクーポンコードを入力した際に取得できる）
     * @return { ContractPlanDocument } 契約プラン情報
     */
    const oneTimePassPlan = computed({
      get: (): ContractPlanDocument | null => <ContractPlanDocument | null>state.oneTimePassPlan,
      set: (plan: ContractPlanDocument | null) => {
        state.oneTimePassPlan = plan
      },
    })

    /**
     * 画面表示用のワンタイムパスプラン情報をセット
     * @param originalOneTimePassPlan クーポン情報.plansにあるプラン情報
     * @param oneTimePassToBeUsed 使用予定のワンタイムパス
     */
    const setOneTimePassPlan = (
      originalOneTimePassPlan: ContractPlanDocument,
      oneTimePassToBeUsed: CouponDocument,
    ) => {
      oneTimePassPlan.value = new ContractPlanDocument({
        ...originalOneTimePassPlan,
        couponData: {
          couponName: {
            ja: oneTimePassToBeUsed.couponName.ja,
            en: oneTimePassToBeUsed.couponName.en,
          },
          title: {
            ja: oneTimePassToBeUsed.additionalData?.title.ja,
            en: oneTimePassToBeUsed.additionalData?.title.en,
          },
          description: {
            ja: oneTimePassToBeUsed.description?.ja || '',
            en: oneTimePassToBeUsed.description?.en || '',
          },
          couponApplicationPeriodText: oneTimePassToBeUsed.couponApplicationPeriodText,
        },
      })
    }

    /**
     * 現在地情報
     */
    const currentLocationInfo = computed({
      get: () => state.currentLocationInfo,
      set: (location: LocationInfoType | null) => {
        state.currentLocationInfo = location
      },
    })

    /**
     * ワンタイムパスを利用できるエリアにいるかをチェックする
     * @param areaId エリアID
     */
    const checkIsInAreaCanUseOneTimePass = async (areaId: string) => {
      if (!areaId) {
        Logger.info(
          'OneTimePassPageStore#checkIsInAreaCanUseOneTimePass currentGPS: areaId is empty',
        )
        return {
          isInArea: false,
          isDisplayLatLng: false,
        }
      }

      let positionResult = null
      try {
        // 現在地を取得
        positionResult = await getCurrentPosition()
        Logger.info(
          `OneTimePassPageStore#checkIsInAreaCanUseOneTimePass currentGPS: ${positionResult.coords.latitude}, ${positionResult.coords.longitude}`,
        )
      } catch (e) {
        const positionError = e as GeolocationPositionError
        Logger.info(
          `OneTimePassConfirm#handlerSubmit getCurrentPositionError: code: ${positionError.code}, message: ${positionError.message}`,
        )
        createGeolocationError(positionError)
        return {
          isInArea: false,
          isDisplayLatLng: false,
        }
      }

      // 対象ワンタイムパスの利用可能エリア情報を取得
      const availableArea = getTargetAvailableArea(areaId)

      try {
        // ユーザーが利用可能エリア内にいるかを判定する
        const isInArea = checkIsUserInArea(positionResult, availableArea)
        if (!isInArea) {
          // 現在地情報をセット
          currentLocationInfo.value = {
            lat: positionResult.coords.latitude,
            lng: positionResult.coords.longitude,
          }
        }
        return {
          isInArea,
          isDisplayLatLng: true,
        }
      } catch (e) {
        MessageDialogStore.value.open({
          title: I18n.tc('common.errors.pointInPolygonError.title'),
          message: I18n.tc('common.errors.pointInPolygonError.message'),
        })
        return {
          isInArea: false,
          isDisplayLatLng: false,
        }
      }
    }

    // methods
    /**
     * ワンタイムパス登録ページのデータをクリアする
     */
    const clearOneTimePassPageData = () => {
      clearContractInfo()
      clearContractPlans()
      clearContractInfoPlans()
      clearCoupons()
      clearAvailableAreas()
      Object.assign(state, initialState)
    }

    return {
      oneTimePass,
      oneTimePassPlan,
      setOneTimePassPlan,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      clearOneTimePassPageData,
      fetchContractInfo,
      fetchContractPlans,
      getCouponPlan,
      fetchContractInfoPlans,
      contractInfoPlans,
      ownContractInfo,
      saveContractInfoPlanCoupon,
      fetchTargetCoupon,
      fetchTargetCouponWithoutAuthorized,
      coupons,
      setCoupon,
      targetOneTimePass,
      clearCoupons,
      fetchTargetAvailableArea,
      fetchTargetAvailableAreaWithoutAuth,
      getTargetAvailableArea,
    }
  }
}

const value: ValueType<OneTimePassPageStore> = {}

export default {
  createStore: new OneTimePassPageStore().createStore,
  value: value as Required<typeof value>,
}
