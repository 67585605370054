









import { defineComponent } from '@vue/composition-api'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import AppSignupOneTimePassConfirmPane from '@/components/AppSignupPage/AppSignupOneTimePassConfirmPane.vue'

/**
 * アプリ新規会員登録: ワンタイムパス登録確認ページ
 */
export default defineComponent({
  name: 'AppSignupOneTimePassConfirmPage',
  components: { AppSignupOneTimePassConfirmPane, HeaderPane },
  methods: {
    moveToAppConfirmationSentPage() {
      /**
       * 認証メール送信画面へ遷移
       */
      this.$router.replace({
        name: 'AppConfirmationSentPage',
        query: { appSignUpFlowType: 'one-time-pass' },
      })
    },
  },
})
