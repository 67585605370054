import { now } from 'lodash'

/**
 * 動画プラットフォームコンテンツのパラメータを操作するための処理
 */
export default function useVideoPlatformContentsParams() {
  // 表示対象となっている動画コンテンツのみを取得するフィルタ
  const generateDisplayFilter = () => {
    const currentDate = now()
    return {
      $or: [
        { $and: [{ startDate: null }, { endDate: null }] },
        {
          $and: [{ startDate: { $lte: currentDate } }, { endDate: { $gte: currentDate } }],
        },
        { $and: [{ startDate: null }, { endDate: { $gte: currentDate } }] },
        { $and: [{ startDate: { $lte: currentDate } }, { endDate: null }] },
      ],
    }
  }

  return {
    generateDisplayFilter,
  }
}
