









import { defineComponent, onBeforeMount } from '@vue/composition-api'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import AppSignupOneTimePassPane from '@/components/AppSignupPage/AppSignupOneTimePassPane.vue'
import StoreUtil from '@/store/StoreUtil'

/**
 * アプリ新規会員登録: ワンタイムパス登録ページ
 */
export default defineComponent({
  name: 'AppSignupOneTimePassPage',
  components: { AppSignupOneTimePassPane, HeaderPane },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { clearOneTimePassPageData } = oneTimePassPageStore

    onBeforeMount(() => {
      // ワンタイムパス登録ページのデータをクリアする
      clearOneTimePassPageData()
    })
  },
  methods: {
    /**
     * ワンタイムパスの登録確認画面へ遷移
     */
    moveToAppSignupOneTimePassConfirm() {
      this.$router.replace({ name: 'AppSignupOneTimePassConfirmPage' })
    },
  },
})
