var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-one-time-pass-confirm-pane" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.OneTimePassPage.title"),
          backLink: "/signup/one-time-pass",
        },
      }),
      _c(
        "div",
        { staticClass: "app-signup-one-time-pass-confirm-pane__main" },
        [
          _c(
            "div",
            { staticClass: "app-signup-one-time-pass-confirm-pane__plan" },
            [
              _vm.oneTimePassPlan
                ? _c("current-plan-card-section", {
                    attrs: {
                      sectionTitle: _vm.$tc("SignupPage.OneTimePassPage.plan"),
                      plan: _vm.oneTimePassPlan,
                      isOneTimePassUsed: true,
                      isConfirmed: true,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "app-signup-one-time-pass-confirm-pane__mail" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "app-signup-one-time-pass-confirm-pane__mail__title",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tc("SignupPage.OneTimePassPage.mail")) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "app-signup-one-time-pass-confirm-pane__mail__input",
                },
                [
                  _c("mail-input-section", {
                    attrs: {
                      isSendingMail: _vm.state.isSendingMail,
                      hasMessage: false,
                      isUseReCaptcha: false,
                    },
                    on: { formSubmit: _vm.onFormSubmit },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "app-signup-one-time-pass-confirm-pane__services" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "app-signup-one-time-pass-confirm-pane__services__title",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$tc("SignupPage.OneTimePassPage.services")) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "app-signup-one-time-pass-confirm-pane__services__list",
                },
                [
                  _c("service-button-list-parts", {
                    on: {
                      onStartGoogleOauthFlow: _vm.startGoogleOauthFlow,
                      onStartAppleOauthFlow: _vm.startAppleOauthFlow,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm.state.isShowLocationErrorModal
        ? _c("common-modal-section", {
            staticClass: "location-error-modal-section",
            on: { close: _vm.emitClose },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("location-error-modal-section", {
                        attrs: {
                          currentLocationInfo: _vm.currentLocationInfo,
                          storeName: _vm.oneTimePassAvailableStoreName,
                          isShowInput: false,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c("atom-input-button", {
                        attrs: {
                          linkText: _vm.$i18n.tc("common.close"),
                          colorType: "tertiary",
                          isDisabled: false,
                        },
                        on: {
                          push: function ($event) {
                            return _vm.emitClose()
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3068661117
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }