var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-modal-section", {
    staticClass: "flow-line-to-paid-plan-modal-section",
    class: "flow-line-to-paid-plan-modal-section--" + _vm.displayMode,
    on: { close: _vm.emitClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _vm.displayMode === "locationError"
              ? _c("location-error-section")
              : _vm._e(),
            _vm.displayMode === "planRegistration"
              ? _c("one-time-pass-register-section")
              : _vm._e(),
            _vm.displayMode === "default"
              ? [
                  _c("modal-title-parts", { attrs: { title: _vm.title } }),
                  _c("modal-message-parts", {
                    attrs: { message: _vm.message },
                  }),
                  _vm.isIncludeNextRelease
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flow-line-to-paid-plan-modal-section__body",
                        },
                        [
                          _c("modal-coupon-code-input-parts", {
                            attrs: {
                              buttonLabel: _vm.$tc(
                                "common.flowLineToPaidPlan.discount.use"
                              ),
                              hasError: _vm.hasCodeError,
                              errorMessage: _vm.$tc(
                                "common.flowLineToPaidPlan.discount.codeError"
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _vm.isIncludeNextRelease
              ? [
                  _vm.submitText && _vm.displayMode === "default"
                    ? _c("atom-icon-link-button", {
                        attrs: {
                          linkText: _vm.submitText,
                          url: _vm.link,
                          colorType: "secondary",
                          imgPath: require("@/assets/img/icon/icon_external_link_gray75.svg"),
                        },
                      })
                    : _vm._e(),
                ]
              : [
                  _vm.submitText && _vm.displayMode === "default"
                    ? _c("atom-icon-link-button", {
                        attrs: {
                          linkText: _vm.submitText,
                          url: _vm.link,
                          colorType: "primary",
                          imgPath: require("@/assets/img/icon/icon_external_link_outline__green.svg"),
                        },
                      })
                    : _vm._e(),
                ],
            _c("atom-input-button", {
              attrs: {
                linkText: _vm.closeButtonLabel,
                colorType: "tertiary",
                isDisabled: false,
              },
              on: {
                push: function ($event) {
                  return _vm.emitClose()
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }