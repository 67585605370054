







import { defineComponent, PropType } from '@vue/composition-api'
import AppConfirmationSentPane from '@/components/AppSignupPage/AppConfirmationSentPane.vue'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import { AppSignUpFlowType } from '@/store/stores/pageStore/SignupPage/SignupType'

/**
 * アプリ新規会員登録: メール認証送信完了ページ
 */
export default defineComponent({
  name: 'AppConfirmationSentPage',
  components: { HeaderPane, AppConfirmationSentPane },
  props: {
    /**
     * アプリ内会員登録: 会員登録フロータイプ
     */
    appSignUpFlowType: {
      type: String as PropType<AppSignUpFlowType>,
      default: 'free',
    },
  },
})
