


































import { defineComponent, computed, reactive, ref, watch } from '@vue/composition-api'
import { ValidationObserver, extend } from 'vee-validate'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'
import useReCaptcha from '@/components/hook/useReCaptcha'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

/**
 * 新規会員登録:メール入力セクションのコンポーネント
 */
export default defineComponent({
  name: 'MailInputSection',
  components: {
    FieldsetEmailParts,
    AtomInputButton,
  },
  props: {
    /**
     * ログインエラーメッセージを表示するかどうか
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * 認証メール送信処理実行中かどうか
     */
    isSendingMail: {
      type: Boolean,
      required: true,
    },
    /**
     * メッセージを表示するかどうか
     */
    hasMessage: {
      type: Boolean,
      default: true,
    },
    /**
     * reCaptchaを使うかどうか
     * アプリの場合reCaptchaが使えない
     */
    isUseReCaptcha: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { checkEmailAddressDuplicated } = usePasswordResetToken()
    const { getReCaptchaToken } = useReCaptcha()

    const state = reactive({
      isDuplicateMailAddress: false,
      isAuthentication: false,
      isNetwork: false,
    })
    const email = ref('')

    const validationProvider = ref<InstanceType<typeof ValidationObserver>>()

    // メールアドレスの重複チェックバリデーション
    const duplicateEmail = {
      validate() {
        return !state.isDuplicateMailAddress
      },
    }
    extend('duplicateEmail', duplicateEmail)

    // メールアドレスの重複チェックバリデーション：認証エラー
    const authentication = {
      validate() {
        return !state.isAuthentication
      },
    }
    extend('authentication', authentication)

    // メールアドレスの重複チェックバリデーション：サーバーエラー
    const network = {
      validate() {
        return !state.isNetwork
      },
    }
    extend('network', network)

    /** 以下の場合は「認証メールを送る」ボタンを押せない
     * 1. バリデーションエラーがある
     * 2. メールアドレス未入力
     * 3. 認証メール送信処理実行中
     */
    const isDisabled = computed(
      () =>
        Number(validationProvider.value?.errors.length) > 0 || !email.value || props.isSendingMail,
    )

    /** 入力したメールアドレスが登録済みか判定する */
    const checkExistMailAddress = async (reCaptchaToken?: string) => {
      // メールアドレスの重複チェックを実行する
      const result = await checkEmailAddressDuplicated(email.value, reCaptchaToken)
      switch (result.response?.data?.error_code) {
        // reCAPTCHAトークンの有効性がない場合や指定されていない場合
        case '401_0001':
          state.isAuthentication = !result.isSuccess
          break
        // reCAPTCHAトークンが空文字場合
        case '400_0001':
          state.isAuthentication = !result.isSuccess
          break
        // リクエストのメールアドレスが既に使用されている場合
        case '409_0000':
          state.isDuplicateMailAddress = !result.isSuccess
          break
        // GoogleのreCAPTCHA検証用APIに接続失敗した場合
        case '500_0017':
          state.isNetwork = !result.isSuccess
          break
        // 上記以外
        default:
          state.isNetwork = !result.isSuccess
          break
      }
    }

    // メールアドレス重複チェックはsubmit時に行うため、リアクティブにメールアドレス重複エラーメッセージが消えない
    // そのため、メールアドレス入力欄が変更されたタイミングで、isDuplicateMailAddressをfalseにしてメールアドレス重複エラーをクリアしている
    watch(
      () => email.value,
      () => {
        state.isDuplicateMailAddress = false
        state.isAuthentication = false
        state.isNetwork = false
      },
    )

    return {
      state,
      email,
      validationProvider,
      isDisabled,
      getReCaptchaToken,
      checkExistMailAddress,
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async onFormSubmit() {
      let reCaptchaToken: string | undefined
      if (this.isUseReCaptcha) {
        reCaptchaToken = await this.getReCaptchaToken()
        // reCaptchaのトークン取得に失敗した場合、エラーを表示して処理を終了する
        if (!reCaptchaToken) {
          MessageDialogStore.value.open({
            title: I18n.tc('SignupPage.common.errors.getReCaptchaTokenError.title'),
            message: I18n.tc('SignupPage.common.errors.getReCaptchaTokenError.message'),
          })
          return {
            isSuccess: false,
            res: { response: { data: { error_code: 'NETWORK_ERROR' } } },
          }
        }
      }
      // メールアドレスの重複チェック
      await this.checkExistMailAddress(reCaptchaToken)

      // 上記でメールアドレスの重複チェックを行っているため、submit前にバリデーションチェックを実行する
      await this.validationProvider?.validate()

      if (Number(this.validationProvider?.errors.length) === 0) {
        /**
         * ボタンが押下されたことを通知する
         * @param email フォームに入力したメールアドレス
         */
        this.$emit('formSubmit', this.email)
      }
    },
  },
})
