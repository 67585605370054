import { reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import type { DynamoDBCredentialsType } from '@/store/stores/collectionModule/documents/data/AccessRequestDocument'
import AccessRequestDocument from '@/store/stores/collectionModule/documents/data/AccessRequestDocument'
import { SaveResponse } from '@/store/stores/collectionModule/CollectionTypes'
import TelemetryDocument from '@/store/stores/collectionModule/documents/telemetry/TelemetryDocument'
import AllPlayerTelemetryDocument from '@/store/stores/collectionModule/documents/liveTiming/AllPlayerTelemetryDocument'
import LiveTimingDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingDocument'
import GpsDocument from '@/store/stores/collectionModule/documents/gps/GpsDocument'
import FeedDataDocument from '@/store/stores/collectionModule/documents/feed/FeedDataDocument'
import loginStore from '@/store/stores/loginStore/LoginStore'
import Logger from '@/util/logger/Logger'
import LiveTimingAggregationDocument from '@/store/stores/collectionModule/documents/liveTiming/LiveTimingAggregationDocument'
import ResultDataDocument from '@/store/stores/collectionModule/documents/standings/ResultDataDocument'
import StandingsDataDocument from '@/store/stores/collectionModule/documents/standings/StandingsDataDocument'
import AwardDataDocument from '@/store/stores/collectionModule/documents/award/AwardDataDocument'
import CalendarDataDocument from '@/store/stores/collectionModule/documents/calendar/CalendarDataDocument'
import PointRankingDataDocument from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import DigitalTicketDataDocument from '@/store/stores/collectionModule/documents/digitalTicket/DigitalTicketDataDocument'
import ReceivedDataDocument from '@/store/stores/collectionModule/documents/received/ReceivedDataDocument'

/**
 * DynamoDB テーブル名のリスト.
 * このリストには、バックエンドに実際に登録されているテーブル名から、環境名のプレフィックスを取り除いたものを定義する。
 */
const DynamoDBTableTypes = [
  'telemetry-data',
  'telemetry-data-by-race',
  'live-timing-data',
  'live-timing-aggregation',
  'GPS-data',
  'feed-data',
  'race-result-data',
  'standings-data',
  'award-data',
  'calendar-data',
  'point-ranking-aggregation',
  'digital-ticket-data',
  'received-data',
] as const

/**
 * DynamoDB テーブル種別の型
 * @see DynamoDBTableTypes
 */
export type DynamoDBTableType = typeof DynamoDBTableTypes[number]

/**
 * 認証情報の初期状態.
 */
const initialCredentials = {
  dynamoDBAccessKey: '',
  dynamoDBSecretAccessKey: '',
  dynamoDBSessionToken: '',
  securityTokenExpiredDate: 0,
  dynamoDBTableName: undefined,
  dynamoDBRegion: '',
} as DynamoDBCredentialsType

/**
 * このストアの状態オブジェクトの型
 */
type StateType = {
  credentials: Record<DynamoDBTableType, DynamoDBCredentialsType | null>
}

/**
 * このストアの状態オブジェクトの初期状態
 */
const initialState = {
  credentials: {
    'telemetry-data': { ...initialCredentials },
    'telemetry-data-by-race': { ...initialCredentials },
    'live-timing-data': { ...initialCredentials },
    'GPS-data': { ...initialCredentials },
    'live-timing-aggregation': { ...initialCredentials },
    'feed-data': { ...initialCredentials },
    'race-result-data': { ...initialCredentials },
    'standings-data': { ...initialCredentials },
    'award-data': { ...initialCredentials },
    'calendar-data': { ...initialCredentials },
    'point-ranking-aggregation': { ...initialCredentials },
    'digital-ticket-data': { ...initialCredentials },
    'received-data': { ...initialCredentials },
  },
} as StateType

/**
 * DynamoDBテーブル名と、そのテーブルからのデータ参照で利用されるDocumentクラスのマップ.
 */
const TableDocumentClassMapping = {
  'telemetry-data': TelemetryDocument,
  'telemetry-data-by-race': AllPlayerTelemetryDocument,
  'live-timing-data': LiveTimingDocument,
  'live-timing-aggregation': LiveTimingAggregationDocument,
  'GPS-data': GpsDocument,
  'feed-data': FeedDataDocument,
  'race-result-data': ResultDataDocument,
  'standings-data': StandingsDataDocument,
  'award-data': AwardDataDocument,
  'calendar-data': CalendarDataDocument,
  'point-ranking-aggregation': PointRankingDataDocument,
  'digital-ticket-data': DigitalTicketDataDocument,
  'received-data': ReceivedDataDocument,
}

/**
 * DynamoDB の認証情報のストア
 */
/* eslint-disable class-methods-use-this */
class DynamoDBCredentialStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    /**
     * AWS認証情報が有効かどうかを判定する。
     * @param credential 認証情報
     */
    const isAWSCredentialValid = (credential: DynamoDBCredentialsType | null) => {
      if (!credential) {
        return false
      }
      return (
        credential.dynamoDBRegion &&
        credential.dynamoDBAccessKey &&
        credential.dynamoDBSecretAccessKey &&
        credential.dynamoDBSessionToken &&
        credential.securityTokenExpiredDate
      )
    }

    /**
     * 指定されたテーブルに対応するDocumentクラスを取得する。
     * @param tableName テーブル名
     */
    const getTableDocumentClass = (tableName: DynamoDBTableType) =>
      TableDocumentClassMapping[tableName]

    /**
     * バックエンドに実際に登録されているテーブル名を取得する。
     * @param tableName テーブルの種別
     */
    const getDynamoTableName = (tableName: DynamoDBTableType): DynamoDBTableType | undefined => {
      const credential = state.credentials[tableName]
      return credential?.dynamoDBTableName
    }

    /**
     * 現在ストアが保持しているAWSの認証情報を返す。
     * 認証情報はテーブルによって異なるため、テーブル種別を指定する。
     *
     * @param tableName テーブル名
     */
    const getAWSCredential = (tableName: DynamoDBTableType) => {
      const credential = state.credentials[tableName]
      if (credential && isAWSCredentialValid(credential)) {
        return {
          accessKeyId: credential.dynamoDBAccessKey,
          secretAccessKey: credential.dynamoDBSecretAccessKey,
          sessionToken: credential.dynamoDBSessionToken,
          region: credential.dynamoDBRegion,
          tokenExpiredDate: credential.securityTokenExpiredDate,
        }
      }
      return null
    }

    /**
     * DynamoDBの認証情報を取得する。
     */
    const auth = async () => {
      const url = `${process.env.VUE_APP_API_BASE_URL as string}/${
        loginStore.value.orgId
      }/data/access_request`

      const accessRequestCollectionModule = CollectionModule.createStore(AccessRequestDocument)
      const requests: Array<Promise<SaveResponse<AccessRequestDocument>>> = []
      DynamoDBTableTypes.forEach((tableName: DynamoDBTableType) => {
        requests.push(
          accessRequestCollectionModule
            .save(new AccessRequestDocument({ type: tableName }), {
              url,
            })
            .then((result) => {
              state.credentials[tableName] = result.data?.dynamoDBCredentials ?? null
              return result
            }),
        )
      })
      const responses = await Promise.all(requests)
      return {
        isSuccess: responses.every((response) => response.isSuccess),
        isNetworkError: responses.some((response) => response.response?.isNetworkError),
      }
    }

    /**
     * ストアの状態をクリアする。
     */
    const clearState = () => {
      // 状態を初期化する
      Object.assign(state, initialState)
    }

    /**
     * 認証情報を定期実行する処理の interval ID
     */
    let intervalId: number

    /**
     * このストアが初期化済みかどうかを判定する
     */
    const initialized = () => !!intervalId

    /**
     * ストアをクリアする
     */
    const clear = () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
      clearState()
    }

    /**
     * ストアを初期化する。
     * このメソッドを呼び出すことで、認証情報を取得し、ストアに保持する。
     * また、定期的に認証情報を更新する処理が開始される。
     * 定期的な認証情報の更新処理を止めるために、このメソッドを呼び出した後は、destroy メソッドを呼び出す。
     */
    const init = async () => {
      if (initialized()) {
        clear()
      }
      await auth()

      const awsCredentials = getAWSCredential?.('live-timing-data')

      const nextUpdateDate = awsCredentials?.tokenExpiredDate
      if (nextUpdateDate) {
        // 定期的に認証情報を更新する
        // 認証情報の有効期限から現在の時間を引いた値に10分を差し引いた値をインターバル期間とする
        // ※ 認証情報の有効期間は1時間なので、インターバルは50分となる想定
        // バックエンドの認証時間が変更された場合を考慮し、計算でインターバル値を求める。
        intervalId = setInterval(async () => {
          Logger.info('Update DynamoDB credentials.')
          await auth()
        }, nextUpdateDate - new Date().getTime() - 10 * 60 * 1000)
      }
    }

    return {
      getAWSCredential,
      getDynamoTableName,
      getTableDocumentClass,
      auth,
      init,
    }
  }
}

const value: ValueType<DynamoDBCredentialStore> = {}

export default {
  createStore: new DynamoDBCredentialStore().createStore,
  value,
}
