


































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import DeviceInfo from '@/util/DeviceInfo'

/**
 * フィールドセット 名前(ふりがな)入力 バーツ
 * todo: 正常なsetup / emit処理 /
 */
export default defineComponent({
  name: 'FieldsetNameRubyParts',
  components: {
    AtomInputText,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 入力データ
     */
    valueFamily: {
      type: String,
      default: '',
    },
    valueFirst: {
      type: String,
      default: '',
    },
    /**
     * 必須フラグ
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーフラグ
     */
    showError: {
      type: Boolean,
      default: false,
    },
    /**
     * エラーメッセージ
     */
    errorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    /**
     * 入力フォームの変更が可能かどうか
     * ブラウザの場合にisDisabled: trueにする
     */
    const isDisabled = ref(!DeviceInfo.isCordova())

    /**
     * familyNameKana
     * @param {string} val 姓(ふりがな)
     */
    const familyNameKana = computed({
      get: () => props.valueFamily,
      set: (val: string) => context.emit('inputfamilyNameKana', val),
    })
    /**
     * firstNameKana
     * @param {string} val 名(ふりがな)
     */
    const firstNameKana = computed({
      get: () => props.valueFirst,
      set: (val: string) => context.emit('inputfirstNameKana', val),
    })

    onMounted(async () => {
      /**
       * Chromeのブラウザだと郵便番号入力フォームが認証情報と誤認されてしまう。
       * そのため、DOM生成時はdisabled状態とすることでChromeに認識させないようにし、0.5秒後に入力可能な状態にすることで対処している。
       */
      await new Promise((resolve) => {
        setTimeout(resolve, 500)
      })
      isDisabled.value = false
    })

    return {
      familyNameKana,
      firstNameKana,
      isDisabled,
    }
  },
  methods: {
    /**
     * Enter Push
     */
    emitEnter() {
      /**
       * Enterボタン押下を通知する
       * @event login
       * @type {Object}
       */
      this.$emit('enter')
    },
  },
})
