



















import { defineComponent, PropType } from '@vue/composition-api'
import RewardItemSection from '@/components/RewardPage/RewardPane/RewardItemSection.vue'
import type { RewardDataType } from '@/store/hook/videoPlatformContents/useRewards'

export default defineComponent({
  name: 'RewardListSection',
  components: { RewardItemSection },
  props: {
    rewardDataList: {
      type: Array as PropType<Array<RewardDataType>>,
      default: () => [],
    },
  },
})
