import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'
import { RegistMethodType } from '@/store/stores/collectionModule/documents/user/UserRegisterTokenDocument'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'

/**
 * メールアドレスチェック機能を提供する。
 */
export default function useCheckEmailAddress() {
  const signupPageStore = StoreUtil.useStore('SignupPageStore')
  const { checkEmailAddressDuplicated } = usePasswordResetToken()

  /**
   * メールアドレスの重複チェックを実行する
   * @param method 会員登録方法
   */
  const emailAddressDuplicateCheckProcess = async (method: RegistMethodType) => {
    let checkEmailAddressResult: Response<DocumentWrapper> = {
      isSuccess: false,
      data: [],
      count: null,
    }

    if (method === 'GOOGLE') {
      checkEmailAddressResult = await checkEmailAddressDuplicated(
        signupPageStore.googleUserInfo.value.email ?? '',
      )
    } else if (method === 'APPLE') {
      checkEmailAddressResult = await checkEmailAddressDuplicated(
        signupPageStore.appleUserInfo.value?.email ?? '',
      )
    }

    if (!checkEmailAddressResult?.isSuccess) {
      if (checkEmailAddressResult.response?.data.error_code === '409_0000') {
        MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.AppSignupTopPage.errors.duplicateEmail.title'),
          message: I18n.tc('SignupPage.AppSignupTopPage.errors.duplicateEmail.message'),
          errorApiResponse: checkEmailAddressResult.response,
        })
      } else {
        MessageDialogStore.value.open({
          title: I18n.tc('SignupPage.AppSignupTopPage.errors.duplicateEmailError.title'),
          message: I18n.tc('SignupPage.AppSignupTopPage.errors.duplicateEmailError.message'),
          errorApiResponse: checkEmailAddressResult.response,
        })
      }
      return false
    }

    return true
  }

  return {
    emailAddressDuplicateCheckProcess,
  }
}
