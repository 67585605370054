var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-complete-section" },
    [
      _c("h3", { staticClass: "signup-complete-section__title" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("SignupPage.SignupCompletePage.title")) + " "
        ),
      ]),
      _c("p", {
        staticClass: "signup-complete-section__message",
        domProps: {
          innerHTML: _vm._s(
            _vm.$tc("SignupPage.SignupCompletePage.contentText")
          ),
        },
      }),
      _vm.isShowAttentionMessage
        ? _c("attention-message-section", {
            staticClass: "signup-complete-section__attention-message",
            attrs: { attentionMessage: _vm.attentionMessage },
          })
        : _vm._e(),
      _vm.paymentSchedule
        ? _c("payment-schedule-section", {
            staticClass: "signup-complete-section__payment-schedule",
            attrs: { paymentSchedule: _vm.paymentSchedule },
          })
        : _vm._e(),
      !_vm.isApp
        ? _c("div", { staticClass: "signup-complete-section__apps-link" }, [
            _c(
              "h4",
              { staticClass: "signup-complete-section__apps-link__title" },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$tc("SignupPage.SignupCompletePage.app.title")) +
                    " "
                ),
              ]
            ),
            _c(
              "ul",
              { staticClass: "signup-complete-section__apps-link__list" },
              [
                _c(
                  "li",
                  { staticClass: "signup-complete-section__apps-link__google" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://play.google.com/store/apps/details?id=net.superformula.sfgo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png",
                            alt: _vm.$tc(
                              "SignupPage.SignupCompletePage.app.googleAlt"
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "signup-complete-section__apps-link__app-store",
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://apps.apple.com/us/app/sfgo/id1615551217?itsct=apps_box_badge&itscg=30200",
                          target: "_blank",
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&releaseDate=1648598400&h=4caa447b276cdbdc2dfad2ec801d220f",
                            alt: _vm.$tc(
                              "SignupPage.SignupCompletePage.app.appStoreAlt"
                            ),
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c("p", { staticClass: "signup-complete-section__back-link" }, [
        _vm.isApp
          ? _c(
              "a",
              {
                staticClass:
                  "atom-router-btn atom-router-btn--light--secondary atom-router-btn--large",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.onMoveToLogin()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$tc("common.login")))]
            )
          : _c(
              "a",
              {
                staticClass:
                  "atom-router-btn atom-router-btn--light--secondary atom-router-btn--large",
                attrs: { href: _vm.sfgoUrl },
              },
              [_vm._v(_vm._s(_vm.$tc("common.login")))]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }