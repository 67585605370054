var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-point-item-parts" }, [
    _c("div", { staticClass: "mypage-point-item-parts__body" }, [
      _c("p", { staticClass: "mypage-point-item-parts__point" }, [
        _c(
          "span",
          { staticClass: "mypage-point-item-parts__point__icon" },
          [
            _vm.item.status === "lost"
              ? _c("mypage-point-minus-icon")
              : _vm._e(),
            _vm.item.status === "get" ? _c("mypage-point-plus-icon") : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "mypage-point-item-parts__point__data" }, [
          _vm._v(_vm._s(_vm.item.point)),
        ]),
        _c("span", { staticClass: "mypage-point-item-parts__point__unit" }, [
          _vm._v("p"),
        ]),
      ]),
      _vm.item.description !== ""
        ? _c("p", { staticClass: "mypage-point-item-parts__description" }, [
            _vm._v(" " + _vm._s(_vm.item.description) + " "),
          ])
        : _vm._e(),
    ]),
    _c("p", { staticClass: "mypage-point-item-parts__date" }, [
      _vm._v(_vm._s(_vm.item.date)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }