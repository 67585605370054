






























































import { computed, defineComponent, inject, reactive, Ref, ref, watch } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import ModalTitleParts from '@/components/common/modal/parts/ModalTitleParts.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import I18n from '@/locales/I18n'
import LocationErrorModalSection from '@/components/common/modal/LocationErrorModalSection.vue'
import ModalCouponCodeInputParts from '@/components/common/modal/parts/ModalCouponCodeInputParts.vue'
import OneTimePassRegisterModalSection from '@/components/common/modal/OneTimePassRegisterModalSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import StoreUtil from '@/store/StoreUtil'
import useOneTimePassValidation from '@/components/hook/oneTimePass/useOneTimePassValidation'

export type FlowLineToPaidPlanModalDisplayModeType =
  | 'default'
  | 'locationError'
  | 'planRegistration'

/**
 * 無料会員が有料会員コンテンツへアクセスを試みた時に表示するモーダル
 * 非公開コンテンツへのアクセスを試みた場合にもこちらのモーダルを表示する
 */
export default defineComponent({
  name: 'FlowLineToPaidPlanModalSection',
  components: {
    OneTimePassRegisterModalSection,
    ModalCouponCodeInputParts,
    LocationErrorModalSection,
    CommonModalSection,
    ModalTitleParts,
    ModalMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  props: {
    /**
     * 非公開コンテンツかどうか
     */
    isPrivate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
  },
  setup(_, { emit }) {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const {
      oneTimePass,
      setOneTimePassPlan,
      getCouponPlan,
      fetchTargetCoupon,
      targetOneTimePass,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      clearCoupons,
      fetchTargetAvailableArea,
      getTargetAvailableArea,
    } = oneTimePassPageStore

    const { validateOneTimePass } = useOneTimePassValidation()

    const loading = inject('loading') as PluginApi

    const state = reactive({
      /** 入力したワンタイムパスコード */
      oneTimePassCode: '',
      /** エラー */
      oneTimePassError: false,
      /** エラーメッセージ */
      oneTimePassErrorMessage: '',
    })

    /** 取得したワンタイムパス情報 */
    const targetOneTimePassItem = ref(undefined) as Ref<CouponDocument | undefined>

    /**
     * モーダル内に表示するコンテンツ
     * - 通常： default
     * - 位置情報エラー: locationError
     * - プラン登録: planRegistration
     */
    const displayMode: Ref<FlowLineToPaidPlanModalDisplayModeType> = ref('default')

    // 閉じるボタンのラベルを解す
    const closeButtonLabel = computed(() =>
      displayMode.value === 'default' ? I18n.tc('common.close') : I18n.tc('common.cancel'),
    )

    /**
     * 入力したワンタイムパスコードに紐づく店舗名またはエリア名
     */
    const oneTimePassAvailableStoreName = computed(() => {
      const area = getTargetAvailableArea(targetOneTimePassItem.value?.availableArea?.areaId ?? '')
      return area?.storeOrAreaName
    })

    /**
     * プラン登録ありのワンタイムパス利用
     * 利用するワンタイムパスをユーザー情報に保存する
     */
    const setOneTimePassPlanOnDisplay = async (oneTimePassToBeUsed: CouponDocument) => {
      /** ワンタイムパスのプラン情報を取得 */
      const originalOneTimePassPlan = oneTimePassToBeUsed?.plans
        ? getCouponPlan(oneTimePassToBeUsed.plans)
        : null
      if (!originalOneTimePassPlan) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.title',
          ),
          message: I18n.tc(
            'MypagePage.MypageOneTimePass.inputPage.errors.getOneTimePassPlanError.message',
          ),
        })
        // ワンタイムパスのプラン情報が取得できない場合、画面遷移しない
        return { isSuccess: false }
      }

      // 取得したワンタイムパスをストアに格納
      oneTimePass.value = oneTimePassToBeUsed
      // 取得したワンタイムパスのプラン情報を元に画面表示用のワンタイムパスプラン情報を作成し、ストアに保存
      setOneTimePassPlan(originalOneTimePassPlan, oneTimePassToBeUsed)

      return { isSuccess: true }
    }

    /**
     * 使用するボタンが押下されたときの処理
     */
    const handlerSubmit = async () => {
      const loader = loading.show()
      if (state.oneTimePassCode) {
        clearCoupons()
        // 入力したコードに紐づくワンタイムパス情報を取得
        const result = await fetchTargetCoupon(state.oneTimePassCode)
        if (!result.isSuccess && result.response?.isNetworkError) {
          loader.hide()
          // ネットワークエラーでクーポンを取得できなかった場合は、エラーメッセージを表示する
          await MessageDialogStore.value.open?.({
            errorApiResponse: result.response,
            isVisibleCancelButton: false,
          })
          return
        }
      }

      // 入力したコードに紐づくワンタイムパス情報
      targetOneTimePassItem.value = targetOneTimePass(state.oneTimePassCode)

      // バリデーションチェックを実行
      const validationResult = validateOneTimePass(
        state.oneTimePassCode,
        targetOneTimePassItem.value,
      )
      state.oneTimePassError = validationResult.isError
      state.oneTimePassErrorMessage = validationResult.errorMessage

      // エラーがある場合、後続処理を実施しない
      if (state.oneTimePassError) {
        loader.hide()
        return
      }

      const oneTimePassAvailableAreaId = targetOneTimePassItem.value?.availableArea?.areaId ?? ''
      const checkLocationTiming = targetOneTimePassItem.value?.availableArea?.checkLocationTiming

      const fetchTargetAvailableAreaResult = await fetchTargetAvailableArea(
        oneTimePassAvailableAreaId,
      )
      if (!fetchTargetAvailableAreaResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.fetchAvailableAreaDataError.title'),
          message: I18n.tc('common.errors.fetchAvailableAreaDataError.message'),
        })
        return
      }

      if (checkLocationTiming === 'watchingVideo') {
        const result = await checkIsInAreaCanUseOneTimePass(oneTimePassAvailableAreaId)
        if (result.isInArea) {
          // ワンタイムパス利用可能エリア内の場合、ワンタイムパスの利用履歴を登録する
          emit('onSaveOneTimePassUsage', targetOneTimePassItem.value)
        } else if (!result.isInArea && result.isDisplayLatLng) {
          // ワンタイムパス利用可能エリア外の場合、緯度経度情報などのエラーを表示する
          // 例外処理が発生した際には別のエラーモーダルが表示されるため、ワンタイムパス入力モーダル表示を切り替えない
          displayMode.value = 'locationError'
        }
      } else {
        const result = await setOneTimePassPlanOnDisplay(
          targetOneTimePassItem.value as CouponDocument,
        )
        if (!result.isSuccess) {
          return
        }

        displayMode.value = 'planRegistration'
      }

      loader.hide()
    }

    /**
     * プラン登録ありのワンタイムパス登録時の処理
     */
    const onClickRegistration = async () => {
      // ワンタイムパス入力して使用するボタンを押した時に取得した対象のワンタイムパス
      const oneTimePassAvailableAreaId = targetOneTimePassItem.value?.availableArea?.areaId ?? ''

      if (!oneTimePassAvailableAreaId) {
        // サーキットなどの現地でのみ利用可能なワンタイムパス以外の場合
        // 入力したワンタイムパスで契約情報契約プランを登録する
        emit('onClickRegistration')
        return
      }

      const loader = loading.show()
      const result = await checkIsInAreaCanUseOneTimePass(oneTimePassAvailableAreaId)
      loader.hide()
      if (result.isInArea) {
        // ワンタイムパス利用可能エリア内の場合、入力したワンタイムパスで契約情報契約プランを登録
        emit('onClickRegistration')
      } else if (!result.isInArea && result.isDisplayLatLng) {
        // ワンタイムパス利用可能エリア外の場合、緯度経度情報などのエラーを表示する
        // 例外処理が発生した際には別のエラーモーダルが表示されるため、ワンタイムパス入力モーダル表示を切り替えない
        displayMode.value = 'locationError'
      }
    }

    /**
     * ワンタイムパスの入力値が変化したタイミングでバリデーションエラーを消す
     */
    watch(
      () => state.oneTimePassCode,
      () => {
        state.oneTimePassError = false
      },
    )

    return {
      // FlowLineToPaidPlanModalSection
      state,
      displayMode,
      closeButtonLabel,
      oneTimePassAvailableStoreName,
      handlerSubmit,
      onClickRegistration,
      // OneTimePassPageStore
      currentLocationInfo,
    }
  },
  methods: {
    emitClose() {
      /**
       * closeModal
       * 親コンポーネントへ閉じる処理を通知
       */
      if (this.displayMode === 'planRegistration') {
        // プラン確認画面の場合、最初のモーダルに戻る
        this.displayMode = 'default'
        return
      }
      this.$emit('close')
    },
  },
})
