var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-section",
      class: {
        "login-section--mode-edit":
          _vm.loginType !== "login" && _vm.loginType !== "notification",
      },
    },
    [
      _c(
        "div",
        { staticClass: "login-section__input-area" },
        [
          _c("ValidationObserver", { ref: "observer", attrs: { tag: "div" } }, [
            _c(
              "div",
              { staticClass: "login-section__input-item" },
              [
                _c("ValidationProvider", {
                  attrs: { name: "email", rules: "required", tag: "div" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return [
                          _c("atom-input-e-mail", {
                            staticClass:
                              "login-section__input-area__email-field",
                            class: {
                              "login-section__input-area__email-field--validation-error":
                                _vm.showLoginError,
                            },
                            attrs: {
                              id: "email",
                              placeholder: _vm.$tc("placeholder.email"),
                              error: _vm.showLoginError,
                            },
                            on: { enter: _vm.login },
                            model: {
                              value: _vm.loginSectionState.loginName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.loginSectionState,
                                  "loginName",
                                  $$v
                                )
                              },
                              expression: "loginSectionState.loginName",
                            },
                          }),
                          _c("error-message-parts", {
                            staticClass:
                              "login-section__input-area__error-message",
                            attrs: {
                              showError: errors.length > 0,
                              message: errors[0],
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "login-section__input-item" },
              [
                _c("ValidationProvider", {
                  attrs: { name: "password", rules: "required", tag: "div" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return [
                          _c("password-with-toggle-button-parts", {
                            staticClass: "login-section__input-area__pw-field",
                            class: {
                              "login-section__input-area__pw-field--validation-error":
                                _vm.showLoginError,
                            },
                            attrs: {
                              id: "password",
                              placeholder: _vm.$tc("placeholder.password"),
                              error: _vm.showLoginError,
                            },
                            on: { enter: _vm.login },
                            model: {
                              value: _vm.loginSectionState.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginSectionState, "password", $$v)
                              },
                              expression: "loginSectionState.password",
                            },
                          }),
                          _c("error-message-parts", {
                            staticClass:
                              "login-section__input-area__error-message",
                            attrs: {
                              showError:
                                _vm.showLoginError || errors.length > 0,
                              message:
                                errors[0] || _vm.$tc("LoginPage.loginError"),
                            },
                          }),
                          _c("error-message-parts", {
                            staticClass:
                              "login-section__input-area__error-message",
                            attrs: {
                              showError: _vm.showNetworkError,
                              message: "" + _vm.$tc("LoginPage.networkError"),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("p", { staticClass: "login-section__input-area__forgot" }, [
            _c(
              "a",
              {
                staticClass: "login-section__input-area__forgot__link",
                attrs: { href: _vm.passwordResetUrl },
              },
              [_vm._v(_vm._s(_vm.$tc("LoginPage.forgetPassword")))]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "login-section__buttons" },
        [
          _c("atom-input-button", {
            staticClass: "login-section__login-btn",
            attrs: {
              linkText: _vm.submitText,
              isDisabled: false,
              theme: _vm.submitButtonTheme,
              colorType: "primary",
            },
            on: { push: _vm.login },
          }),
          _vm.loginType === "login" || _vm.loginType === "notification"
            ? _c("div", { staticClass: "login-section__signup" }, [
                _c("ul", { staticClass: "login-section__signup__list" }, [
                  _c(
                    "li",
                    { staticClass: "login-section__signup__item" },
                    [
                      _vm.isApp
                        ? _c("atom-router-button", {
                            attrs: {
                              to: "/signup",
                              isOpenAsBlank: _vm.isIosDevice,
                              linkText: _vm.signupButtonLabel,
                              theme: _vm.submitButtonTheme,
                              colorType: "secondary",
                            },
                          })
                        : _c("atom-link-button", {
                            attrs: {
                              url: _vm.signupUrl,
                              isOpenAsBlank: _vm.isIosDevice,
                              linkText: _vm.signupButtonLabel,
                              theme: _vm.submitButtonTheme,
                              colorType: "secondary",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm.isApp
                    ? _c(
                        "li",
                        { staticClass: "login-section__signup__item" },
                        [
                          _c("atom-router-button", {
                            attrs: {
                              to: "/signup/one-time-pass",
                              linkText: _vm.$tc(
                                "LoginPage.signupLabelForOneTimePass"
                              ),
                              theme: _vm.submitButtonTheme,
                              colorType: "secondary",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }