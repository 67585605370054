import { StoreBase, ValueType } from '@/store/StoreBase'
import useUser from '@/store/hook/useUser'
import useBillingInfo from '@/store/hook/useBillingInfo'
import useReceivedData from '@/store/hook/useReceivedData'

/**
 * ユーザーデータを扱うStore
 * 本ストアはGlobalStoreとする
 */
/* eslint-disable class-methods-use-this */
class UserStore implements StoreBase {
  createStore() {
    // hook
    const { fetchUser, user, clearUsers } = useUser()
    const {
      fetchFixedBillingInfo,
      ownBillingInfo,
      settlementFailedOwnBillingInfo,
      clearBillingInfo,
    } = useBillingInfo()
    const { fetchReceivedData, attachedDataIdList, clearReceivedData } = useReceivedData()

    /**
     * ユーザー情報を取得する
     */
    const fetchUserData = async (loginId?: string) => {
      if (!loginId) {
        return
      }
      await fetchUser(loginId)
    }

    /**
     * 初回ログインかどうかを判定
     * 必須入力となっている名前(漢字)の姓が未入力の場合は初回ログインと判断する
     */
    const isInitLogin = () => !user.value?.additionalData?.familyName

    /**
     * プロフィール/お気に入りミッション達成済みかどうか
     * ユーザー情報に登録されているデータを用いて判定
     */
    const profileAndFavoriteMissionCompleted = () =>
      !!(
        user.value?.birthDay &&
        user.value?.country &&
        user.value?.gender &&
        user.value?.favoritePlayerId &&
        user.value?.favoriteTeamId
      )

    /**
     * ユーザー情報をクリアする
     */
    const clearUserData = () => {
      clearUsers()
      clearBillingInfo()
      clearReceivedData()
    }

    return {
      // useUser
      user,
      // useBillingInfo
      fetchFixedBillingInfo,
      ownBillingInfo,
      settlementFailedOwnBillingInfo,
      // useReceivedData
      fetchReceivedData,
      attachedDataIdList,
      // UserStore
      fetchUserData,
      isInitLogin,
      profileAndFavoriteMissionCompleted,
      clearUserData,
    }
  }
}
const value: ValueType<UserStore> = {}

export default {
  createStore: new UserStore().createStore,
  value: value as Required<typeof value>,
}
