










import { defineComponent, PropType } from '@vue/composition-api'
import ConfirmationSentSection from '@/components/AppSignupPage/AppConfirmationSentPane/ConfirmationSentSection.vue'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import { AppSignUpFlowType } from '@/store/stores/pageStore/SignupPage/SignupType'

/**
 * アプリ新規会員登録: 認証メール送信完了ペインのコンポーネント
 */
export default defineComponent({
  name: 'AppConfirmationSentPane',
  components: { SubHeaderSection, ConfirmationSentSection },
  props: {
    /**
     * アプリ内会員登録: 会員登録フロータイプ
     */
    appSignUpFlowType: {
      type: String as PropType<AppSignUpFlowType>,
      default: 'free',
    },
  },
  methods: {
    moveToEmailAuthentication() {
      const page =
        this.appSignUpFlowType === 'one-time-pass'
          ? 'AppSignupOneTimePassConfirmPage'
          : 'AppSignupTopPage'
      this.$router.replace({ name: page })
    },
  },
})
