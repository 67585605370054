import APIResponse from '@/util/APIResponse'
import DocumentWrapper from './documents/DocumentWrapper'

/**
 * このファイルでは特別に1fileに複数のクラスを定義できるようにする
 */
/* eslint max-classes-per-file:0 */

/**
 * クエリに指定できる型
 * 各項目の詳細は以下のURLを参照する
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/308249051
 */
class Query {
  /**
   * 取得件数を指定する
   * 有効値は1～10000
   * nullを指定した場合は20になる
   */
  limit?: number

  /**
   * 並び順を指定する
   * (降順はマイナス付与)
   * カンマで区切ると複数項目の並べ替えが可能
   * ソートキーの上限は5個
   */
  sort?: string

  /* eslint-disable @typescript-eslint/no-explicit-any */
  /**
   * 検索条件を指定することでデータの絞込の指定が可能
   * filterで利用可能な演算子はMongoDBのクエリと同じ形式が利用可能。
   * https://docs.mongodb.com/manual/reference/operator/query/
   * ただしISOLATE()などの関数は利用不可
   */
  filter?: { [Key: string]: any }

  /* eslint-enable @typescript-eslint/no-explicit-any */
  /**
   * 展開する情報のパラメータを指定する
   * カンマで区切ると複数項目の展開が可能
   * 一部のAPIにしか対応していない
   */
  expand?: string

  /**
   * trueの場合、条件にマッチしたデータのレコード数を取得する
   */
  count?: boolean

  /**
   * 固定のクエリを使用して実行できないAPIがある場合に使用する
   * TODO: collectionModuleをsfgo-web-api用を作成した方が良い
   */
  additionalQuery?: object
}

export type BaseOptions = {
  /**
   * documentに設定されているpathの代わりに、ここで指定されたpathを使用することができる
   * urlが指定されている場合はurlを優先して使用することに注意する
   */
  path?: string

  /**
   * documentに設定されているpathの代わりに、完全な形式のurlを指定したい場合はこちらを指定する
   * 指定例 'http://localhost:8080/api/v0/auth'
   */
  url?: string

  /**
   * SFgo Web APIかどうか
   * FL-UX APIとSFgo Web API両方で使っているDocumentの場合、Documentに_isSfgoApiを指定できないため、optionに指定して利用する
   */
  isSfgoApi?: boolean
}
/**
 * CollectionModuleのfetchの引数(option)に指定する型
 */
export type FetchOptions = {
  /**
   * fetch時にpathに指定するid
   * 指定しない場合はコレクション全体を取得する
   */
  targetId?: string | undefined | null

  /**
   * リクエスト送信時に指定するクエリ
   */
  query?: Query

  /**
   * fetch時にfetchの結果をCollectionModuleに保存するかどうか
   */
  isSaveInStore?: boolean

  /**
   * fetch時にfetch結果のデータを既存のデータと結合するかどうか
   * trueの場合、以下ように動作する
   *   * 重複しているデータは新しいデータで置き換えられる
   *   * 既存データに存在しないデータは追加される
   *   * 既存データにのみ存在するデータは残される
   */
  isUnionExistData?: boolean

  /**
   * fetch時に、fetch結果のデータを既存のデータに追加する.
   * このパラメタはデータをページングして取得し、
   * 既存のデータと取得したデータに重複データが含まれないことが明確な場合のみ利用する。
   * fetchSplit の処理で利用される。
   */
  isAppendData?: boolean

  /**
   * fetch時のエラーダイアログをawaitするかどうか
   */
  awaitErrorMessage?: boolean
  /**
   * 論理削除されたデータを検索対象とするかどうか
   */
  includeDeleted?: boolean
  /**
   * filterクエリを含めるかどうか
   */
  excludeFilter?: boolean
  /**
   * 取得条件に他組織から共有されたデータを含めるかどうか
   * url OR path が指定されていたらこの設定は無視される
   * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/3827007925/PublicScope
   */
  includePublicScope?: boolean
} & BaseOptions
/**
 * CollectionModuleのsaveの引数(option)に指定する型
 */
export type SaveOptions = {
  /**
   * save後にfetch処理を行うかどうか
   */
  reload?: boolean

  /**
   * save後にfetch処理を行うオプション
   */
  reloadOptions?: FetchOptions
} & BaseOptions
/**
 * CollectionModuleのdeleteの引数(option)に指定する型
 */
export type DeleteOptions = {
  /**
   * delete後にfetch処理を行うかどうか
   */
  reload?: boolean

  /**
   * delete後にfetch処理を行うオプション
   */
  reloadOptions?: FetchOptions

  /**
   * 論理削除するかどうか
   */
  isSoftDelete?: boolean
} & BaseOptions

/**
 * fetch時の返り値の型
 */
export type Response<T extends DocumentWrapper> = {
  /**
   * fetch処理が成功したかどうか
   */
  isSuccess: boolean
  /**
   * 取得したDocumentのリスト
   */
  data: T[]
  count: number | null
  response?: APIResponse
}
/**
 * save時の返り値の型
 */
export type SaveResponse<T extends DocumentWrapper> = {
  /**
   * save処理が成功したかどうか
   */
  isSuccess: boolean
  /**
   * SaveしたDocument
   */
  data: T | null
  response?: APIResponse
}
/**
 * delete時の返り値の型
 */
export type DeleteResponse = {
  /**
   * delete処理が成功したかどうか
   */
  isSuccess: boolean
  response?: APIResponse
}

/**
 * FetchOptionsの初期値を返す
 * @returns FetchOptionsの初期値
 */
export const initFetchOptions = (): FetchOptions => ({
  targetId: null,
  query: new Query(),
  isSaveInStore: true,
  isUnionExistData: false,
  awaitErrorMessage: false,
  includeDeleted: false,
})

/**
 * SaveOptionsの初期値を返す
 * @returns SaveOptionsの初期値
 */
export const initSaveOptions = (): SaveOptions => ({
  reload: false,
  reloadOptions: initFetchOptions(),
})
/**
 * DeleteOptionsの初期値を返す
 * @returns DeleteOptionsの初期値
 */
export const initDeleteOptions = (): SaveOptions => ({
  reload: false,
  reloadOptions: initFetchOptions(),
})
