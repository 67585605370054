












import { defineComponent, reactive } from '@vue/composition-api'
import { LoaderComponent } from 'vue-loading-overlay'
import SignupHeaderSection from '@/components/SignupPage/common/SignupHeaderSection.vue'
import SignupFormSection from '@/components/SignupPage/RegistrationPane/SignupFormSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useContract from '@/components/hook/useContract'
import Const from '@/util/Const'
import useLogin from '@/components/hook/useLogin'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'

/**
 * 新規会員登録: 会員情報入力画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'RegistrationPane',
  components: {
    SignupHeaderSection,
    SignupFormSection,
  },
  props: {
    /**
     * 登録トークン管理APIで発行されたトークンID
     */
    tokenId: {
      type: String,
      required: true,
    },
    /**
     * 認証メール送信画面で入力したメールアドレス
     */
    email: {
      type: String,
      required: true,
    },
  },
  setup() {
    const loginStore = StoreUtil.useStore('LoginStore')
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const userStore = StoreUtil.useStore('UserStore')
    const { createContract } = useContract()
    const { login: _login } = useLogin()
    const { checkEmailAddressDuplicated } = usePasswordResetToken()

    const state = reactive({
      isRegistering: false,
    })

    return {
      state,
      loginStore,
      registerUser: signupPageStore.registerUser,
      fetchUser: signupPageStore.fetchUser,
      userStoreFetchUser: userStore.fetchUserData,
      saveRegistrationTypeInProgress: signupPageStore.saveRegistrationTypeInProgress,
      memberType: signupPageStore.memberType,
      createContract,
      _login,
      // usePasswordResetToken
      checkEmailAddressDuplicated,
    }
  },
  methods: {
    /**
     * 入力したメールアドレスが登録済みか判定する
     */
    async checkExistMailAddress(loader: LoaderComponent) {
      const result = await this.checkEmailAddressDuplicated(this.email)

      if (!result?.isSuccess && result.response?.data?.error_code === '409_0000') {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.RegistrationPage.errors.duplicateEmail.title'),
          message: this.$tc('SignupPage.RegistrationPage.errors.duplicateEmail.message'),
          errorApiResponse: result?.response,
        })
        return { isSuccess: false }
      }

      return { isSuccess: true }
    },
    /**
     * 以下の処理を行う
     * - メールアドレス重複チェック
     * - 新規会員登録
     * - 契約情報契約プランを登録
     */
    async handlerSubmit(password: string) {
      this.state.isRegistering = true
      const loader = this.$loading.show()
      const errorStore = StoreUtil.useStore('ErrorStore')

      /** メールアドレス重複チェック */
      const checkExistMailAddressResult = await this.checkExistMailAddress(loader)
      if (!checkExistMailAddressResult.isSuccess) {
        errorStore.setConfig('registerError')
        await this.$router.replace({ name: 'ErrorPage' })
        return
      }

      /** 新規会員登録 */
      const registerUserResult = await this.registerUser(this.tokenId, password)
      if (!registerUserResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$t('SignupPage.RegistrationPage.errors.registerError.title') as string,
          message: this.$t('SignupPage.RegistrationPage.errors.registerError.message') as string,
        })
        errorStore.setConfig('registerError')
        await this.$router.replace({ name: 'ErrorPage' })
        return
      }

      /** 登録したユーザーでログイン */
      await this._login(this.email, password)

      /** プラン登録手続き中のフラグをセット */
      // ログインユーザー情報を取得（グローバルストアにもユーザー情報を入れておく）
      await Promise.all([
        this.fetchUser(this.loginStore.loginId),
        this.userStoreFetchUser(this.loginStore.loginId),
      ])
      // ログインユーザーをプラン登録手続き中にする
      const saveProgressStartResult = await this.saveRegistrationTypeInProgress(
        this.memberType,
        'start',
      )
      if (!saveProgressStartResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$t('SignupPage.RegistrationPage.errors.createContractError.title') as string,
          message: this.$t(
            'SignupPage.RegistrationPage.errors.createContractError.message',
          ) as string,
        })
        errorStore.setConfig('createContractError')
        await this.$router.replace({ name: 'ErrorPage' })
        return
      }

      if (this.memberType === Const.MEMBER_TYPE.FREE) {
        /** 契約情報契約プランを登録
         * プランが存在しない場合、ログインのタイミングで無料プランを登録するため、ここでプラン登録が失敗したとしてもエラーメッセージは出さない
         */
        const createContractResult = await this.createContract('freePlan')
        if (createContractResult.isSuccess) {
          // プラン登録が成功した場合
          /** プラン登録手続き完了にする */
          // ログインユーザーのプラン登録手続きを完了にする（既にプラン登録に成功している状態のため、ここではエラー処理を考慮しない）
          await this.saveRegistrationTypeInProgress(this.memberType, 'end')
        }
      }

      this.$emit('formSubmit')
      loader.hide()
      this.state.isRegistering = false
    },
  },
})
