import Pusher, { Channel } from 'pusher-js/with-encryption'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import RealtimeMessagingEndPointDocument from '@/store/stores/collectionModule/documents/realtimeMessaging/RealtimeMessagingEndPointDocument'
import Logger from '@/util/logger/Logger'

/**
 * RTMで受信するイベント名と、Pusherに指定するイベント名とのマッピング
 */
const eventNameMap = {
  /** イベント名: レース */
  race: ['game'],
  /** イベント名: ハイライト */
  highlight: ['communication'],
  /** イベント名: オペレーション */
  operation: ['operation'],
  /** イベント名: ログイン */
  login: ['login'],
  /** イベント名： お知らせ */
  information: ['information'],
}

/**
 * 通知メッセージ：ハイライトコメント
 */
export type CommunicationCommentMessageType = {
  commentId: string
  userGameEventId: string
}

/**
 * RTM通知のコールバックの引数
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/617120122/FL-UX
 *      通知メッセージ
 */
export type RTMCallbackParamType = {
  table:
    | 'contents_info'
    | 'communication_user_game_event'
    | 'communication_comment'
    | 'game_match'
    | 'communication_user_group'
    | 'radio-data'
    | 'manage_operation_log'
    | 'manage_session'
    | 'information'
  type: 'created' | 'edited' | 'deleted'
  message: Record<string, unknown> | string | CommunicationCommentMessageType
}

/**
 * RTM通知のコールバック関数の型
 */
export type RTMCallbackType = (data: RTMCallbackParamType) => void

/**
 * リアルタイムメッセージを利用するための処理を提供する。
 */
export default function useRealtimeMessaging() {
  // Collection modules
  const RTMEndPointCollectionModule = CollectionModule.createStore(
    RealtimeMessagingEndPointDocument,
  )

  const fetchRTMEndPoint = () => RTMEndPointCollectionModule.fetch()

  let RTMEndpoint: RealtimeMessagingEndPointDocument
  let pusher: Pusher
  let channel: Channel
  let personalChannel: Channel
  let publicChannel: Channel

  /**
   * RTMを初期化する。
   */
  const initRTM = async () => {
    Logger.debug('useRealtimeMessaging#initRTM: Start to initialize RealtimeMessaging.')
    const apiResponse = await fetchRTMEndPoint()
    if (!apiResponse.isSuccess) {
      Logger.info(
        `useRealtimeMessaging#subscribe: Failed to fetch RTM endpoint. APIResponse: ${apiResponse}`,
      )
      return
    }
    const endPoint = RTMEndPointCollectionModule.data[0]
    if (!endPoint?.key || !endPoint?.cluster) {
      Logger.info(
        `useRealtimeMessaging#subscribe: RTM endpoint is invalid. No key or cluster value. RTM endpoint: ${endPoint}`,
      )
      return
    }
    RTMEndpoint = endPoint
    pusher = new Pusher(endPoint.key, {
      cluster: endPoint.cluster,
      enabledTransports: ['ws'],
    })
    Logger.debug('useRealtimeMessaging#initRTM: RealtimeMessaging has been initialized.')
  }
  /**
   * RTMのチャンネルを購読する。
   * @param event 購読するイベント名
   * @param callback イベントが発生した際に呼び出されるコールバック関数
   */
  const subscribeRTM = (event: keyof typeof eventNameMap, callback: RTMCallbackType) => {
    if (
      !pusher ||
      !RTMEndpoint?.commonChannelName ||
      !RTMEndpoint?.personalChannelName ||
      !RTMEndpoint?.publicChannelName
    ) {
      Logger.warn(
        'useRealtimeMessaging#initRTM: RealtimeMessaging has not been initialized. Please call initRTM.',
      )
      return
    }
    channel = pusher.subscribe(RTMEndpoint.commonChannelName)
    personalChannel = pusher.subscribe(RTMEndpoint.personalChannelName)
    publicChannel = pusher.subscribe(RTMEndpoint.publicChannelName)

    eventNameMap[event].forEach((eventName) => {
      channel.bind(eventName, callback)
      personalChannel.bind(eventName, callback)
      publicChannel.bind(eventName, callback)
    })
  }

  /**
   * 指定されたチャンネルの行動を停止する。
   *
   * @param channelName チャンネル名
   */
  const unsubscribe = (channelName: string | null) => {
    if (channelName) {
      pusher.unsubscribe(channelName)
    }
  }

  /**
   * RTMチャンネルの購読をやめる
   */
  const unSubscribeRTM = () => {
    if (
      !pusher ||
      !RTMEndpoint?.commonChannelName ||
      !channel ||
      !personalChannel ||
      !publicChannel
    ) {
      return
    }
    channel.unbind()
    personalChannel.unbind()
    publicChannel.unbind()

    pusher.disconnect()

    unsubscribe(RTMEndpoint.commonChannelName)
    unsubscribe(RTMEndpoint.personalChannelName)
    unsubscribe(RTMEndpoint.publicChannelName)
  }

  return {
    initRTM,
    subscribeRTM,
    unSubscribeRTM,
  }
}
