






















import { defineComponent } from '@vue/composition-api'

/**
 * 共通 オーバーレイ 拒否コード テンプレート
 */
export default defineComponent({
  name: 'OverlayDeclineMessageParts',
  props: {
    declineCode: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    nextUserAction: {
      type: String,
      default: '',
    },
  },
})
