

















import { defineComponent } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import OneTimePassInputSection from '@/components/MypageOneTimePassInputPage/OneTimePassInputPane/OneTimePassInputSection.vue'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'

/**
 * アプリ新規会員登録: ワンタイムパス登録ペインのコンポーネント
 */
export default defineComponent({
  name: 'AppSignupOneTimePassPane',
  components: { OneTimePassInputSection, SubHeaderSection },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { oneTimePass, setOneTimePassPlan, getCouponPlan } = oneTimePassPageStore

    return {
      oneTimePass,
      setOneTimePassPlan,
      getCouponPlan,
    }
  },
  methods: {
    /**
     * ワンタイムパス登録ボタン押下時以下の処理を行う
     * 1. 取得したワンタイムパスのプラン情報をストアに保存
     * 2. 確認画面に遷移するための通知を親コンポーネントに伝える
     */
    async setOneTimePassPlanAndOnSubmit(oneTimePass: CouponDocument) {
      /** ワンタイムパスのプラン情報を取得 */
      const originalOneTimePassPlan = oneTimePass?.plans
        ? this.getCouponPlan(oneTimePass.plans)
        : null
      if (!originalOneTimePassPlan) {
        await MessageDialogStore.value.open({
          title: I18n.tc(
            'SignupPage.AppSignupOneTimePassPage.errors.getOneTimePassPlanError.title',
          ),
          message: I18n.tc(
            'SignupPage.AppSignupOneTimePassPage.errors.getOneTimePassPlanError.message',
          ),
        })
        // ワンタイムパスのプラン情報が取得できない場合、画面遷移しない
        return
      }

      // 取得したワンタイムパスをストアに格納
      this.oneTimePass = oneTimePass
      // 取得したワンタイムパスのプラン情報を元に画面表示用のワンタイムパスプラン情報を作成し、ストアに保存
      this.setOneTimePassPlan(originalOneTimePassPlan, oneTimePass)

      /** 確認画面への遷移 */
      this.$emit('handlerSubmit')
    },
  },
})
