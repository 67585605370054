var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-coupon-code-input-parts" }, [
    _c(
      "div",
      { staticClass: "modal-coupon-code-input-parts__input" },
      [
        _c("fieldset-text-parts", {
          attrs: {
            label: _vm.$tc("common.flowLineToPaidPlan.discount.code"),
            placeholder: _vm.$tc(
              "common.flowLineToPaidPlan.discount.placeholder"
            ),
            showError: _vm.hasError,
            errorMessage: _vm.errorMessage,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "modal-coupon-code-input-parts__submit" },
      [
        _c("atom-button", {
          attrs: { linkText: _vm.buttonLabel },
          on: { onClick: _vm.handleUseClicked },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }