import qs from 'qs'
import OAuthHelper from '@/util/oauth/OAuthHelper'
import Logger from '@/util/logger/Logger'

export type OAuthCodeFlowType = 'google'

type GoogleScopeType = 'email' | 'profile'

type AuthOptionRequestType = {
  response_type: 'code' | 'token' | 'id_token'
  client_id: string
  redirect_uri: string
  scope: string
  state: string
  code_challenge: string
  code_challenge_method: 'plain' | 'S256'
}

/**
 * OAuth 2.0 Authorization Code Flow with PKCEの処理を行うためのクラス
 */
export default class OAuthCodeFlowBrowser<T extends OAuthCodeFlowType> {
  /**
   * Code Flowの種類
   */
  type!: OAuthCodeFlowType

  /**
   * OAuth認可URLに指定するリダイレクトURL
   */
  authUri: string | null = null

  /**
   * OAuth認可URLに指定するリダイレクトURL
   */
  tokenUri: string | null = null

  /**
   * OAuth認可URLに指定するリダイレクトURL
   */
  redirectUri: string | null = null

  /**
   * OAuth 2.0 Client ID
   */
  clientId: string | null = null

  /**
   * OAuth 認可URLに指定するためのstate値
   */
  state!: string

  /**
   * PKCE Code Verifier値
   */
  codeVerifier!: string

  constructor(type: T, state?: string, codeVerifier?: string) {
    this.type = type
    this.state = !state ? OAuthHelper.generateRandomString() : state
    this.codeVerifier = !codeVerifier ? OAuthHelper.generateRandomString() : codeVerifier
    switch (type) {
      case 'google':
        this.clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID
        this.authUri = 'https://accounts.google.com/o/oauth2/auth'
        this.tokenUri = 'https://oauth2.googleapis.com/token'
        this.redirectUri = process.env.VUE_APP_BROWSER_CAN_REDIRECT_TO_APP_BASE_URL
        break
      default:
        break
    }
  }

  /**
   * OAuth Authorization Code Flow を開始する。
   */
  public startOauthCodeFlow(scope: Array<GoogleScopeType>) {
    if (this.clientId && this.redirectUri) {
      const oAuthOptions: AuthOptionRequestType = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: `${this.redirectUri}`,
        scope: scope.join(' '),
        state: this.state,
        code_challenge: this.codeVerifier,
        code_challenge_method: 'plain',
      }
      Logger.debug(
        `OAuthCodeFlowBrowser#startOauthCodeFlow: oAuthOptions: ${JSON.stringify(oAuthOptions)}`,
      )
      const urlQueryParams = qs.stringify(oAuthOptions)
      const openUrl = `${this.authUri}?${urlQueryParams}`
      Logger.debug(`OAuthCodeFlowBrowser#startOauthCodeFlow: openUrl: ${openUrl}`)
      window.open(openUrl, '_system')
    }
  }
}
