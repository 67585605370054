import Logger from '@/util/logger/Logger'

type FullNameType = {
  nickname?: string
  phoneticRepresentation?: object
  middleName?: string
  familyName?: string
  namePrefix?: string
  givenName?: string
  nameSuffix?: string
}

/**
 * Sign in with appleで受け取れる認可したユーザーの情報
 */
export type AppleSignInResponse = {
  // Apple ID の一意の識別子
  user: string
  // 初回のみ取得できる
  email?: string
  // 初回のみ取得できる
  fullName?: FullNameType
  // IDトークン（JWT）
  identityToken: string
  // 認可コード
  authorizationCode: string
}

export type AppleSignInError = {
  // エラーコード
  code: string
  // エラーメッセージ
  message?: string
}

/**
 * Sign in with appleに関する処理をまとめたhook
 */
export default function useSignInWithApple() {
  /**
   * サインインがエラーかどうかを判定する
   */
  const isAppleSignInError = (
    response: AppleSignInResponse | AppleSignInError,
  ): response is AppleSignInError => !!(response as AppleSignInError)?.code

  /**
   * サインインを実施する（iosでのみ）
   */
  const appleSignIn = (): Promise<AppleSignInResponse | AppleSignInError> =>
    new Promise((resolve) => {
      // Apple サインインを呼び出し
      window.cordova.plugins.SignInWithApple.signin(
        {
          requestedScopes: [
            0, // 名前
            1, // メール
          ],
        },
        (response: AppleSignInResponse) => {
          // サインイン成功時の情報を取得
          Logger.debug(
            `useSignInWithApple#signIn: Success to apple sign. response: ${JSON.stringify(
              response,
            )}`,
          )
          return resolve(response)
        },
        (err: AppleSignInError) => {
          Logger.error(
            `useSignInWithApple#signIn: Failed to apple sign. error: ${JSON.stringify(err)}`,
          )
          return resolve(err)
        },
      )
    })

  return { isAppleSignInError, appleSignIn }
}
