var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-point-year-section" }, [
    _c("p", { staticClass: "mypage-point-year-section__title" }, [
      _vm._v(_vm._s(_vm.monthlyData.month)),
    ]),
    _c(
      "ul",
      { staticClass: "mypage-point-year-section__list" },
      _vm._l(_vm.monthlyData.pointList, function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "mypage-point-year-section__item" },
          [_c("mypage-point-item-parts", { attrs: { item: item } })],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }