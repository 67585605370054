


















import { defineComponent, onBeforeUnmount, onMounted, ref, Ref } from '@vue/composition-api'
import UserInitSection from '@/components/MypageUserInitPage/MypageUserInitPane/UserInitSection.vue'
import type { OpenBirthdayMenuIdType } from '@/components/common/form/FieldsetBirthdayParts.vue'
import StoreUtil from '@/store/StoreUtil'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import type { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'

/**
 * 初期会員情報入力画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'MypageUserInitPane',
  components: {
    UserInitSection,
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const { user } = mypagePageStore

    const openBirthdayMenuId = ref('' as OpenBirthdayMenuIdType)

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }
    /**
     * チームロゴ画像のURL
     * @param team チーム情報
     */
    const teamLogoUrl = (team?: TeamDocument) => {
      if (!team) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(team.teamLogo)
    }
    /**
     * チーム表示名を取得する
     * @param target チーム
     */
    const getTeamName = (target: TeamDocument) => {
      if (target.teamShortName) {
        return target.teamShortName
      }
      return target.teamName
    }

    const handleBirthdayClicked = (menuId: OpenBirthdayMenuIdType) => {
      openBirthdayMenuId.value = openBirthdayMenuId.value === menuId ? '' : menuId
    }

    const closeBirthdayOption = () => {
      openBirthdayMenuId.value = ''
    }

    onMounted(() => {
      window.addEventListener('scroll', closeBirthdayOption)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', closeBirthdayOption)
    })

    return {
      mailAddress: user.value.mailAddress as string,
      lang: user.value.lang as UserLang,
      editUserInfo: mypagePageStore.editUserInfo as Ref<EditUserType>,
      favoritePlayer: mypagePageStore.savingFavoritePlayer as Ref<PlayerDocument>,
      favoriteTeam: mypagePageStore.savingFavoriteTeam as Ref<TeamDocument>,
      playerPictureUrl,
      teamLogoUrl,
      getTeamName,
      handleBirthdayClicked,
      openBirthdayMenuId,
      closeBirthdayOption,
    }
  },
  methods: {
    /**
     * 入力した会員情報をストアに保存する
     */
    setEditUserInfo(editUserData: EditUserType) {
      this.editUserInfo = editUserData
    },
  },
})
