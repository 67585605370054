var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-ec-site-connect-button-parts" },
    [
      _c("h3", { staticClass: "mypage-ec-site-connect-button-parts__title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc("MypagePage.MypageEcSiteConnectPage.connect.title")
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "mypage-ec-site-connect-button-parts__description" },
        [
          _vm.ecConnectStatus === "connectedGuestOnly"
            ? _c("p", {
                staticClass:
                  "mypage-ec-site-connect-button-parts__description-guest",
                domProps: {
                  textContent: _vm._s(
                    _vm.$tc(
                      "MypagePage.MypageEcSiteConnectPage.connect.description.guestOnly"
                    )
                  ),
                },
              })
            : _vm._e(),
          _c("p", {
            staticClass:
              "mypage-ec-site-connect-button-parts__description-paragraph",
            domProps: {
              textContent: _vm._s(
                _vm.$tc(
                  "MypagePage.MypageEcSiteConnectPage.connect.description.paragraph"
                )
              ),
            },
          }),
          _c(
            "ul",
            { staticClass: "mypage-ec-site-connect-button-parts__list" },
            [
              _c(
                "li",
                { staticClass: "mypage-ec-site-connect-button-parts__item" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "MypagePage.MypageEcSiteConnectPage.connect.description.item1"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "li",
                { staticClass: "mypage-ec-site-connect-button-parts__item" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$tc(
                          "MypagePage.MypageEcSiteConnectPage.connect.description.item2"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("atom-button", {
        staticClass: "mypage-ec-site-connect-button-parts__button",
        class:
          "mypage-ec-site-connect-button-parts__button--count" +
          _vm.state.count,
        attrs: {
          linkText: _vm.connectButtonLabel,
          disabled: _vm.ecConnectStatus === "connecting",
        },
        on: { onClick: _vm.handleConnectEc },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }