import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import I18n from '@/locales/I18n'

/**
 * レース種別の型
 */
export const RaceType = {
  /**
   * 決勝
   */
  RACE: 'RACE',
  /**
   * 予選
   */
  QUALIFYING: 'QUALIFYING',
  /**
   * フリー走行
   */
  FREE_PRACTICE: 'FREE_PRACTICE',
} as const

/**
 * 予選名の型
 */
type QualifyingLabel = {
  name: string
}

/**
 * 公開範囲設定の型
 */
type Publishing = {
  paidUser: boolean
  freeUser: boolean
}

/**
 * 無線の再生時間を遅らせる調整時間の型
 */
type RadioDelayAdjustTimeType = {
  movie: number
  audio: number
}

/**
 * レース情報を表現するクラス。
 */
export default class RaceDocument extends DocumentWrapper {
  constructor(initProps?: Partial<RaceDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'data/record/game_match'

  idAttr = 'matchId'

  /**
   * レース情報の追加項目
   */
  additionalData?: {
    /**
     * レース種別
     */
    raceType: keyof typeof RaceType
    /**
     * 予選名称リスト
     */
    qualifyingLabels: Array<QualifyingLabel>
    /**
     * 公開範囲設定
     */
    publishing?: Publishing
    /**
     * レースタイトル（多言語化対応）
     */
    title?: Multilingual
    /**
     * 予選名称リスト（多言語化対応）
     */
    multilingualQualifyingLabels?: Record<keyof Multilingual, Array<QualifyingLabel>>
    /**
     * 予選開始日時リスト
     */
    qualifyingStartDateList?: Array<number>
    /**
     * 無線の再生時間を遅らせる調整時間
     */
    radioDelayAdjustTime?: RadioDelayAdjustTimeType
    /**
     * ピットウインドウの制約なしかどうか
     */
    noPitWindow?: boolean
  }

  /**
   * レースタイトル
   */
  title = ''

  /**
   * 大会マスタID
   */
  championshipMasterId: string | null = null

  /**
   * レース日
   */
  scheduleDate?: number | null = null

  /**
   * レース開始日時。
   *
   * 実際のレースの開始日時が指定される。
   * 無線交信データは、startDateとendDateの範囲で登録されたデータのみ、SFgoに表示される。
   */
  startDate?: number | null = null

  /**
   * レース終了日時
   */
  endDate?: number | null = null

  // 以下はフロントでセットする項目
  /**
   * ハイライト情報。
   * このレースを表示した際に、最初にハイライト再生する場合に利用される。
   */
  highlightPlayInfo: {
    /** ハイライト情報ID */
    highlightId: string
    /** ハイライトの動画の再生開始位置 */
    movieStartTime: number
  } | null = null

  /**
   * スコアリングシステムで登録する予選名称の順番。
   * スコアリングシステム予選名称入力欄の上から順に数字をセットする。
   */
  qualifyingLabelOrder: number | null = null

  /**
   * レース種別
   */
  get raceType(): keyof typeof RaceType | undefined {
    return this.additionalData?.raceType
  }

  /**
   * レースタイトル
   */
  get raceTitle() {
    if (this.raceType === 'QUALIFYING') {
      // 予選の場合はqualifyingLabelsをtitleに設定する処理を行っている
      return this.title
    }

    if (this.additionalData?.title?.[I18n.locale]) {
      // レース選択画面の改善で多言語化対応を行ったため、多言語化対応されているものはユーザーの言語設定の応じたタイトルを返す
      return this.additionalData.title[I18n.locale]
    }
    return this.title
  }

  /**
   * このレースに登録されている予選の名称一覧
   */
  get qualifyingLabels() {
    if (this.additionalData?.multilingualQualifyingLabels?.[I18n.locale]) {
      // レース選択画面の改善で多言語化対応を行ったため、多言語化対応されているものはユーザーの言語設定の応じた名称を返す
      return this.additionalData.multilingualQualifyingLabels[I18n.locale]
    }
    return this.additionalData?.qualifyingLabels
  }

  /**
   * セクションID。
   * 予選の場合、そのスタート地点のハイライトのIDを返す。
   * 決勝の場合、レースIDを返す。
   */
  get sectionId() {
    return this.highlightPlayInfo?.highlightId ?? this.id
  }

  /**
   * 無線の再生時間を遅らせる時間（動画再生中）
   */
  get radioDelayAdjustTimeForMovie(): number {
    return this.additionalData?.radioDelayAdjustTime?.movie ?? 0
  }

  /**
   * 無線の再生時間を遅らせる時間（サーキットモード中）
   */
  get radioDelayAdjustTimeForAudio(): number {
    return this.additionalData?.radioDelayAdjustTime?.audio ?? 0
  }

  /**
   * ピットウインドウの制約なしかどうか
   */
  get noPitWindow(): boolean {
    return this.additionalData?.noPitWindow ?? false
  }
}
