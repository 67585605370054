import { computed, reactive, ref, watch } from '@vue/composition-api'
import { cloneDeep, uniqBy } from 'lodash'
import { StoreBase, ValueType } from '@/store/StoreBase'
import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import useUser from '@/store/hook/useUser'
import usePlayer from '@/store/hook/usePlayer'
import useTeam from '@/store/hook/useTeam'
import I18n from '@/locales/I18n'
import useOrganization from '@/store/hook/useOrganization'
import useContractPlan from '@/store/hook/useContractPlan'
import { PlanGroupType } from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useUserPoint from '@/store/hook/useUserPoint'
import Logger from '@/util/logger/Logger'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import UserPointDocument from '@/store/stores/collectionModule/documents/user/UserPointDocument'
import { Response } from '@/store/stores/collectionModule/CollectionTypes'
import useInAppPurchaseHistory from '@/store/hook/history/useInAppPurchaseHistory'
import useContractInfoBillingSettleMonthly from '@/store/hook/useContractInfoBillingSettleMonthly'
/**
 * 会員情報登録用の型
 */
export type EditUserType = Pick<
  UserDocument,
  | 'firstName'
  | 'familyName'
  | 'firstNameKana'
  | 'familyNameKana'
  | 'userDisplayName'
  | 'birthDay'
  | 'gender'
  | 'country'
  | 'prefecture'
  | 'zipcode'
>

/**
 * ECサイト連携状態の型
 */
export type EcConnectStatusType =
  | 'notConnected'
  | 'connected'
  | 'connectedGuestOnly'
  | 'connecting'
  | 'connectError'

/* eslint-disable class-methods-use-this */
/**
 * SFgoのマイページ画面のStore
 */
class MypagePageStore implements StoreBase {
  createStore() {
    const mypagePageStore = reactive({
      /**
       * マイページのデータを表示する会員ID
       */
      loginId: '',
      /**
       * 選択お気に入り選手Id
       */
      favoritePlayerId: LocalStorageAccessor.favoritePlayerId,
      /**
       * 選択お気に入りチームId
       */
      favoriteTeamId: LocalStorageAccessor.favoriteTeamId,
      /**
       * マイページで入力する会員情報
       */
      editUserInfo: {
        firstName: '',
        familyName: '',
        firstNameKana: '',
        familyNameKana: '',
        userDisplayName: '',
        birthDay: '',
        gender: '',
        country: '',
        prefecture: '',
        zipcode: '',
      } as EditUserType,
      /**
       * 選択中お気に入り選手
       * 初回ログイン時の会員情報入力で、お気に入り選手登録前に選択状態を表現するために使用する
       */
      savingFavoritePlayerId: '',
      /**
       * 選択中お気に入りチーム
       * 初回ログイン時の会員情報入力で、お気に入りチーム登録前に選択状態を表現するために使用する
       */
      savingFavoriteTeamId: '',
      /**
       * 選択中のプラン
       */
      selectedPlanGroupId: '' as PlanGroupType,
      /**
       * 保有ポイント
       * デフォルトで0がセットされているとランクがsilverやgoldなどの場合にbronze画像がちらついてしまうため、ちらつきを回避するためにデフォルトnullで保有ポイントを表現する
       */
      currentOwnedPoints: null as number | null,
      /**
       * アプリ内課金購入処理実行中かどうか
       */
      isPurchasingInAppPurchase: false,
      /**
       * アプリ内課金 BEに連携するサブスクリプション購入時のUUID
       * AppStoreの場合: トランザクションID
       * GooglePlayの場合: トークン
       */
      purchaseSubscriptionUuid: '',
      /**
       * アプリ内課金のトランザクション
       */
      inAppPurchaseTransaction: null as CdvPurchase.Transaction | null,
    })

    // hook
    const {
      fetchUser,
      confirmUser,
      confirmUserOneTimePassInfo,
      saveUser,
      user,
      clearUsers,
      saveLanguage,
      saveFavoritePlayer,
      saveFavoriteTeam,
      saveRegistrationTypeInProgress,
    } = useUser()
    const {
      currentOwnedPoints: originalCurrentOwnedPoints,
      fetchEffectiveUserPoints,
      fetchPreviousSeasonExpirationUserPoints,
      userPointsWithExpirationByExpirationDate,
      expirationUserPoints,
      annualPaidMemberMissionPoint,
      monthlyPaidMemberMissionData,
      clearUserPoints,
    } = useUserPoint()
    const { fetchPlayers, players, clearPlayers } = usePlayer()
    const { fetchTeams, teams, clearTeams } = useTeam()
    const { fetchOrganizations, pollingOrganizations, updateOrganization, ownOrganization } =
      useOrganization()
    const { fetchContractInfo, ownContractInfo, updateContractInfo, clearContractInfo } =
      useContractInfo()
    const {
      fetchContractPlans,
      contractPlans,
      getTargetContractPlan,
      contractPaidPlans,
      contractFreePlan,
      monthlyPlanPriceJpy,
      annualPlanPriceJpy,
      clearContractPlans,
    } = useContractPlan()
    const {
      fetchContractInfoPlans,
      contractInfoPlans,
      futureContactInfoPlan,
      clearContractInfoPlans,
    } = useContractInfoPlan()

    const { fetchInAppPurchaseHistory, inAppPurchaseHistory, clearInAppPurchaseHistory } =
      useInAppPurchaseHistory()
    const { immediatelyBillingSettleMonthly } = useContractInfoBillingSettleMonthly()

    /**
     * 初回ログイン時に入力する会員情報
     * return { UserDocument } 会員情報
     */
    const editUserInfo = computed({
      get: (): EditUserType => mypagePageStore.editUserInfo,
      set: (editUser: EditUserType) => {
        mypagePageStore.editUserInfo = editUser
      },
    })

    /**
     * 選手情報から同じ選手IDをもつ重複データを取り除く
     */
    const availablePlayers = computed(() =>
      uniqBy(players.value, 'playerId').filter((player) => player.position !== 'FW'),
    )
    /**
     * 選手情報から同じ選手IDをもつ重複データを取り除く
     * 先頭に「あとでお気に入り選手を選択する」を追加
     */
    const addedSelectLaterAvailablePlayers = computed(() => {
      const newPlayer = cloneDeep(availablePlayers)
      // 配列の先頭に「あとでお気に入り選手を選択する」を追加
      newPlayer.value.unshift(
        new PlayerDocument({
          playerId: 'selectFavoritePlayerLater',
          playerName: {
            [I18n.locale]: I18n.tc('common.selectFavoritePlayerLater'),
          },
          playerShortName: {
            [I18n.locale]: I18n.tc('common.selectFavoritePlayerLater'),
          },
        }),
      )

      return newPlayer.value
    })

    /**
     * ユーザー情報fetch処理時に登録されているニックネーム
     */
    const existDisplayName = ref('')

    /**
     * 選択中の契約プラングループID
     * return { ContractPlanDocument } 契約プラン情報
     */
    const selectedPlanGroupId = computed({
      get: (): PlanGroupType => mypagePageStore.selectedPlanGroupId,
      set: (planGroupId: PlanGroupType) => {
        mypagePageStore.selectedPlanGroupId = planGroupId
      },
    })

    /**
     * 保有ポイント
     * return { currentOwnedPoints } 保有ポイント
     */
    const currentOwnedPoints = computed({
      get: (): number | null => mypagePageStore.currentOwnedPoints,
      set: (points: number | null) => {
        mypagePageStore.currentOwnedPoints = points
      },
    })

    /**
     * アプリ内課金購入処理実行中かどうか
     */
    const isPurchasingInAppPurchase = computed({
      get: (): boolean => mypagePageStore.isPurchasingInAppPurchase,
      set: (isPurchasing: boolean) => {
        mypagePageStore.isPurchasingInAppPurchase = isPurchasing
      },
    })

    /**
     * アプリ内課金 BEに連携するサブスクリプション購入時のUUID
     * AppStoreの場合: トランザクションID
     * GooglePlayの場合: トークン
     */
    const purchaseSubscriptionUuid = computed({
      get: (): string => mypagePageStore.purchaseSubscriptionUuid,
      set: (id: string) => {
        mypagePageStore.purchaseSubscriptionUuid = id
      },
    })

    /**
     * アプリ内課金のトランザクション
     */
    const inAppPurchaseTransaction = computed({
      get: (): CdvPurchase.Transaction | null => mypagePageStore.inAppPurchaseTransaction,
      set: (transaction: CdvPurchase.Transaction | null) => {
        mypagePageStore.inAppPurchaseTransaction = transaction
      },
    })

    /**
     * 選択中の契約プラン情報
     * return { ContractPlanDocument } 契約プラン情報
     */
    const getSelectedContractPlan = computed(() =>
      contractPaidPlans.value.find(
        (contractPlan) => contractPlan.planGroupId === mypagePageStore.selectedPlanGroupId,
      ),
    )

    /**
     * 初回ログイン時に入力する会員情報 選択中お気に入り選手
     * return { string } 選択中お気に入り選手
     */
    const savingFavoritePlayer = computed(
      () =>
        <PlayerDocument>(
          addedSelectLaterAvailablePlayers.value.find(
            (item) => item.sid === mypagePageStore.savingFavoritePlayerId,
          )
        ),
    )
    const setSavingFavoritePlayerId = (favoritePlayerId: string) => {
      mypagePageStore.savingFavoritePlayerId = favoritePlayerId
    }
    /**
     * 選択中のお気に入り選手ID
     * return { PlayerDocument } 選択中のお気に入り選手情報
     */
    const favoritePlayer = computed({
      get: (): PlayerDocument =>
        <PlayerDocument>players.value.find((item) => item.sid === user.value.favoritePlayerId),
      set: (player: PlayerDocument) => {
        mypagePageStore.favoritePlayerId = player.sid || ''
      },
    })

    /**
     * チーム情報から同じチームIDをもつ重複データを取り除く
     */
    const availableTeams = computed(() => uniqBy(teams.value, 'teamId'))
    /**
     * 選手情報から同じ選手IDをもつ重複データを取り除く
     * 先頭に「あとでお気に入りチームを選択する」を追加
     */
    const addedSelectLaterAvailableTeams = computed(() => {
      const newTeams = cloneDeep(availableTeams)

      // 配列の先頭に「あとでお気に入りチームを選択する」を追加
      newTeams.value.unshift(
        new TeamDocument({
          teamId: 'selectFavoriteTeamLater',
          teamName: {
            [I18n.locale]: I18n.tc('common.selectFavoriteTeamLater'),
          },
          teamShortName: {
            [I18n.locale]: I18n.tc('common.selectFavoriteTeamLater'),
          },
        }),
      )

      return newTeams.value
    })
    /**
     * 初回ログイン時に入力する会員情報 選択中お気に入りチーム
     * return { string } 選択中お気に入りチーム
     */
    const savingFavoriteTeam = computed(
      () =>
        <TeamDocument>(
          addedSelectLaterAvailableTeams.value.find(
            (item) => item.sid === mypagePageStore.savingFavoriteTeamId,
          )
        ),
    )
    const setSavingFavoriteTeamId = (favoriteTeamId: string) => {
      mypagePageStore.savingFavoriteTeamId = favoriteTeamId
    }
    /**
     * 選択中のお気に入りチームID
     * return { TeamDocument } 選択中のお気に入りチーム情報
     */
    const favoriteTeam = computed({
      get: (): TeamDocument =>
        <TeamDocument>teams.value.find((item) => item.sid === user.value.favoriteTeamId),
      set: (team: TeamDocument) => {
        mypagePageStore.favoriteTeamId = team.sid || ''
      },
    })

    // API requests
    /**
     * MypagePageで必要なデータを全て取得する。
     * @param loginId データを取得する会員ID
     * @param executionFetchUserPoints ユーザーポイント一覧のデータ取得を実施するかどうか
     * @return データ取得を待機するためのPromise
     */
    const fetchMypagePageData = async (loginId?: string, executionFetchUserPoints = false) => {
      if (loginId) {
        mypagePageStore.loginId = loginId
      }

      const promises: Array<
        Promise<Response<UserDocument | OrganizationDocument | UserPointDocument>>
      > = [fetchUser(mypagePageStore.loginId), fetchOrganizations()]

      if (executionFetchUserPoints) promises.push(fetchEffectiveUserPoints())

      const results = await Promise.all(promises)
      const failedResponse = results.flat().find((response) => !response.isSuccess)
      if (failedResponse) {
        return failedResponse
      }
      const _user = user.value
      existDisplayName.value = _user.userDisplayName ?? ''

      if (!_user || !_user.id) {
        return null
      }
      // 保有ポイントをセット
      mypagePageStore.currentOwnedPoints = originalCurrentOwnedPoints.value

      const resultsFavoritePlayer = await Promise.all([fetchPlayers()])
      const failedFavoritePlayerResponse = resultsFavoritePlayer.find(
        (response) => !response.isSuccess,
      )
      if (failedFavoritePlayerResponse) {
        return failedFavoritePlayerResponse
      }
      if (!favoritePlayer.value) {
        mypagePageStore.favoritePlayerId = _user.favoritePlayerId ? _user.favoritePlayerId : ''
      }
      const resultsFavoriteTeam = await Promise.all([fetchTeams()])
      const failedFavoriteTeamResponse = resultsFavoriteTeam.find((response) => !response.isSuccess)
      if (failedFavoriteTeamResponse) {
        return failedFavoriteTeamResponse
      }
      if (!favoriteTeam.value) {
        mypagePageStore.favoriteTeamId = _user.favoriteTeamId ? _user.favoriteTeamId : ''
      }
      return resultsFavoriteTeam[0]
    }

    /**
     * ECサイト連携状態を返す
     */
    const ecConnectStatus = computed(() => {
      let status = 'notConnected' as EcConnectStatusType
      if (ownOrganization.value?.ec?.linkRequest) {
        status = 'connecting'
      } else if (ownOrganization.value?.isLinkedToGuestOnly) {
        status = 'connectedGuestOnly'
      } else if (
        ownOrganization.value?.ec?.customerId &&
        !ownOrganization.value?.ecConnectBatchError
      ) {
        status = 'connected'
      } else if (ownOrganization.value?.ecConnectBatchError) {
        status = 'connectError'
        Logger.info(
          `MypagePageStore#ecConnectStatus connectError: ecErrorCodeList: ${ownOrganization.value?.ecConnectEcApiErrors?.join(
            ', ',
          )}`,
        )
      }

      return status
    })

    /**
     * ECサイト連携エラーかどうか
     */
    const isEcConnectError = computed(() => !!ownOrganization.value?.ecConnectBatchError)

    // methods
    /**
     * TOPページのデータをクリアする
     */
    const clearMypagePageData = () => {
      clearUsers()
      clearUserPoints()
      clearPlayers()
      clearTeams()
      clearInAppPurchaseHistory()
    }

    // watcher
    /**
     * チャンネル変更を監視し、変更時に発火しローカルストレージにチャンネルIDを保持する
     */
    watch(
      () => mypagePageStore.favoritePlayerId,
      (playerId) => {
        LocalStorageAccessor.favoritePlayerId = playerId
      },
    )
    watch(
      () => mypagePageStore.favoriteTeamId,
      (teamId) => {
        LocalStorageAccessor.favoriteTeamId = teamId
      },
    )

    return {
      fetchMypagePageData,
      ecConnectStatus,
      isEcConnectError,
      fetchUser,
      confirmUser,
      confirmUserOneTimePassInfo,
      saveUser,
      user,
      currentOwnedPoints,
      isPurchasingInAppPurchase,
      purchaseSubscriptionUuid,
      inAppPurchaseTransaction,
      existDisplayName,
      editUserInfo,
      savingFavoritePlayer,
      setSavingFavoritePlayerId,
      savingFavoriteTeam,
      setSavingFavoriteTeamId,
      fetchPlayers,
      players,
      availablePlayers,
      addedSelectLaterAvailablePlayers,
      favoritePlayer,
      fetchTeams,
      teams,
      availableTeams,
      addedSelectLaterAvailableTeams,
      favoriteTeam,
      clearMypagePageData,
      saveFavoritePlayer,
      saveFavoriteTeam,
      fetchOrganizations,
      pollingOrganizations,
      updateOrganization,
      ownOrganization,
      saveLanguage,
      saveRegistrationTypeInProgress,
      selectedPlanGroupId,
      getSelectedContractPlan,
      fetchContractInfo,
      ownContractInfo,
      userPointsWithExpirationByExpirationDate,
      updateContractInfo,
      clearContractInfo,
      fetchContractInfoPlans,
      contractInfoPlans,
      futureContactInfoPlan,
      clearContractInfoPlans,
      fetchContractPlans,
      contractPlans,
      getTargetContractPlan,
      contractPaidPlans,
      contractFreePlan,
      monthlyPlanPriceJpy,
      annualPlanPriceJpy,
      annualPaidMemberMissionPoint,
      monthlyPaidMemberMissionData,
      clearContractPlans,
      fetchInAppPurchaseHistory,
      inAppPurchaseHistory,
      immediatelyBillingSettleMonthly,
      fetchPreviousSeasonExpirationUserPoints,
      expirationUserPoints,
    }
  }
}

const value: ValueType<MypagePageStore> = {}

export default {
  createStore: new MypagePageStore().createStore,
  value: value as Required<typeof value>,
}
