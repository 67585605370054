import { computed } from '@vue/composition-api'
import { orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import VideoPlatformContentsDocument, {
  SelectRankRewordTypeValueType,
  UserSpecifiedRewordVideoType,
} from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import { MembersRankNameType } from '@/util/Const'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { Multilingual } from '@/store/stores/collectionModule/documents/GeneralTypes'
import useVideoPlatformContentsParams from '@/store/hook/videoPlatformContents/useVideoPlatformContentsParams'

// 特典画像表示用の型
export type RewardDataType = {
  // 動画コンテンツID
  videoContentsId: string
  // 動画種別
  videoType: SelectRankRewordTypeValueType | UserSpecifiedRewordVideoType
  // 動画識別子
  videoId: string
  // 配布日
  distributionDate: string
  // 配布日UNIXタイムスタンプ
  distributionDateUnixTime: number
  // タイトル
  title: Multilingual
  // 説明
  description: Multilingual
  // 画像パス
  imagePath: string
  // 表示期限日
  deadline: string
}

/**
 * 特典画像・動画を操作するための処理
 */
export default function useRewards() {
  const rankSpecifiedRewardsCollectionModule = CollectionModule.createStore(
    VideoPlatformContentsDocument,
  )
  const userSpecifiedRewardsCollectionModule = CollectionModule.createStore(
    VideoPlatformContentsDocument,
  )

  const { getDisplayDate } = useDisplayDependingOnLang()
  const { generateDisplayFilter } = useVideoPlatformContentsParams()

  /**
   * ランク指定特典画像・動画情報を取得するための、クエリを作成する
   */
  const generateRankSpecifiedFilter = (currentRank: MembersRankNameType | undefined) => {
    const targetRankFilterQueryArray: Array<SelectRankRewordTypeValueType> = []
    targetRankFilterQueryArray.push('rewardVideoToAll', 'rewardImageToAll')
    if (!currentRank || currentRank === 'free') return targetRankFilterQueryArray
    targetRankFilterQueryArray.push('rewardVideoAboveBronze', 'rewardImageAboveBronze')
    if (currentRank === 'bronze') return targetRankFilterQueryArray
    targetRankFilterQueryArray.push('rewardVideoAboveSilver', 'rewardImageAboveSilver')
    if (currentRank === 'silver') return targetRankFilterQueryArray
    targetRankFilterQueryArray.push('rewardVideoAboveGold', 'rewardImageAboveGold')
    return targetRankFilterQueryArray
  }

  /**
   * ランク指定特典画像・動画情報を取得
   */
  const fetchRankSpecifiedRewards = async (currentRank: MembersRankNameType | undefined) =>
    rankSpecifiedRewardsCollectionModule.fetch({
      query: {
        filter: {
          $and: [
            generateDisplayFilter(),
            {
              videoType: {
                $in: generateRankSpecifiedFilter(currentRank),
              },
            },
          ],
        },
        // 作成日時が新しい順にソートする
        sort: '-_createdDate',
      },
      isSaveInStore: true,
      isUnionExistData: true,
    })

  /**
   * ユーザー指定特典画像・動画情報を取得
   * 添付データIDと一致する特典情報を取得する
   */
  const fetchRewardContentsByAttachedDataId = async (attachedDataIds: string[]) =>
    userSpecifiedRewardsCollectionModule.fetchSplitFilterIn(
      {
        query: {
          filter: generateDisplayFilter(),
          // 作成日時が新しい順にソートする
          sort: '-_createdDate',
        },
        isSaveInStore: true,
        isUnionExistData: true,
      },
      1,
      100,
      'videoContentsId',
      attachedDataIds,
    )

  /**
   * ランク指定特典情報一覧
   */
  const rankSpecifiedRewards = computed(() => rankSpecifiedRewardsCollectionModule.data)

  /**
   * ユーザー指定特典情報一覧
   */
  const userSpecifiedRewards = computed(() => userSpecifiedRewardsCollectionModule.data)

  const createRewardDataForDisplay = (reward: VideoPlatformContentsDocument): RewardDataType => ({
    videoContentsId: reward.id ?? '',
    videoType: reward.videoType as SelectRankRewordTypeValueType | UserSpecifiedRewordVideoType,
    videoId: reward.videoId ?? '',
    distributionDate: getDisplayDate(reward.startDate),
    distributionDateUnixTime: reward.startDate ?? 0,
    title: reward.title ?? { ja: '', en: '' },
    description: reward.description ?? { ja: '', en: '' },
    imagePath: reward.videoThumbnailPath ?? '',
    deadline: reward.endDate ? getDisplayDate(reward.endDate) : '---',
  })

  /**
   * 特典画像・動画データ一覧
   */
  const rewardDataList = computed(() => {
    const rankSpecified = rankSpecifiedRewards.value.map((reward) =>
      createRewardDataForDisplay(reward),
    )

    const userSpecified = userSpecifiedRewards.value.map(
      (reward): RewardDataType => createRewardDataForDisplay(reward),
    )

    return orderBy([...rankSpecified, ...userSpecified], 'distributionDateUnixTime', 'desc')
  })

  /**
   * 取得した特典画像・動画情報をクリアする
   */
  const clearRewards = () => {
    rankSpecifiedRewardsCollectionModule.clearData()
    userSpecifiedRewardsCollectionModule.clearData()
  }

  return {
    fetchRewardContentsByAttachedDataId,
    fetchRankSpecifiedRewards,
    rewardDataList,
    clearRewards,
  }
}
