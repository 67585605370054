var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirmation-sent-section" },
    [
      _c("h3", { staticClass: "confirmation-sent-section__title" }, [
        _vm._v(
          " " + _vm._s(_vm.$tc("SignupPage.ConfirmationSentPage.title")) + " "
        ),
      ]),
      _c("div", {
        staticClass: "confirmation-sent-section__message",
        domProps: {
          innerHTML: _vm._s(_vm.$tc("SignupPage.ConfirmationSentPage.message")),
        },
      }),
      _c(
        "action-buttons-section",
        {
          staticClass: "confirmation-sent-section__action-buttons",
          attrs: {
            scrollTargetSelector: ".confirmation-sent-section",
            visible: true,
          },
        },
        [
          _c("atom-input-button", {
            attrs: {
              linkText: _vm.$tc("SignupPage.ConfirmationSentPage.reEnter"),
              isDisabled: false,
              colorType: "secondary",
            },
            on: { push: _vm.onMoveToEmailAuthentication },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }