var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mission-list-pane", on: { scroll: _vm.handlePaneScroll } },
    [
      _c("div", { staticClass: "mission-list-pane__container" }, [
        _c(
          "div",
          {
            ref: "header",
            staticClass: "mission-list-pane__header",
            class: { "is-fixed": _vm.isFixed },
          },
          [
            _c("mission-list-header-section", {
              attrs: {
                selectedMissionYear: _vm.selectedMissionYear,
                isFixed: _vm.isFixed,
                successfulCheckIn: _vm.successfulCheckIn,
                checkInMissionsBeingHeldForThisSeason:
                  _vm.checkInMissionsBeingHeldForThisSeason,
              },
              on: {
                onChangeMissionYear: _vm.fetchMissionData,
                onClickCheckIn: _vm.handleCheckInClicked,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "mission-list-pane__list",
            class: { "is-fixed": _vm.state.doneFetch },
            style: _vm.state.listStyle,
          },
          _vm._l(_vm.missionProgressCardData, function (mission, key) {
            return _c("mission-card-section", {
              key: key,
              staticClass: "mission-list-pane__item",
              attrs: {
                mission: mission,
                noSpace: mission.honeycombType === "check",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header-icon",
                    fn: function () {
                      return [
                        mission.headerIcon === "race-icon"
                          ? _c("mission-race-icon", {
                              staticClass: "mission-card-section__title-icon",
                            })
                          : _vm._e(),
                        mission.headerIcon === "member-icon"
                          ? _c("mission-member-icon", {
                              staticClass: "mission-card-section__title-icon",
                            })
                          : mission.headerIcon === "clip-icon"
                          ? _c("mission-clip-icon", {
                              staticClass: "mission-card-section__title-icon",
                            })
                          : mission.headerIcon === "radiophone-icon"
                          ? _c("mission-radiophone-icon", {
                              staticClass: "mission-card-section__title-icon",
                            })
                          : mission.headerIcon === "share-icon"
                          ? _c("mission-share-icon", {
                              staticClass: "mission-card-section__title-icon",
                              attrs: { width: 18, height: 18 },
                            })
                          : mission.headerIcon === "map-icon"
                          ? _c("mission-map-icon", {
                              staticClass: "mission-card-section__title-icon",
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function () {
                      return [
                        !mission.hasCompleted &&
                        mission.missionTerm &&
                        mission.missionTermTitle
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "mission-card-section__mission-term",
                              },
                              [
                                _c("mission-term-parts", {
                                  attrs: { mission: mission },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !mission.hasCompleted &&
                        mission.achievementCountDispType
                          ? _c(
                              "div",
                              { staticClass: "mission-card-section__process" },
                              [
                                _c("mission-process-parts", {
                                  attrs: { mission: mission },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        mission.achievementCondition && mission.honeycombType
                          ? [
                              mission.honeycombType === "circuit"
                                ? _c("mission-honey-comb-circuit-parts", {
                                    attrs: { mission: mission },
                                  })
                                : mission.achievementCondition.length === 7 ||
                                  mission.achievementCondition.length === 10
                                ? _c("mission-honeycomb-seven-ten-parts", {
                                    staticClass:
                                      "mission-card-section__honeycomb",
                                    class: {
                                      "is-completed": mission.hasCompleted,
                                    },
                                    attrs: {
                                      mission: mission,
                                      checkInMissionsBeingHeld:
                                        _vm.checkInMissionsBeingHeld,
                                    },
                                  })
                                : mission.achievementCondition.length === 9
                                ? _c("mission-honeycomb-nine-parts", {
                                    attrs: {
                                      mission: mission,
                                      checkInMissionsBeingHeld:
                                        _vm.checkInMissionsBeingHeld,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        mission.honeycombType === "check"
                          ? _c("mission-check-list-parts", {
                              attrs: { mission: mission },
                            })
                          : _vm._e(),
                        mission.earnedPoints
                          ? _c("mission-get-point-parts", {
                              staticClass: "mission-card-section__get-point",
                              class: { "is-completed": mission.hasCompleted },
                              attrs: { mission: mission },
                            })
                          : _vm._e(),
                        mission.missionCode === "INVITED_USING_REFERRAL_CODE" &&
                        !mission.hasCompleted
                          ? _c("mission-referral-code-parts")
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  mission.achievementMethod
                    ? {
                        key: "howto",
                        fn: function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass: "mission-card-section__how-title",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(mission.achievementMethod.title) +
                                    " "
                                ),
                              ]
                            ),
                            _c("p", {
                              staticClass:
                                "mission-card-section__how-description",
                              domProps: {
                                innerHTML: _vm._s(
                                  mission.achievementMethod.text.replace(
                                    /\n/g,
                                    "<br/>"
                                  )
                                ),
                              },
                            }),
                            mission.missionGroupCode === "CHECKED_IN_BONUS"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mission-card-section__check-point",
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "mission-card-section__check-point-title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$tc(
                                                "MissionPage.checkPointList.title"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      Object.entries(_vm.checkPointListByArea),
                                      function (ref) {
                                        var area = ref[0]
                                        var checkinPoint = ref[1]
                                        return _c("div", { key: area }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "mission-card-section__check-point-area",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MissionPage.checkPointList.area",
                                                      { area: area }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "mission-card-section__check-point-list",
                                            },
                                            _vm._l(
                                              checkinPoint,
                                              function (checkPoint) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: checkPoint.stadiumId,
                                                    staticClass:
                                                      "mission-card-section__check-point-item",
                                                  },
                                                  [
                                                    _c(
                                                      "mission-check-in-point-section",
                                                      {
                                                        attrs: {
                                                          checkPoint:
                                                            checkPoint,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            mission.achievementMethod.infoLink
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mission-card-section__how-info",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "mission-card-section__how-info-link",
                                        attrs: {
                                          href: mission.achievementMethod
                                            .infoLink,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tc("MissionPage.howToDetail")
                                            )
                                          ),
                                        ]),
                                        _c("mission-external-link-icon"),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            mission.achievementMethod.note
                              ? _c("p", {
                                  staticClass: "mission-card-section__how-note",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      mission.achievementMethod.note
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }),
          1
        ),
      ]),
      _c("div", { staticClass: "mission-list-pane__background" }, [
        _c("img", {
          staticClass: "mission-list-pane__background-image",
          attrs: { src: _vm.bgImagePath, alt: "" },
        }),
      ]),
      _vm.isShowCheckInFailedDetailModal
        ? _c("check-in-failed-modal-section", {
            on: { close: _vm.hideCheckInFailedDetailModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }