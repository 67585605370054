var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-point-expiration-date-pane" }, [
    _c("div", { staticClass: "mypage-point-expiration-date-pane__container" }, [
      _c("p", { staticClass: "mypage-point-expiration-date-pane__message" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$tc("MypagePage.MypagePointExpirationDatePage.description")
            ) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "mypage-point-expiration-date-pane__table" },
        [_c("mypage-point-expiration-date-table-section")],
        1
      ),
      _c("ul", { staticClass: "mypage-point-expiration-date-pane__buttons" }, [
        _c(
          "li",
          { staticClass: "mypage-point-expiration-date-pane__button" },
          [
            _c("atom-router-button", {
              attrs: {
                to: "/mypage/point-history",
                linkText: _vm.$tc(
                  "MypagePage.MypagePointExpirationDatePage.buttonLabel.history"
                ),
                theme: "light",
                colorType: "secondary",
              },
            }),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "mypage-point-expiration-date-pane__button" },
          [
            _c("atom-icon-link-button", {
              attrs: {
                url: _vm.pointDescriptionPageUrl,
                linkText: _vm.$tc(
                  "MypagePage.MypagePointExpirationDatePage.buttonLabel.detail"
                ),
                theme: "light",
                colorType: "tertiary",
                imgPath: require("@/assets/img/icon/icon_external_link_gray75.svg"),
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }