























import { defineComponent, PropType } from '@vue/composition-api'
import Swiper, { Navigation, Pagination } from 'swiper'
import ImgSlideParts from '@/components/HomePage/HomePane/parts/ImgSlideParts.vue'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

/**
 * ヘッドラインのスライド画像の型定義
 */
export type ImgSliderLinkData = {
  /**
   * videoContentsId
   */
  videoContentsId: string
  /**
   * ヘッドラインカード選択時の画面遷移の方法。
   * SFgoの画面へ遷移する場合: router
   * SUPERFORMULA公式サイト、別サイトに遷移する場合: link
   */
  linkType: 'router' | 'link'
  /**
   * 遷移先のURL。または、パス
   * linkTypeがrouterの場合、パスが設定される。
   * 外部サイトの場合、URLが設定される。
   */
  link: string
  /**
   * ヘッドラインのサムネイル画像のURL
   */
  img: string
  /**
   * ヘッドラインに表示するテキスト
   */
  text: string
}

/**
 * HOME イメージスライダーセクション
 */
export default defineComponent({
  name: 'ImgSliderSection',
  components: {
    ImgSlideParts,
  },
  data(): {
    swiper: Swiper | null
  } {
    return {
      swiper: null,
    }
  },
  props: {
    imgSlideLinks: {
      type: Array as PropType<ImgSliderLinkData[]>,
      required: true,
    },
  },
  mounted() {
    // eslint-disable-next-line
    this.swiper = new Swiper(this.$refs.swiper as HTMLElement, {
      modules: [Navigation, Pagination],
      loop: true,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
    })
  },
  watch: {
    imgSlideLinks(newVal: ImgSliderLinkData[], oldVal: ImgSliderLinkData[]) {
      const newValIds = newVal.map((val) => val.videoContentsId)
      const oldValIds = oldVal.map((val) => val.videoContentsId)
      if (newValIds !== oldValIds) {
        this.$nextTick(() => {
          if (this.swiper) {
            this.swiper.update()
            this.swiper.loopDestroy()
            this.swiper.loopCreate()
          }
        })
      }
    },
  },
})
