import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'

export type RegistMethodType = 'MAIL_ADDRESS' | 'SNS_LINE' | 'GOOGLE' | 'APPLE'

/**
 * ユーザー登録トークン情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1135870048/07+API
 */
export default class UserRegisterTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserRegisterTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
    // CouponDocumentのgetterを使えるようにするために、CouponDocumentのインスタンスを初期化する
    if (initProps?.couponInfo) {
      this.couponInfo = new CouponDocument(initProps?.couponInfo)
    }
  }

  _path = 'manage/regist_token'

  idAttr = 'tokenId'

  /**
   * 登録トークンID
   */
  tokenId: string | null = null

  /**
   * reCAPTCHAのトークン
   */
  reCaptchaToken?: string | null = null

  /**
   * 組織名
   */
  organizationName = ''

  /**
   * 組織種別
   */
  organizationType = 'SFGO'

  /**
   * 言語
   */
  lang: UserLang | null = null

  /**
   * 登録方法
   */
  registMethod: RegistMethodType | null = null

  /**
   * メールアドレス
   */
  mailAddress: string | null = null

  /**
   * トークン有効期限
   */
  tokenExpirationDate?: number | null = null

  /**
   * クーポン情報
   */
  couponInfo?: CouponDocument | null = null

  /**
   * 会員登録情報の追加項目
   */
  additionalData?: {
    /**
     * 会員登録タイプ
     */
    memberType: MemberType
  }
}
