import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import GoogleUserInfoDocument from '@/store/stores/collectionModule/documents/googleUserInfo/GoogleUserInfoDocument'

/**
 * Googleの認可をしたユーザーの情報を取得する処理
 */
export default function useGoogleUserInfo() {
  // Collection modules
  const googleUserInfoCollectionModule = CollectionModule.createStore(GoogleUserInfoDocument)

  /**
   * 認可をしたユーザーの情報を取得する。
   * @param code 認可後のリダイレクトされたURLに付与されるパラメータ
   * @param redirectUrl 認可時に指定したリダイレクトURL
   * @param codeVerifier 認可時に指定したcode_challenge
   */
  const fetchGoogleUserInfo = (code: string, redirectUrl: string, codeVerifier: string) =>
    googleUserInfoCollectionModule.fetch({
      url: `${process.env.VUE_APP_SFGO_API_BASE_URL}/googleUserInfo`,
      query: { additionalQuery: { code, redirectUrl, codeVerifier } },
    })

  /**
   * 認可をしたユーザーの情報を返す。
   */
  const googleUserInfo = computed(() => googleUserInfoCollectionModule.data[0])

  /**
   * 取得した情報をクリアする。
   */
  const clearGoogleUserInfo = () => {
    googleUserInfoCollectionModule.clearData()
  }

  return {
    fetchGoogleUserInfo,
    googleUserInfo,
    clearGoogleUserInfo,
  }
}
