import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * Googleの認可をしたユーザーの情報を取得するためのクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SFGO/pages/4097114430/03.+Google
 */
export default class GoogleUserInfoDocument extends DocumentWrapper {
  constructor(initProps?: Partial<GoogleUserInfoDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = 'googleUserInfo'

  _isSfgoApi = true

  /**
   * googleの一意のID
   */
  googleId: string | null = null

  /**
   * メールアドレス
   */
  email: string | null = null

  /**
   * 名前（姓）
   */
  familyName: string | null = null

  /**
   * 名前（名）
   */
  givenName: string | null = null
}
