var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-one-time-pass-page" },
    [
      _c("header-pane", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.pageTitle"),
          colorType: "light",
        },
      }),
      _c(
        "div",
        { staticClass: "app-signup-one-time-pass-page__main" },
        [
          _c("app-signup-one-time-pass-pane", {
            on: { handlerSubmit: _vm.moveToAppSignupOneTimePassConfirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }