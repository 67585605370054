import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'

/**
 * 受信データの種類
 */
type ReceivedDataType = 'REWARD'

/**
 * ユーザー指定の受信データ情報を表現するクラス。
 * @see https://docs.google.com/spreadsheets/d/178Ks6odFZPMxDJBPJsix_1Xl6ACP5MQFH2Pf0u7Fl3k/edit?gid=97973075#gid=97973075
 */
export default class ReceivedDataDocument extends DocumentWrapper {
  constructor(initProps?: Partial<ReceivedDataDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  _path = ''

  // ユニークに識別できるIDを設定
  idAttr = 'attachedDataId'

  /**
   * 種別
   */
  type: ReceivedDataType | null = null

  /**
   * 受信したユーザーID
   */
  userId: string | null = null

  /**
   * 添付データを識別するID
   */
  attachedDataId: string | null = null

  /**
   * 作成日時
   */
  createdDate: number | null = null
}
