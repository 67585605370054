var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-info-prompt-modal-section" },
    [
      _c("common-modal-section", { on: { close: _vm.handleClicked } }, [
        _c(
          "div",
          {
            staticClass: "user-info-prompt-modal-section__header",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c("p", { staticClass: "user-info-prompt-modal-section__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$tc("HomePage.UserInfoPromptModal.title")) +
                  " "
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "user-info-prompt-modal-section__body" }, [
          _c("p", { staticClass: "user-info-prompt-modal-section__message" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$tc("HomePage.UserInfoPromptModal.message")) +
                " "
            ),
          ]),
          _c(
            "ul",
            { staticClass: "user-info-prompt-modal-section__note" },
            _vm._l(
              _vm.$t("HomePage.UserInfoPromptModal.note"),
              function (note, index) {
                return _c("li", {
                  key: index,
                  domProps: { innerHTML: _vm._s(note) },
                })
              }
            ),
            0
          ),
          _c("p", { staticClass: "user-info-prompt-modal-section__mission" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$tc("HomePage.UserInfoPromptModal.mission")) +
                " "
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "user-info-prompt-modal-section__buttons",
            attrs: { slot: "buttons" },
            slot: "buttons",
          },
          [
            _c(
              "ul",
              { staticClass: "user-info-prompt-modal-section__buttons__list" },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "user-info-prompt-modal-section__buttons__item",
                  },
                  [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleClicked("/mypage/user-edit")
                          },
                        },
                      },
                      [
                        _c("atom-router-button", {
                          attrs: {
                            to: "",
                            linkText: _vm.$tc(
                              "HomePage.UserInfoPromptModal.buttonLabel"
                            ),
                            colorType: "secondary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "user-info-prompt-modal-section__buttons__item",
                  },
                  [
                    _c(
                      "p",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleClicked("/mission")
                          },
                        },
                      },
                      [
                        _c("atom-router-button", {
                          attrs: {
                            to: "",
                            linkText: _vm.$tc(
                              "HomePage.UserInfoPromptModal.missionButtonLabel"
                            ),
                            colorType: "tertiary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "user-info-prompt-modal-section__buttons__item",
                  },
                  [
                    _c("atom-button", {
                      attrs: {
                        linkText: _vm.$tc("common.close"),
                        colorType: "tertiary",
                      },
                      on: {
                        onClick: function ($event) {
                          return _vm.handleClicked("")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }