import AppConfigStore from '@/store/stores/pageStore/common/AppConfigStore'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import LoadingOverlayProgressStore from '@/store/stores/pageStore/common/LoadingOverlayProgressStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import HomePageStore from '@/store/stores/pageStore/HomePage/HomePageStore'
import TopPageStore from '@/store/stores/pageStore/TopPage/TopPageStore'
import SignupPageStore from '@/store/stores/pageStore/SignupPage/SignupPageStore'
import MypagePageStore from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import OneTimePassPageStore from '@/store/stores/pageStore/MypagePage/OneTimePassPageStore'
import MypageTicketPageStore from '@/store/stores/pageStore/MypagePage/MypageTicketPageStore'
import RaceVideoPageStore from '@/store/stores/pageStore/RaceVideoPage/RaceVideoPageStore'
import StandingsPageStore from '@/store/stores/pageStore/StandingsPage/StandingsPageStore'
import MissionPageStore from '@/store/stores/pageStore/MissionPage/MissionPageStore'
import CalendarPageStore from '@/store/stores/pageStore/LinkPage/CalendarPageStore'
import DynamoDBCredentialStore from '@/store/stores/loginStore/DynamoDBCredentialStore'
import UserStore from '@/store/stores/pageStore/common/UserStore'
import NotificationStore from '@/store/stores/pageStore/common/NotificationStore'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import ErrorStore from '@/store/stores/pageStore/common/ErrorStore'
import IndexedDBStore from '@/store/stores/IndexedDBstore/IndexedDBStore'

/**
 * このプロジェクトで利用するStoreのMapリスト
 * この記載順で初期化される為、DynamoDBを使うstoreはDynamoDBCredentialStoreより下に定義する必要がある
 */
export const StoreIndex = {
  AppConfigStore,
  LoginStore,
  NotificationStore,
  ContractInfoStore,
  DynamoDBCredentialStore,
  UserStore,
  SignupPageStore,
  TopPageStore,
  MessageDialogStore,
  LoadingOverlayProgressStore,
  MypagePageStore,
  OneTimePassPageStore,
  MypageTicketPageStore,
  RaceVideoPageStore,
  StandingsPageStore,
  MissionPageStore,
  CalendarPageStore,
  HomePageStore,
  ErrorStore,
  IndexedDBStore,
}

export type StoresType = typeof StoreIndex

/**
 * StoreIndexからStoreの実体のみを抽出したMapを返す
 */
const GeneratedStores = (): Partial<{ [K in keyof StoresType]: StoresType[K]['value'] }> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [key: string]: any } = {}
  Object.entries(StoreIndex).forEach(([key, val]) => {
    result[key] = val.value
  })
  return result as Partial<{ [K in keyof StoresType]: StoresType[K]['value'] }>
}

export default GeneratedStores
