
































































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import FieldsetPasswordParts from '@/components/common/form/FieldsetPasswordParts.vue'
import FieldsetCheckboxParts from '@/components/common/form/FieldsetCheckboxParts.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FieldsetNameParts from '@/components/common/form/FieldsetNameParts.vue'
import FieldsetCountryParts from '@/components/common/form/FieldsetCountryParts.vue'
import FieldsetBirthdayParts from '@/components/common/form/FieldsetBirthdayParts.vue'
import type { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import type { OpenBirthdayMenuIdType } from '@/components/common/form/FieldsetBirthdayParts.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'

/**
 * 会員登録で必要な入力項目
 */
type RegistrationInputDataType = {
  email: string
  password: string
  agreement: boolean
}

/**
 * 会員情報入力項目のプロパティ
 */
type UserInputPropertyType = 'familyName' | 'firstName' | 'birthDay' | 'country'

/**
 * アプリ新規会員登録 会員情報入力セクション
 */
export default defineComponent({
  name: 'SignupFormSection',
  components: {
    FormErrorMessageParts,
    FieldsetBirthdayParts,
    FieldsetCountryParts,
    FieldsetNameParts,
    FieldsetEmailParts,
    FieldsetPasswordParts,
    FieldsetCheckboxParts,
    ActionButtonsSection,
    AtomInputButton,
  },
  props: {
    /**
     * 無料会員登録処理実行中かどうか
     */
    isRegistering: {
      type: Boolean,
      required: true,
    },
    /**
     * 認証メール送信画面で入力したメールアドレス
     */
    email: {
      type: String,
      required: true,
    },
    /**
     * 入力対象の会員情報
     */
    userInfo: {
      type: Object as PropType<EditUserType>,
      required: true,
    },
    /**
     * 年・月・日のうちどれを変更するか
     */
    openBirthdayMenuId: {
      type: String as PropType<OpenBirthdayMenuIdType>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const inputData = ref<RegistrationInputDataType>({
      email: '',
      password: '',
      agreement: false,
    })

    /** 以下の場合は「登録する」ボタンを押せない
     * 1. 規約未同意
     * 2. 無料会員登録処理実行中
     */
    const submitDisabled = computed(() => !inputData.value.agreement || props.isRegistering)

    const onAgreementChecked = (agreementValue: boolean) => {
      inputData.value.agreement = agreementValue
    }

    const handleBirthdayClicked = (menuId: string) => {
      emit('onClickBirthday', menuId)
    }

    watch(
      () => props.email,
      () => {
        inputData.value.email = props.email
      },
    )

    return {
      // SignupFormSection
      inputData,
      submitDisabled,
      onAgreementChecked,
      handleBirthdayClicked,
      // 入力する会員情報
      firstName: props.userInfo.firstName ?? '',
      familyName: props.userInfo.familyName ?? '',
      birthDay: props.userInfo.birthDay,
      country: props.userInfo.country || 'JP',
    }
  },
  methods: {
    /**
     * 入力対象の会員情報
     */
    editUserData(): EditUserType {
      return {
        firstName: this.firstName,
        familyName: this.familyName,
        firstNameKana: '',
        familyNameKana: '',
        userDisplayName: '',
        birthDay: this.birthDay,
        gender: '',
        country: this.country,
        prefecture: '',
        zipcode: '',
      }
    },
    /**
     * 入力対象が変更された場合
     */
    inputValue(value: string, property: UserInputPropertyType) {
      this[property] = value
      this.onSetEditUserInfo()
    },
    onSetEditUserInfo() {
      /**
       * 入力対象が変更されたことを通知する
       * @event onSetEditUserInfo
       */
      this.$emit('onChangeEditUserInfo', this.editUserData())
    },
    onFormSubmit() {
      /**
       * ボタンが押下されたことを通知する
       */
      this.$emit('formSubmit', this.inputData.password, this.userInfo)
    },
  },
})
