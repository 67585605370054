var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-input-pane" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("SignupPage.Steps.Step2") },
      }),
      _c("app-signup-form-section", {
        attrs: {
          email: _vm.email,
          userInfo: _vm.editUserInfo,
          openBirthdayMenuId: _vm.openBirthdayMenuId,
          isRegistering: _vm.state.isRegistering,
        },
        on: {
          onChangeEditUserInfo: _vm.setEditUserInfo,
          onClickBirthday: _vm.handleBirthdayClicked,
          onClickCloseBirthday: _vm.closeBirthdayOption,
          formSubmit: _vm.handlerSubmit,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }