import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'

export type SnsAccountInfoType = {
  /**
   * Googleから取得できるユーザを識別するID
   * - Googleアカウント登録の際に使用する
   */
  googleUserId?: string
  /**
   * Appleから取得できるユーザを識別するID
   * - Appleアカウント登録の際に使用する
   */
  appleUserId?: string
}

/**
 * 新規ユーザー情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1144094729/API
 */
export default class InitialUserDocument extends UserDocument {
  constructor(initProps?: Partial<InitialUserDocument>) {
    super(initProps as Partial<UserDocument>)
    this.mergeToThis(initProps)
  }

  _path = 'manage/service_registration/initial_user'

  /**
   * 登録トークンID
   */
  tokenId?: string | null = null

  /**
   * パスワード
   */
  password?: string | null = null

  /**
   * SNSアカウント情報
   */
  snsAccountInfo?: SnsAccountInfoType | null = null
}
