var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "race-player-info-section",
      class: "race-player-info-section--" + _vm.screenOrientationType,
    },
    [
      _c(
        "div",
        { staticClass: "race-player-info-section__inner" },
        [
          _c(
            "button",
            {
              staticClass: "race-player-info-section__selector",
              attrs: { type: "button" },
              on: { click: _vm.emitPlayerChange },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "18",
                    height: "18",
                    viewBox: "0 0 18 18",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M10.2374 2.17687L15.0607 7.00009H12.9393L9.17679 3.23753C9.07915 3.1399 8.92086 3.1399 8.82323 3.23753L5.06067 7.00009H2.93935L7.76257 2.17687C8.44599 1.49345 9.55403 1.49345 10.2374 2.17687ZM7.76255 15.8233L2.93933 11.0001H5.06065L8.82321 14.7627C8.92085 14.8603 9.07914 14.8603 9.17677 14.7627L12.9393 11.0001H15.0607L10.2374 15.8233C9.55401 16.5067 8.44597 16.5067 7.76255 15.8233Z",
                      fill: "white",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("player-card-parts", {
            staticClass: "race-player-info-section__card",
            attrs: {
              img: _vm.playerPictureUrl(_vm.selectedPlayer),
              name: _vm.playerName(_vm.selectedPlayer),
            },
            on: { click: _vm.emitPlayerChange },
          }),
          _c("div", { staticClass: "race-player-info-section__button-set" }, [
            _c(
              "button",
              {
                staticClass: "race-player-info-section__voice-switch",
                class: {
                  "race-player-info-section__voice-switch--selected":
                    _vm.radioVoiceEnabled,
                },
                attrs: { type: "button" },
                on: { click: _vm.toggleRadioVoice },
              },
              [
                _c(
                  "svg",
                  {
                    ref: "radioCountIcon",
                    attrs: {
                      width: "18",
                      height: "18",
                      viewBox: "0 0 18 18",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: { d: "M6.5 0H8V5H6.5V0Z", fill: "white" },
                    }),
                    _c("path", {
                      attrs: { d: "M9.5 4H12V5H9.5V4Z", fill: "white" },
                    }),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M5 7C5 6.44772 5.44772 6 6 6H12C12.5523 6 13 6.44772 13 7V17C13 17.5523 12.5523 18 12 18H6C5.44772 18 5 17.5523 5 17V7ZM6.5 7.5H11.5V8.5H6.5V7.5ZM11.5 9.5H6.5V10.5H11.5V9.5ZM7.5 13C7.5 13.2761 7.27614 13.5 7 13.5C6.72386 13.5 6.5 13.2761 6.5 13C6.5 12.7239 6.72386 12.5 7 12.5C7.27614 12.5 7.5 12.7239 7.5 13ZM9 13.5C9.27614 13.5 9.5 13.2761 9.5 13C9.5 12.7239 9.27614 12.5 9 12.5C8.72386 12.5 8.5 12.7239 8.5 13C8.5 13.2761 8.72386 13.5 9 13.5ZM11.5 13C11.5 13.2761 11.2761 13.5 11 13.5C10.7239 13.5 10.5 13.2761 10.5 13C10.5 12.7239 10.7239 12.5 11 12.5C11.2761 12.5 11.5 12.7239 11.5 13ZM7 15.5C7.27614 15.5 7.5 15.2761 7.5 15C7.5 14.7239 7.27614 14.5 7 14.5C6.72386 14.5 6.5 14.7239 6.5 15C6.5 15.2761 6.72386 15.5 7 15.5ZM9.5 15C9.5 15.2761 9.27614 15.5 9 15.5C8.72386 15.5 8.5 15.2761 8.5 15C8.5 14.7239 8.72386 14.5 9 14.5C9.27614 14.5 9.5 14.7239 9.5 15ZM11 15.5C11.2761 15.5 11.5 15.2761 11.5 15C11.5 14.7239 11.2761 14.5 11 14.5C10.7239 14.5 10.5 14.7239 10.5 15C10.5 15.2761 10.7239 15.5 11 15.5Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "race-player-info-section__voice-switch__count",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.getCurrentSelectedPlayerRadioDataSet.length)
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "button",
              {
                staticClass: "race-player-info-section__race-select",
                class: {
                  "race-player-info-section__race-select--selected":
                    _vm.raceSelectEnabled,
                },
                attrs: { type: "button" },
                on: { click: _vm.toggleRaceSelect },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "18",
                      height: "18",
                      viewBox: "0 0 18 18",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M8 3H3.78078C3.32191 3 2.92193 3.3123 2.81063 3.75746L2 7H6.21922C6.67809 7 7.07807 6.6877 7.18937 6.24254L8 3Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M18 3H13.7808C13.3219 3 12.9219 3.3123 12.8106 3.75746L12 7H16.2192C16.6781 7 17.0781 6.6877 17.1894 6.24254L18 3Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M6 11H1.78078C1.32191 11 0.921926 11.3123 0.810634 11.7575L0 15H4.21922C4.67809 15 5.07807 14.6877 5.18937 14.2425L6 11Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M16 11H11.7808C11.3219 11 10.9219 11.3123 10.8106 11.7575L10 15H14.2192C14.6781 15 15.0781 14.6877 15.1894 14.2425L16 11Z",
                        fill: "white",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "M12 7H7.78078C7.32191 7 6.92193 7.3123 6.81063 7.75746L6 11H10.2192C10.6781 11 11.0781 10.6877 11.1894 10.2425L12 7Z",
                        fill: "white",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }