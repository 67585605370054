var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-one-time-pass-confirm-page" },
    [
      _c("header-pane", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.pageTitle"),
          colorType: "light",
        },
      }),
      _c(
        "div",
        { staticClass: "app-signup-one-time-pass-confirm-page__main" },
        [
          _c("app-signup-one-time-pass-confirm-pane", {
            on: { formSubmit: _vm.moveToAppConfirmationSentPage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }