var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-signup-form-section" }, [
    _c(
      "div",
      { staticClass: "app-signup-form-section__main" },
      [
        _c("fieldset-email-parts", {
          attrs: { isReadOnly: true, showError: false, required: false },
          model: {
            value: _vm.inputData.email,
            callback: function ($$v) {
              _vm.$set(_vm.inputData, "email", $$v)
            },
            expression: "inputData.email",
          },
        }),
        _c("ValidationObserver", {
          ref: "validationObserver",
          attrs: { slim: "" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var errors = ref.errors
                var invalid = ref.invalid
                return [
                  _c(
                    "div",
                    { staticClass: "app-signup-form-section__item" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          slim: "",
                          name: "password",
                          rules: "required|passwordFormat",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c("fieldset-password-parts", {
                                    attrs: {
                                      required: true,
                                      showError: errors.length > 0,
                                      errorMessage: errors[0],
                                    },
                                    model: {
                                      value: _vm.inputData.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputData, "password", $$v)
                                      },
                                      expression: "inputData.password",
                                    },
                                  }),
                                  _c("form-error-message-parts", {
                                    attrs: {
                                      showError: errors.length > 0,
                                      message: errors[0],
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "app-signup-form-section__item" },
                    [
                      _c("fieldset-name-parts", {
                        attrs: {
                          valueFamily: _vm.familyName,
                          valueFirst: _vm.firstName,
                          required: true,
                          showError:
                            !!Object.assign({}, errors.familyName)[0] ||
                            !!Object.assign({}, errors.firstName)[0],
                          errorMessage:
                            Object.assign({}, errors.familyName)[0] ||
                            Object.assign({}, errors.firstName)[0],
                        },
                        on: {
                          inputfamilyName: function ($event) {
                            return _vm.inputValue($event, "familyName")
                          },
                          inputfirstName: function ($event) {
                            return _vm.inputValue($event, "firstName")
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("ValidationProvider", {
                    attrs: {
                      slim: "",
                      name: "birthDay",
                      rules: "required|validBirthDay",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "app-signup-form-section__item",
                                },
                                [
                                  _c("fieldset-birthday-parts", {
                                    attrs: {
                                      openBirthdayMenuId:
                                        _vm.openBirthdayMenuId,
                                      required: true,
                                      showError: errors.length > 0,
                                      errorMessage: errors[0],
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.inputValue(
                                          $event,
                                          "birthDay"
                                        )
                                      },
                                      onClickBirthday:
                                        _vm.handleBirthdayClicked,
                                      onClickCloseBirthday: function ($event) {
                                        return _vm.$emit("onClickCloseBirthday")
                                      },
                                    },
                                    model: {
                                      value: _vm.birthDay,
                                      callback: function ($$v) {
                                        _vm.birthDay = $$v
                                      },
                                      expression: "birthDay",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("ValidationProvider", {
                    attrs: { slim: "", name: "country", rules: "required" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "app-signup-form-section__item",
                                },
                                [
                                  _c("fieldset-country-parts", {
                                    attrs: {
                                      required: true,
                                      showError: errors.length > 0,
                                      errorMessage: errors[0],
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.inputValue($event, "country")
                                      },
                                    },
                                    model: {
                                      value: _vm.country,
                                      callback: function ($$v) {
                                        _vm.country = $$v
                                      },
                                      expression: "country",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("div", { staticClass: "app-signup-form-section__term" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$tc("SignupPage.FormInput.TermLinkText")
                        ),
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "app-signup-form-section__agreement" },
                    [
                      _c("fieldset-checkbox-parts", {
                        attrs: {
                          value: _vm.inputData.agreement,
                          label: _vm.$tc("SignupPage.FormInput.Agreement"),
                        },
                        on: { changed: _vm.onAgreementChecked },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "action-buttons-section",
                    {
                      staticClass: "signup-form-section__action-buttons",
                      attrs: {
                        scrollTargetSelector: ".app-signup-form-section",
                        visible: true,
                      },
                    },
                    [
                      _c("atom-input-button", {
                        attrs: {
                          linkText: _vm.$tc("common.registration"),
                          isDisabled: invalid || _vm.submitDisabled,
                          colorType: "secondary",
                        },
                        on: { push: _vm.onFormSubmit },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }