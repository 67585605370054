import { computed } from '@vue/composition-api'
import { orderBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import ReceivedDataDocument from '@/store/stores/collectionModule/documents/received/ReceivedDataDocument'
import useDynamoDB, { SearchDateRangeDataType } from '@/store/hook/useDynamoDB'
import LoginStore from '@/store/stores/loginStore/LoginStore'

/**
 * ユーザー指定・特典の受信データを操作するための処理を取得する。
 */
export default function useReceivedData() {
  // Collection modules
  const receivedDataCollectionModule = CollectionModule.createStore(ReceivedDataDocument)

  const { searchDateRangeData } = useDynamoDB()

  /**
   * ユーザー指定・特典の受信データを取得する。
   */
  const fetchReceivedData = async () => {
    const params = {
      tableName: 'received-data',
      partitionKey: 'userId',
      partitionKeyValue: LoginStore.value.userId,
      sortKey: 'createdDate',
    } as SearchDateRangeDataType

    // 2回目以降は差分を取得する
    if (receivedDataCollectionModule.data.length > 0) {
      const from = orderBy(receivedDataCollectionModule.data, 'createdDate', 'desc')[0].createdDate
      if (!from) return
      params.from = from + 1
      params.to = new Date().getTime() + 60000
    }
    const result = await searchDateRangeData(params)

    const currentIds = receivedDataCollectionModule.data.map((item) => item.id)
    const newIds = result.map((item) => item.id)
    const allIds = [...new Set([...currentIds, ...newIds])]

    const dataMap = receivedDataCollectionModule.data.reduce((acc, cur) => {
      if (cur.id) {
        acc[cur.id] = cur
      }
      return acc
    }, Object.create(null))

    const updateDataMap = result.reduce((acc, d) => {
      if (d.id) {
        acc[d.id] = d
      }
      return acc
    }, Object.create(null))

    receivedDataCollectionModule.data = allIds.map(
      (id) => updateDataMap[id || ''] || dataMap[id || ''],
    )
  }

  /**
   * 取得したReceivedデータ一覧
   */
  const receivedData = computed(() => receivedDataCollectionModule.data)

  /**
   * 取得したReceivedデータのattachedDataIdの一覧
   */
  const attachedDataIdList = computed(() =>
    receivedDataCollectionModule.data.map((data) => data.attachedDataId ?? ''),
  )

  /**
   * 取得したReceivedデータをクリアする
   */
  const clearReceivedData = () => {
    receivedDataCollectionModule.clearData()
  }

  return {
    fetchReceivedData,
    receivedData,
    attachedDataIdList,
    clearReceivedData,
  }
}
