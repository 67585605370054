import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'

/**
 * 登録トークン有効情報を表現するクラス。
 * @see https://pitchbase.atlassian.net/wiki/spaces/SL01/pages/1135870048/07+API
 */
export default class UserValidateTokenDocument extends DocumentWrapper {
  constructor(initProps?: Partial<UserValidateTokenDocument>) {
    super(initProps as Partial<DocumentWrapper>)
    this.mergeToThis(initProps)
  }

  /**
   * 有効確認結果
   */
  isValid?: boolean | null = false

  /**
   * 登録トークンエンティティ
   * isValid が true の場合返す
   */
  manageRegistToken?: {
    mailAddress: string

    /**
     * トークン有効期限
     */
    tokenExpirationDate?: number | null

    /**
     * 会員登録情報の追加項目
     */
    additionalData?: {
      /**
       * 会員登録タイプ
       */
      memberType: MemberType
    }
  }

  /**
   * 無効理由
   * isValid が false の場合返す
   */
  ngReason?: Array<string> | null = null
}
