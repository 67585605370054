


























































import { defineComponent } from '@vue/composition-api'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import AtomRouterButton from '@/components/atoms/AtomRouterButton.vue'
import AtomButton from '@/components/atoms/AtomButton.vue'

/**
 * 会員情報入力催促モーダル セクション
 */
export default defineComponent({
  name: 'UserInfoPromptModalSection',
  components: { AtomButton, AtomRouterButton, CommonModalSection },
  setup(_, { emit }) {
    /**
     * 会員情報入力催促モーダルにあるボタンが押下された時の処理
     */
    const handleClicked = (destination: string) => {
      emit('onClick', destination)
    }

    return {
      handleClicked,
    }
  },
})
