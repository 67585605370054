import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import VideoPlatformContentsDocument from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import useVideoPlatformContentsParams from '@/store/hook/videoPlatformContents/useVideoPlatformContentsParams'

/**
 * Race Pointを操作するための処理
 */
export default function useRacePoints() {
  const racePointsCollectionModule = CollectionModule.createStore(VideoPlatformContentsDocument)

  const { generateDisplayFilter } = useVideoPlatformContentsParams()

  /**
   * Race Point Video、Race Point Radio情報一覧取得
   */
  const fetchRacePoints = async () =>
    racePointsCollectionModule.fetch({
      query: {
        filter: {
          $and: [
            generateDisplayFilter(),
            {
              $or: [{ videoType: 'video' }, { videoType: 'radio' }],
            },
          ],
        },
        // 作成日時が新しい順にソートする
        sort: '-_createdDate',
      },
    })

  /**
   * Race Point Video、Race Point Radio情報一覧
   */
  const racePoints = computed(() => racePointsCollectionModule.data)

  /**
   * 取得したRace Point 情報をクリアする
   */
  const clearRacePoints = () => {
    racePointsCollectionModule.clearData()
  }

  return {
    fetchRacePoints,
    racePoints,
    clearRacePoints,
  }
}
