












import { defineComponent, onBeforeMount, onUnmounted } from '@vue/composition-api'
import LinkSection from '@/components/LinkPage/LinkPane/LinkSection.vue'
import { LinkTransitionType } from '@/components/LinkPage/LinkPane/LinkItemParts.vue'
import i18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import DeviceInfo from '@/util/DeviceInfo'
import Const from '@/util/Const'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Logger from '@/util/logger/Logger'

export type LinkData = {
  iconPath: string
  text: string
  url: string
  transitionCase?: LinkTransitionType
}

export type LinkSectionData = {
  sectionTitle: string
  links: LinkData[]
}

/**
 * Link ペイン
 */
export default defineComponent({
  name: 'LinkPane',
  components: {
    LinkSection,
  },
  setup() {
    // store
    const userStore = StoreUtil.useStore('UserStore')
    const notificationStore = StoreUtil.useStore('NotificationStore')
    const { user } = userStore
    const { fetchNotificationData } = notificationStore

    // hook
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()

    const userLang = user.value.lang || DeviceInfo.getLanguage()

    const linksData: LinkSectionData[] = [
      {
        sectionTitle: i18n.tc('LinkPage.announcement.title'),
        links: [
          {
            iconPath: 'assets/img/LinkPage/icon_award.svg',
            text: i18n.tc('LinkPage.announcement.award'),
            url: '/link/award/',
            transitionCase: 'routerLink',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_calendar.svg',
            text: i18n.tc('LinkPage.announcement.news'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.INFORMATION.JA
                : Const.EXTERNAL_LINKS.INFORMATION.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_ranking.svg',
            text: i18n.tc('LinkPage.announcement.driverStandings'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.DRIVER_STANDINGS.JA
                : Const.EXTERNAL_LINKS.DRIVER_STANDINGS.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_ranking.svg',
            text: i18n.tc('LinkPage.announcement.teamStandings'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.TEAM_STANDINGS.JA
                : Const.EXTERNAL_LINKS.TEAM_STANDINGS.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_race.svg',
            text: i18n.tc('LinkPage.announcement.liveTiming'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.LIVE_TIMING.JA
                : Const.EXTERNAL_LINKS.LIVE_TIMING.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_calendar.svg',
            text: i18n.tc('LinkPage.announcement.motorsportCalendar'),
            url: '/link/calendar/',
            transitionCase: 'routerLink',
          },
        ],
      },
      {
        sectionTitle: i18n.tc('LinkPage.purchase.title'),
        links: [
          {
            iconPath: 'assets/img/LinkPage/icon_cart.svg',
            text: i18n.tc('LinkPage.purchase.officialShop'),
            url: Const.EXTERNAL_LINKS.EC_SITE.TOP,
            transitionCase: 'inAppBrowser',
          },
        ],
      },
      {
        sectionTitle: i18n.tc('LinkPage.aboutSuperFormula.title'),
        links: [
          {
            iconPath: 'assets/img/LinkPage/icon_menu_book.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.about'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.ABOUT_SF.JA
                : Const.EXTERNAL_LINKS.ABOUT_SF.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_driver.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.drivers'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.DRIVER_TAXONOMY.JA
                : Const.EXTERNAL_LINKS.DRIVER_TAXONOMY.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_team.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.teams'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.TEAM_TAXONOMY.JA
                : Const.EXTERNAL_LINKS.TEAM_TAXONOMY.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_machine.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.machine'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.ABOUT_MACHINE.JA
                : Const.EXTERNAL_LINKS.ABOUT_MACHINE.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_calendar.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.raceCalendar'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.RACE_TAXONOMY.JA
                : Const.EXTERNAL_LINKS.RACE_TAXONOMY.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_logo.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.aboutSFgo'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA
                : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_info_book.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.history'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.ABOUT_HISTORY.JA
                : Const.EXTERNAL_LINKS.ABOUT_HISTORY.EN,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_driver.svg',
            text: i18n.tc('LinkPage.aboutSuperFormula.legendDrivers'),
            url:
              userLang === 'ja'
                ? Const.EXTERNAL_LINKS.ABOUT_DRIVER.JA
                : Const.EXTERNAL_LINKS.ABOUT_DRIVER.EN,
            transitionCase: 'inAppBrowser',
          },
        ],
      },
      {
        sectionTitle: i18n.tc('LinkPage.sns.title'),
        links: [
          {
            iconPath: 'assets/img/LinkPage/icon_x.svg',
            text: i18n.tc('LinkPage.sns.twitter'),
            url: Const.EXTERNAL_LINKS.X.SUPER_FORMULA,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_instagram.svg',
            text: i18n.tc('LinkPage.sns.instagram'),
            url: Const.EXTERNAL_LINKS.INSTAGRAM.OFFICIAL,
            transitionCase: 'externalLink',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_youtube.svg',
            text: i18n.tc('LinkPage.sns.youtube'),
            url: Const.EXTERNAL_LINKS.YOUTUBE.OFFICIAL,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_circuit_fuji.svg',
            text: i18n.tc('LinkPage.sns.fuji'),
            url: Const.EXTERNAL_LINKS.X.FUJI,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_circuit_suzuka.svg',
            text: i18n.tc('LinkPage.sns.suzuka'),
            url: Const.EXTERNAL_LINKS.X.SUZUKA,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_circuit_auto_polis.svg',
            text: i18n.tc('LinkPage.sns.autoPolis'),
            url: Const.EXTERNAL_LINKS.X.AUTOPOLIS,
            transitionCase: 'inAppBrowser',
          },
          {
            iconPath: 'assets/img/LinkPage/icon_circuit_motegi.svg',
            text: i18n.tc('LinkPage.sns.motegi'),
            url: Const.EXTERNAL_LINKS.X.MOTEGI,
            transitionCase: 'inAppBrowser',
          },
        ],
      },
    ]

    // SFBingoのURLが環境変数にある場合にはSFBingoへのリンクを表示する
    if (process.env.VUE_APP_SFBINGO_URL) {
      linksData[0].links.unshift({
        iconPath: 'assets/img/LinkPage/icon_sfbingo.svg',
        text: i18n.tc('LinkPage.announcement.sfbingo'),
        url: process.env.VUE_APP_SFBINGO_URL,
        transitionCase: 'inAppBrowser',
      } as LinkData)
    }

    onBeforeMount(async () => {
      // お知らせのリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `LinkPane#subscribeRTM: Receive notification event. event: ${JSON.stringify(data)}`,
        )
        fetchNotificationData()
      })
    })

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()
    })

    return {
      linksData,
    }
  },
})
