




























































































































import { defineComponent, PropType } from '@vue/composition-api'
import dayjs from 'dayjs'
import FieldsetNameParts from '@/components/common/form/FieldsetNameParts.vue'
import FieldsetNameRubyParts from '@/components/common/form/FieldsetNameRubyParts.vue'
import FieldsetDisplayNameParts from '@/components/common/form/FieldsetDisplayNameParts.vue'
import FieldsetBirthdayParts, {
  OpenBirthdayMenuIdType,
} from '@/components/common/form/FieldsetBirthdayParts.vue'
import FieldsetGenderParts from '@/components/common/form/FieldsetGenderParts.vue'
import FieldsetCountryParts from '@/components/common/form/FieldsetCountryParts.vue'
import FieldsetPrefecturesParts from '@/components/common/form/FieldsetPrefecturesParts.vue'
import FieldsetZipcodeParts from '@/components/common/form/FieldsetZipcodeParts.vue'
import FieldsetUserEditMailPasswordParts from '@/components/MypageUserEditPage/MypageUserEditPane/parts/FieldsetUserEditMailPasswordParts.vue'
import EmailChangeModalSection from '@/components/MypageUserEditPage/MypageUserEditPane/EmailChangeModalSection.vue'
import PasswordChangeModalSection from '@/components/MypageUserEditPage/MypageUserEditPane/PasswordChangeModalSection.vue'
import MessageModalSection from '@/components/common/modal/MessageModalSection.vue'
import TitledModalSection from '@/components/common/modal/TitledModalSection.vue'
import ModalMessageParts from '@/components/common/modal/parts/ModalMessageParts.vue'
import StoreUtil from '@/store/StoreUtil'
import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import Const from '@/util/Const'

export type DataType = {
  firstName: string
  familyName: string
  firstNameKana: string
  familyNameKana: string
  userDisplayName: string
  birthDay: string
  gender: string
  country: string
  prefecture: string
  zipcode: string
  emailChangeModal: boolean
  emailChangeCompleteModal: boolean
  emailChangeErrorModal: boolean
  passwordChangeModal: boolean
  passwordChangeCompleteModal: boolean
  passwordChangeErrorModal: boolean
  showNameError: boolean
  nameErrorMessage: string
  showKanaError: boolean
  kanaErrorMessage: string
  isExistDisplayName: boolean
  showDisplayNameError: boolean
  displayNameErrorMessage: string
  showBirthDayError: boolean
  birthDayErrorMessage: string
  showCountryError: boolean
  showZipcodeError: boolean
  zipcodeErrorMessage: string
  showPrefecturesParts: boolean
  showZipcodeParts: boolean
  isSetBirthday: boolean
}

/**
 * マイページ: 会員情報変更 入力セクション
 * todo: コンポーネント間データ連携 / エラー表示 / emit等
 */
export default defineComponent({
  name: 'UserEditFormSection',
  components: {
    FieldsetNameParts,
    FieldsetNameRubyParts,
    FieldsetDisplayNameParts,
    FieldsetBirthdayParts,
    FieldsetGenderParts,
    FieldsetCountryParts,
    FieldsetPrefecturesParts,
    FieldsetZipcodeParts,
    FieldsetUserEditMailPasswordParts,
    EmailChangeModalSection,
    PasswordChangeModalSection,
    MessageModalSection,
    ModalMessageParts,
    TitledModalSection,
  },
  props: {
    openBirthdayMenuId: {
      type: String as PropType<OpenBirthdayMenuIdType>,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const loginStore = StoreUtil.useStore('LoginStore')

    const { getDisplayDate } = useDisplayDependingOnLang()

    const userInfo: UserDocument = mypagePageStore.user.value
    /** 名前（ふりがな）の入力を行うかどうか */
    const userNameRubyEnabled = userInfo.lang === 'ja' || !userInfo.lang

    const handleBirthdayClicked = (menuId: string) => {
      emit('onClickBirthday', menuId)
    }

    return {
      getDisplayDate,
      userInfo,
      existDisplayName: mypagePageStore.existDisplayName.value,
      email: mypagePageStore.user.value.mailAddress,
      password: loginStore.password,
      confirmUser: mypagePageStore.confirmUser,
      userNameRubyEnabled,
      handleBirthdayClicked,
    }
  },
  data(): DataType {
    return {
      firstName: '',
      familyName: '',
      firstNameKana: '',
      familyNameKana: '',
      userDisplayName: '',
      birthDay: '',
      gender: '',
      country: '',
      prefecture: '',
      zipcode: '',
      emailChangeModal: false, // メールアドレス変更モーダル 表示フラグ
      emailChangeCompleteModal: false, // メールアドレス変更完了モーダル 表示フラグ
      emailChangeErrorModal: false, // メールアドレス変更失敗モーダル 表示フラグ
      passwordChangeModal: false, // パスワード変更モーダル 表示フラグ
      passwordChangeCompleteModal: false, // パスワード変更完了モーダル 表示フラグ
      passwordChangeErrorModal: false, // パスワード変更失敗モーダル 表示フラグ
      showNameError: false,
      nameErrorMessage: '',
      showKanaError: false,
      kanaErrorMessage: '',
      isExistDisplayName: false, // ニックネームが既に登録されているかどうか フラグ
      showDisplayNameError: false,
      displayNameErrorMessage: '',
      showBirthDayError: false,
      birthDayErrorMessage: '',
      showCountryError: false,
      showZipcodeError: false,
      zipcodeErrorMessage: '',
      showPrefecturesParts: false,
      showZipcodeParts: false,
      isSetBirthday: false, // ユーザー情報に登録している生年月日を編集データにセットしたかどか
    }
  },
  mounted() {
    this.firstName = this.userInfo.firstName || ''
    this.familyName = this.userInfo.familyName || ''
    this.firstNameKana = this.userInfo.firstNameKana || ''
    this.familyNameKana = this.userInfo.familyNameKana || ''
    this.userDisplayName = this.userInfo.userDisplayName || ''
    // ニックネームが既に登録済みであるかどうかのフラグをセット
    this.isExistDisplayName = !!this.existDisplayName

    const birthDayNumber = this.userInfo.additionalData?.birthDay ?? new Date().getTime()
    // 生年月日編集データについては存在しない日付をチェックするため、フォーマットを YYYY/MM/DD に統一している
    this.birthDay = this.getDisplayDate(birthDayNumber, 'YYYY/MM/DD', 'YYYY/MM/DD')
    this.isSetBirthday = true
    this.gender = this.userInfo.gender || ''
    this.country = this.userInfo.country || ''
    if (this.country === 'JP') {
      this.prefecture = this.userInfo.prefecture || ''
      this.showPrefecturesParts = true
      this.zipcode = this.userInfo.zipcode || ''
      this.showZipcodeParts = true
    } else {
      this.prefecture = ''
      this.showPrefecturesParts = false
      this.zipcode = ''
      this.showZipcodeParts = false
    }
  },
  methods: {
    /**
     * 確認保存処理
     */
    confirmUserInfo() {
      this.confirmUser(
        this.familyName,
        this.firstName,
        this.familyNameKana,
        this.firstNameKana,
        this.userDisplayName,
        this.birthDay,
        this.gender,
        this.country,
        this.prefecture,
        this.zipcode,
      )
      /**
       * エラー表示リセット
       */
      this.showNameError = false
      this.showKanaError = false
      this.showDisplayNameError = false
      this.showBirthDayError = false
      this.showCountryError = false
      this.showZipcodeError = false
      this.nameErrorMessage = ''
      this.kanaErrorMessage = ''
      this.birthDayErrorMessage = ''
      this.zipcodeErrorMessage = ''

      /**
       * 名前バリデーション
       */
      if (this.familyName.length < 1 || this.familyName.length > 32) {
        this.showNameError = true
        this.nameErrorMessage = ''
      }
      if (this.firstName.length < 1 || this.firstName.length > 32) {
        this.showNameError = true
        this.nameErrorMessage = ''
      }
      /**
       * 名前ふりがなバリデーション
       */
      if (this.userNameRubyEnabled) {
        if (this.familyNameKana.length > 32) {
          this.showKanaError = true
          this.kanaErrorMessage = ''
        }
        if (this.firstNameKana.length > 32) {
          this.showKanaError = true
          this.kanaErrorMessage = ''
        }
      }

      /**
       * ニックネームバリデーション
       * すでに登録されたデータがあり、ブランクで保存しようとした際にエラーにする
       */
      if (this.isExistDisplayName && this.userDisplayName === '') {
        this.showDisplayNameError = true
        this.displayNameErrorMessage = this.$tc('formParts.errorMessage.displayName') as string
      }

      /**
       * ニックネーム文字数バリデーション
       */
      if (this.userDisplayName.length > Const.MAX_DISPLAY_NAME_LENGTH) {
        this.showDisplayNameError = true
        this.displayNameErrorMessage = this.$tc('formParts.errorMessage.displayNameLengthExceeded')
      }

      /**
       * 生年月日バリデーション
       */
      if (this.birthDay === '' || !this.birthDay) {
        this.showBirthDayError = true
      }
      if (this.birthDay) {
        if (dayjs(this.birthDay).format('YYYY/MM/DD') !== this.birthDay) {
          /**
           * 存在しない日付の場合はエラー
           * 条件は以下を参考にした
           * https://github.com/iamkun/dayjs/issues/320#issuecomment-537885327
           */
          this.showBirthDayError = true
          this.birthDayErrorMessage = this.$tc('formParts.errorMessage.invalidBirthDay')
        }

        const todayMillisec = new Date(new Date().toDateString()).getTime()
        const birthDayMillisec = new Date(new Date(this.birthDay).toDateString()).getTime()
        const threeYearsAgo = dayjs(todayMillisec).subtract(3, 'year').valueOf()
        // 3歳未満はエラー
        if (threeYearsAgo < birthDayMillisec) {
          this.showBirthDayError = true
          this.birthDayErrorMessage = this.$tc('formParts.errorMessage.invalidBirthDay')
        }
      }
      /**
       * お住まいの国バリデーション
       */
      if (this.country === '') {
        this.showCountryError = true
      }
      /**
       * お住まいの国が日本の場合バリデーション
       */
      if (this.country === 'JP') {
        /**
         * 郵便番号バリデーション
         * ^[0-9]{7}$
         */
        // 有料プラン・無料プラン共通バリデーション
        if (this.zipcode) {
          const regex = new RegExp(/^[0-9]{7}$/)
          if (!regex.test(this.zipcode)) {
            this.showZipcodeError = true
            this.zipcodeErrorMessage = this.$tc('formParts.errorMessage.zipcodeFormat') as string
          }
        }
      }
      if (
        !this.showNameError &&
        !this.showKanaError &&
        !this.showDisplayNameError &&
        !this.showBirthDayError &&
        !this.showCountryError &&
        !this.showZipcodeError
      ) {
        this.confirmUser(
          this.familyName,
          this.firstName,
          this.familyNameKana,
          this.firstNameKana,
          this.userDisplayName,
          this.birthDay,
          this.gender,
          this.country,
          this.prefecture,
          this.zipcode,
        )
        /**
         * 確認画面へ遷移
         * @event success-confirm
         */
        this.$emit('success-confirm')
      }
    },
    /**
     * 名（漢字）が変更された場合
     */
    inputfirstName(value: string) {
      this.firstName = value
    },
    /**
     * 姓（漢字）が変更された場合
     */
    inputfamilyName(value: string) {
      this.familyName = value
    },
    /**
     * 名（漢字）が変更された場合
     */
    inputfirstNameKana(value: string) {
      this.firstNameKana = value
    },
    /**
     * 姓（漢字）が変更された場合
     */
    inputfamilyNameKana(value: string) {
      this.familyNameKana = value
    },
    /**
     * Display Nameが変更された場合
     */
    inputDisplayName(value: string) {
      this.userDisplayName = value
    },
    /**
     * お住まいの国が変更された場合
     */
    changeCountry() {
      if (this.country === 'JP') {
        this.showPrefecturesParts = true
        this.showZipcodeParts = true
      } else {
        this.showPrefecturesParts = false
        this.showZipcodeParts = false
        this.prefecture = ''
        this.zipcode = ''
      }
    },
    /**
     * メール送信完了処理時 & モーダル切り替え処理
     */
    sendAuthenticationMail(submitType: string): void {
      this.emailChangeModal = false

      if (submitType === 'complete') {
        this.emailChangeCompleteModal = true
      }
    },
    /**
     * パスワード変更完了処理 & モーダル切り替え処理
     */
    changePasswordComplete(submitType: string): void {
      this.passwordChangeModal = false

      if (submitType === 'complete') {
        this.passwordChangeCompleteModal = true
      }
    },
    /**
     * メールアドレス変更失敗 & モーダル切り替え処理
     */
    changeEmailError(): void {
      this.emailChangeModal = false
      this.emailChangeCompleteModal = false
      this.emailChangeErrorModal = true
    },
    /**
     * パスワード変更失敗 & モーダル切り替え処理
     */
    changePasswordError(): void {
      this.passwordChangeModal = false
      this.passwordChangeCompleteModal = false
      this.passwordChangeErrorModal = true
    },
    /**
     * キャンセル確認
     */
    editCancel(): void {
      if (this.firstName !== this.userInfo.firstName) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.familyName !== this.userInfo.familyName) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.firstNameKana !== this.userInfo.firstNameKana) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.familyNameKana !== this.userInfo.familyNameKana) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.userDisplayName !== (this.userInfo.userDisplayName ?? '')) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.birthDay !== this.userInfo.birthDay) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.gender !== this.userInfo.gender) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.country !== this.userInfo.country) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.prefecture !== (this.userInfo.prefecture ?? '')) {
        this.$emit('show-cancel', true)
        return
      }
      if (this.zipcode !== (this.userInfo.zipcode ?? '')) {
        this.$emit('show-cancel', true)
        return
      }
      this.$emit('show-cancel', false)
    },
  },
})
