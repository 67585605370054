

















import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  Ref,
  ref,
} from '@vue/composition-api'
import { LoaderComponent } from 'vue-loading-overlay'
import { cloneDeep } from 'lodash'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import AppSignupFormSection from '@/components/AppSignupPage/AppSignupInputPane/AppSignupFormSection.vue'
import StoreUtil from '@/store/StoreUtil'
import useContract from '@/components/hook/useContract'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import type { OpenBirthdayMenuIdType } from '@/components/common/form/FieldsetBirthdayParts.vue'
import type { EditUserType } from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import useHistory from '@/store/hook/useHistory'
import OrganizationDocument from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import SecureKeyStore from '@/util/secureKeyStore/SecureKeyStore'
import useLogin from '@/components/hook/useLogin'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import useOneTimePassContract from '@/components/hook/useOneTimePassContract'
import useContractPlan from '@/store/hook/useContractPlan'
import AdjustTime from '@/util/timeSynchronisation/AdjustTime'
import useOneTimePassErrorHandling from '@/components/hook/error/useOneTimePassErrorHandling'
import Logger from '@/util/logger/Logger'
import { RegistMethodType } from '@/store/stores/collectionModule/documents/user/UserRegisterTokenDocument'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'

/**
 * アプリ新規会員登録: 会員情報入力画面ペインのコンポーネント
 */
export default defineComponent({
  name: 'AppSignupInputPane',
  components: {
    SubHeaderSection,
    AppSignupFormSection,
  },
  props: {
    /**
     * 登録トークン管理APIで発行されたトークンID
     */
    tokenId: {
      type: String,
      required: true,
    },
    /**
     * 認証メール送信画面で入力したメールアドレス
     */
    email: {
      type: String,
      required: true,
    },
    /**
     * 登録方法
     */
    method: {
      type: String as PropType<RegistMethodType>,
      default: 'MAIL_ADDRESS',
    },
    /**
     * ワンタイムパスを特定するためのキー
     */
    keyIdentifyOneTimePass: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const loginStore = StoreUtil.useStore('LoginStore')
    const signupPageStore = StoreUtil.useStore('SignupPageStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const { createContract } = useContract()
    const { saveMissionHistoryForProfile } = useHistory()
    const { login: _login } = useLogin()
    const { createOneTimePassContract } = useOneTimePassContract()
    const { getCouponPlanWithCouponDocument } = useContractPlan()
    const { createContractError } = useOneTimePassErrorHandling()
    const { checkEmailAddressDuplicated } = usePasswordResetToken()

    const state = reactive({
      isRegistering: false,
    })

    const openBirthdayMenuId = ref('' as OpenBirthdayMenuIdType)

    /**
     * 前の画面で入力したワンタイムパス情報
     */
    const inputtedOneTimePass = computed(() => {
      if (props.method === 'APPLE') {
        // Apple認可の場合、画面遷移フローがないため、ストアに保存されているワンタイムパスを返す
        return oneTimePassPageStore.oneTimePass.value
      }

      const oneTimePassList = LocalStorageAccessor.getAppSignupOneTimePass()
      const coupon = oneTimePassList.find(
        (oneTimePass) => oneTimePass.key === props.keyIdentifyOneTimePass,
      )?.couponInfo
      return coupon ? new CouponDocument(coupon) : null
    })

    /**
     * プランなしのワンタイムパスかどうか
     */
    const isOneTimePassWithNoPlan = computed(
      () => inputtedOneTimePass.value?.availableArea?.checkLocationTiming === 'watchingVideo',
    )

    const handleBirthdayClicked = (menuId: OpenBirthdayMenuIdType) => {
      openBirthdayMenuId.value = openBirthdayMenuId.value === menuId ? '' : menuId
    }

    const closeBirthdayOption = () => {
      openBirthdayMenuId.value = ''
    }

    /**
     * 認可後に受け取れる情報をユーザー情報にセットする
     */
    const setUserInfoReceivedInAuth = (method: RegistMethodType) => {
      let firstName = ''
      let familyName = ''
      if (method === 'GOOGLE') {
        // Google認可の場合
        firstName = signupPageStore.googleUserInfo.value.givenName ?? ''
        familyName = signupPageStore.googleUserInfo.value.familyName ?? ''
      } else if (method === 'APPLE') {
        // Apple認可の場合
        firstName = signupPageStore.appleUserInfo.value?.fullName?.givenName ?? ''
        familyName = signupPageStore.appleUserInfo.value?.fullName?.familyName ?? ''
      }

      mypagePageStore.editUserInfo.value = {
        firstName,
        familyName,
        firstNameKana: '',
        familyNameKana: '',
        userDisplayName: '',
        birthDay: '',
        gender: '',
        country: '',
        prefecture: '',
        zipcode: '',
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', closeBirthdayOption)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', closeBirthdayOption)
    })

    if (props.method === 'GOOGLE' || props.method === 'APPLE') {
      // 認可で受け取る情報をユーザー情報にセットする
      setUserInfoReceivedInAuth(props.method)
    }

    return {
      cloneDeep,
      // AppSignupInputPane
      state,
      openBirthdayMenuId,
      inputtedOneTimePass,
      isOneTimePassWithNoPlan,
      handleBirthdayClicked,
      closeBirthdayOption,
      // LoginStore
      loginStore,
      // SignupPageStore
      signupPageStore,
      // MypagePageStore
      editUserInfo: mypagePageStore.editUserInfo as Ref<EditUserType>,
      confirmUser: mypagePageStore.confirmUser,
      confirmUserOneTimePassInfo: mypagePageStore.confirmUserOneTimePassInfo,
      mypagePageStoreFetchUser: mypagePageStore.fetchUser,
      saveUser: mypagePageStore.saveUser,
      ownOrganization: mypagePageStore.ownOrganization,
      fetchOrganizations: mypagePageStore.fetchOrganizations,
      updateOrganization: mypagePageStore.updateOrganization,
      // OneTimePassPageStore
      oneTimePassPageStore,
      // useContract
      createContract,
      // useHistory
      saveMissionHistoryForProfile,
      // useLogin
      _login,
      // useOneTimePassContract
      createOneTimePassContract,
      // useContractPlan
      getCouponPlanWithCouponDocument,
      // useOneTimePassErrorHandling
      createContractError,
      // usePasswordResetToken
      checkEmailAddressDuplicated,
    }
  },
  methods: {
    /**
     * 入力した会員情報をストアに保存する
     */
    setEditUserInfo(editUserData: EditUserType) {
      this.editUserInfo = editUserData
    },
    /**
     * 入力したメールアドレスが登録済みか判定する
     */
    async checkExistMailAddress(loader: LoaderComponent) {
      const result = await this.checkEmailAddressDuplicated(this.email)

      if (!result?.isSuccess && result.response?.data?.error_code === '409_0000') {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.AppSignupTopPage.errors.duplicateEmail.title'),
          message: this.$tc('SignupPage.AppSignupTopPage.errors.duplicateEmail.message'),
          errorApiResponse: result?.response,
        })
        return { isSuccess: false }
      }

      return { isSuccess: true }
    },
    /**
     * 新規会員登録処理
     */
    async createUser(password: string, loader: LoaderComponent) {
      let registerUserResult

      if (this.method === 'MAIL_ADDRESS') {
        // 登録方法がメールの場合
        // ユーザー登録処理
        registerUserResult = await this.signupPageStore.registerUser(this.tokenId, password)
      } else if (this.method === 'GOOGLE' || this.method === 'APPLE') {
        // 登録方法が外部アカウント連携の場合
        let externalLinkUserId = ''
        if (this.method === 'GOOGLE') {
          externalLinkUserId = this.signupPageStore.googleUserInfo.value.googleId ?? ''
        } else if (this.method === 'APPLE') {
          externalLinkUserId = this.signupPageStore.appleUserInfo.value?.user ?? ''
        }

        // ユーザー登録処理
        registerUserResult = await this.signupPageStore.registerUserWithExternalLink(
          this.tokenId,
          password,
          this.email,
          externalLinkUserId,
          this.method,
        )
      }

      if (!registerUserResult?.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.AppSignupInputPage.errors.appRegisterError.title'),
          message: this.$tc('SignupPage.AppSignupInputPage.errors.appRegisterError.message'),
          errorApiResponse: registerUserResult?.response,
        })
        return { isSuccess: false }
      }

      return { isSuccess: true }
    },
    /**
     * 入力された会員情を登録する処理
     */
    async saveUserInfo(editUserInfo: EditUserType, loader: LoaderComponent) {
      // ログインユーザー情報を取得
      await this.mypagePageStoreFetchUser(this.loginStore.loginId)

      /** 会員情報保存 */
      this.confirmUser(
        editUserInfo.familyName ?? '',
        editUserInfo.firstName ?? '',
        '',
        '',
        '',
        editUserInfo.birthDay,
        '',
        editUserInfo.country as string,
        '',
        '',
      )

      if (this.inputtedOneTimePass && this.isOneTimePassWithNoPlan) {
        // プラン登録なしのワンタイムパスの場合、ワンタイムパス情報をユーザー情報に持たせる
        this.confirmUserOneTimePassInfo(this.inputtedOneTimePass.couponCode ?? '')
      }

      const saveUserResult = await this.saveUser()
      if (!saveUserResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.AppSignupInputPage.errors.appSaveUserError.title'),
          message: this.$tc('SignupPage.AppSignupInputPage.errors.appSaveUserError.message'),
          errorApiResponse: saveUserResult?.response,
        })
        return { isSuccess: false }
      }

      if (saveUserResult.data) {
        // プロフィール登録ミッションの操作ログを登録
        this.saveMissionHistoryForProfile(saveUserResult.data)
      }

      /** 組織情報更新 */
      await this.fetchOrganizations()
      const newOrgData = this.cloneDeep(this.ownOrganization) as OrganizationDocument
      // フォームに入力した姓名をorganizationNameにセットする
      newOrgData.organizationName = `${editUserInfo.familyName} ${editUserInfo.firstName}`
      const updateOrganizationResult = await this.updateOrganization(newOrgData)
      if (!updateOrganizationResult.isSuccess) {
        // 更新に失敗したとしても、ユーザー情報編集時にorganizationNameを更新できるため、ここではエラーメッセージを表示しない
        return { isSuccess: false }
      }

      return { isSuccess: true }
    },
    /**
     * 契約情報契約プラン登録処理
     */
    async createContractPlan(loader: LoaderComponent) {
      /** 契約情報契約プランを登録 */
      if (this.inputtedOneTimePass && !this.isOneTimePassWithNoPlan) {
        /**
         * ケース1. プラン登録ありのワンタイムパスの場合の処理
         */
        Logger.info(
          `AppSignupInputPane#createContractPlan: createContractPlan with one-time pass. One-time pass code: ${this.inputtedOneTimePass.couponCode}`,
        )

        // 登録対象のワンタイムパス情報をストアに保存する
        this.oneTimePassPageStore.setCoupon(this.inputtedOneTimePass)

        // ワンタイムパスプラン登録処理
        const couponPlan = this.inputtedOneTimePass.plans
          ? this.getCouponPlanWithCouponDocument(this.inputtedOneTimePass.plans)
          : undefined
        if (!couponPlan) {
          loader.hide()
          await MessageDialogStore.value.open({
            title: this.$tc('SignupPage.AppSignupInputPage.errors.createContractError.title'),
            message: this.$tc('SignupPage.AppSignupInputPage.errors.createContractError.message'),
          })
          return { isSuccess: false }
        }

        /**
         * 1. 以下の処理を行う
         * - クーポンプランの開始日時が将来の場合、先に無料プランを登録する
         * - 上記以外の場合、契約情報更新処理までを行う
         */
        const isFutureStartCouponPlan =
          couponPlan?.startDate && couponPlan.startDate > AdjustTime.getAdjustedCurrentTime()
        const createContractResult = isFutureStartCouponPlan
          ? await this.createContract('freePlan')
          : await this.createContract(null)

        if (!createContractResult.isSuccess) {
          loader.hide()
          await MessageDialogStore.value.open({
            title: this.$tc('SignupPage.AppSignupInputPage.errors.createContractError.title'),
            message: this.$tc('SignupPage.AppSignupInputPage.errors.createContractError.message'),
            errorApiResponse: createContractResult?.response,
          })
          return createContractResult
        }

        /** 2. ワンタイムパスプランを登録 */
        await this.oneTimePassPageStore.fetchContractInfo()
        const { contractInfoId } = this.oneTimePassPageStore.ownContractInfo.value
        const createOneTimePassContractResult = await this.createOneTimePassContract(contractInfoId)
        if (!createOneTimePassContractResult.isSuccess) {
          loader.hide()
          await this.createContractError(
            createOneTimePassContractResult.response?.data,
            'AppSignup',
          )
          return createOneTimePassContractResult
        }

        return createOneTimePassContractResult
      }

      /**
       * ケース2. 無料プラン登録処理（「ケース1. プラン登録ありのワンタイムパスの場合の処理」以外の場合にこちらの処理を実施する）
       */
      Logger.info(
        `AppSignupInputPane#createContractPlan: createContractPlan with free plan. isOneTimePassWithNoPlan: ${this.isOneTimePassWithNoPlan}`,
      )
      return this.createContract('freePlan')
    },
    /**
     * 以下の処理を行う
     * - メールアドレス重複チェック
     * - 新規会員登録
     * - 入力された会員情を登録
     * - 契約情報契約プランを登録
     */
    async handlerSubmit(password: string, editUserData: EditUserType) {
      this.state.isRegistering = true
      const loader = this.$loading.show()

      /** メールアドレス重複チェック */
      const checkExistMailAddressResult = await this.checkExistMailAddress(loader)
      if (!checkExistMailAddressResult.isSuccess) {
        this.state.isRegistering = false

        this.$router.replace({ path: '/login' })
        return
      }

      /** 新規会員登録 */
      const createUserResult = await this.createUser(password, loader)
      if (!createUserResult.isSuccess) {
        this.state.isRegistering = false

        this.$router.replace({ path: '/login' })
        return
      }

      /** ログイン情報をSecureKeyStoreに保存する */
      SecureKeyStore.setItem('sfgoUserCredential', { loginId: this.email, password })

      /** 登録したユーザーでログイン */
      await this._login(this.email, password)

      /** 入力された会員情を登録 */
      await this.saveUserInfo(editUserData, loader)

      /**
       * 契約情報契約プランを登録
       * 契約情報が存在しない場合、ログインのタイミングで無料プランを登録するため、ここでプラン登録が失敗したとしてもエラーメッセージは出さない
       */
      await this.createContractPlan(loader)

      loader.hide()
      this.state.isRegistering = false

      // 会員登録完了画面に遷移する
      this.$router.replace({
        path: '/signup/complete',
      })
    },
  },
})
