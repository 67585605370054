var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "8",
        height: "8",
        viewBox: "0 0 8 8",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.666687 3.66699L7.33335 3.66699V4.33366L0.666687 4.33366L0.666687 3.66699Z",
          fill: "#2B2B2B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }