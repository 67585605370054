import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import { RegistMethodType } from '@/store/stores/collectionModule/documents/user/UserRegisterTokenDocument'
import Const from '@/util/Const'
import router from '@/router'
import useSignInWithApple from '@/components/hook/apple/useSignInWithApple'
import useCheckEmailAddress from '@/components/AppSignupPage/hook/useCheckEmailAddress'

/**
 * アプリ内会員登録: Sign in with apple処理時のフローを提供する
 */
export default function useAppleSignInFlow() {
  const signupPageStore = StoreUtil.useStore('SignupPageStore')
  const { isAppleSignInError, appleSignIn } = useSignInWithApple()
  const { emailAddressDuplicateCheckProcess } = useCheckEmailAddress()

  /**
   * サインインを実施するし、処理結果を制御する
   */
  const appleOauthFlow = async () => {
    const method: RegistMethodType = 'APPLE'

    // Apple認可を行い、認可したユーザーの情報を取得する
    const response = await appleSignIn()
    if (isAppleSignInError(response)) {
      if (response.code === '1001') {
        // 認可キャンセル時に返ってくるエラーコードの場合は何もしない
        return
      }
      // 認可キャンセル時に返ってくるエラーコード以外の場合はエラーダイアログを表示する
      await MessageDialogStore.value.open({
        title: I18n.tc('SignupPage.AppSignupTopPage.errors.getSnsUserInfoError.title'),
        message: I18n.t('SignupPage.AppSignupTopPage.errors.getSnsUserInfoError.message', {
          snsType: 'Apple',
          errorCode: '32-021',
        }).toString(),
      })
      return
    }

    if (!response.email) {
      await MessageDialogStore.value.open({
        title: I18n.tc('SignupPage.AppSignupTopPage.errors.getAppleEmailError.title'),
        message: I18n.t('SignupPage.AppSignupTopPage.errors.getAppleEmailError.message', {
          url:
            I18n.locale === 'ja'
              ? Const.EXTERNAL_LINKS.OAUTH2.SIGN_IN.IOS.JA
              : Const.EXTERNAL_LINKS.OAUTH2.SIGN_IN.IOS.EN,
        }).toString(),
      })
      return
    }

    if (response.email.endsWith('@privaterelay.appleid.com')) {
      // 「メールを非公開」にして会員登録をしようとした際に、エラーを表示して処理を抜ける
      await MessageDialogStore.value.open({
        title: I18n.tc('SignupPage.AppSignupTopPage.errors.getApplePrivateEmailError.title'),
        message: I18n.t('SignupPage.AppSignupTopPage.errors.getApplePrivateEmailError.message', {
          url:
            I18n.locale === 'ja'
              ? Const.EXTERNAL_LINKS.OAUTH2.SIGN_IN.IOS.JA
              : Const.EXTERNAL_LINKS.OAUTH2.SIGN_IN.IOS.EN,
        }).toString(),
      })
      return
    }

    // Apple認可したユーザーの情報をストアにセットする
    signupPageStore.appleUserInfo.value = response

    // メールアドレス重複チェックを実施する
    const emailCheckResult = await emailAddressDuplicateCheckProcess(method)
    if (!emailCheckResult) {
      // 呼び出し先でエラーモーダルを表示しているため、ここでは何もせず処理を終了する
      return
    }

    // 会員情報入力ページに遷移
    router.replace({ name: 'AppSignupInputPage', query: { method } })
  }

  return {
    appleOauthFlow,
  }
}
