var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signup-complete-pane" },
    [
      _c("signup-header-section", {
        attrs: { steps: 0, pageTitle: _vm.$tc("SignupPage.Steps.complete") },
      }),
      _c(
        "div",
        { staticClass: "signup-complete-pane__main" },
        [
          _c("signup-complete-section", {
            attrs: {
              isCouponUsed: _vm.isCouponUsed,
              attentionMessage: _vm.attentionMessage,
              paymentSchedule: _vm.paymentSchedule,
            },
            on: { onMoveToLogin: _vm.onMoveToLogin },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }