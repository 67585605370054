var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mypage-point-expiration-date-table-section" },
    [
      _c(
        "table",
        { staticClass: "mypage-point-expiration-date-table-section__table" },
        [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$tc("MypagePage.MypagePointExpirationDatePage.point")
                  )
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$tc(
                      "MypagePage.MypagePointExpirationDatePage.expirationDate"
                    )
                  )
                ),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            _vm._l(_vm.dummyData, function (item) {
              return _c("tr", { key: item.id }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "mypage-point-expiration-date-table-section__point",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-point-expiration-date-table-section__point-data",
                      },
                      [_vm._v(_vm._s(item.point.toLocaleString()))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "mypage-point-expiration-date-table-section__point-unit",
                      },
                      [_vm._v("P")]
                    ),
                  ]
                ),
                _c(
                  "td",
                  {
                    staticClass:
                      "mypage-point-expiration-date-table-section__date",
                  },
                  [_vm._v(" " + _vm._s(item.expirationDate) + " ")]
                ),
              ])
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }