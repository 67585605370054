

































import { computed, defineComponent, PropType } from '@vue/composition-api'
import CommonOverlaySection from '@/components/common/overlay/CommonOverlaySection.vue'
import OverlayTitleParts from '@/components/common/overlay/parts/OverlayTitleParts.vue'
import OverlayMessageParts from '@/components/common/overlay/parts/OverlayMessageParts.vue'
import OverlayDeclineMessageParts from '@/components/common/overlay/parts/OverlayDeclineMessageParts.vue'
import AtomIconLinkButton from '@/components/atoms/AtomIconLinkButton.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import { PurchasePaymentMethodType } from '@/store/stores/pageStore/common/ContractInfoStore'
import Const from '@/util/Const'
import I18n from '@/locales/I18n'
import { DisplayDeclineInfoType } from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'

/**
 * HOME リードオンリープラン状態確認モーダル
 */
export default defineComponent({
  name: 'ReadOnlyAlertModalSection',
  components: {
    OverlayDeclineMessageParts,
    CommonOverlaySection,
    OverlayTitleParts,
    OverlayMessageParts,
    AtomIconLinkButton,
    AtomInputButton,
  },
  props: {
    /**
     * 失敗した決済方法
     */
    failedPaymentMethod: {
      type: String as PropType<PurchasePaymentMethodType>,
      default: 'CreditCard',
    },
    /**
     * 決済失敗情報
     */
    displayDeclineInfo: {
      type: Object as PropType<DisplayDeclineInfoType>,
      default: null,
    },
  },
  setup(props) {
    // タイトル
    const alertTitle = computed(() =>
      props.failedPaymentMethod === 'CreditCard'
        ? I18n.tc('MypagePage.ReadOnlyAlertModal.title')
        : I18n.tc('MypagePage.ReadOnlyAlertModalInAppPurchase.title'),
    )
    // 本文
    const alertMessage = computed(() =>
      props.failedPaymentMethod === 'CreditCard'
        ? I18n.tc('MypagePage.ReadOnlyAlertModal.message')
        : I18n.tc('MypagePage.ReadOnlyAlertModalInAppPurchase.message'),
    )
    // リンクボタンテキスト
    const linkText = computed(() =>
      props.failedPaymentMethod === 'CreditCard'
        ? I18n.tc('MypagePage.ReadOnlyAlertModal.submitText')
        : I18n.tc('MypagePage.ReadOnlyAlertModalInAppPurchase.submitText'),
    )
    // 支払い方法についてのリンク先
    const paymentUrl = computed(() => {
      if (props.failedPaymentMethod === 'AppStore') {
        return Const.EXTERNAL_LINKS.IN_APP_PURCHASE.BILLING.IOS
      }
      if (props.failedPaymentMethod === 'GooglePlay') {
        return Const.EXTERNAL_LINKS.IN_APP_PURCHASE.BILLING.ANDROID
      }
      return `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/payment`
    })

    return {
      alertTitle,
      alertMessage,
      linkText,
      paymentUrl,
    }
  },
  methods: {
    /**
     * closeModal
     * 親コンポーネントへ閉じる処理
     */
    emitClose(): void {
      this.$emit('close')
    },
  },
})
