import { computed, reactive } from '@vue/composition-api'
import { orderBy, uniq } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useMissionProgress from '@/store/hook/mission/useMissionProgress'
import useAvailableArea from '@/store/hook/useAvailableArea'
import useGeolocation from '@/components/hook/useGeolocation'
import Logger from '@/util/logger/Logger'
import useErrorHandling from '@/components/hook/useErrorHandling'
import usePointRankingData from '@/store/hook/usePointRankingData'
import useUserRetrieveName from '@/store/hook/useUserRetrieveName'
import {
  RankInUserType,
  PointRankingCategoryType,
} from '@/store/stores/collectionModule/documents/pointRanking/PointRankingDataDocument'
import { PointRankingKeyType } from '@/store/stores/collectionModule/documents/organization/OrganizationDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import I18n from '@/locales/I18n'
import useHistory from '@/store/hook/useHistory'
import AvailableAreaDocument from '@/store/stores/collectionModule/documents/availableArea/AvailableAreaDocument'
import { SaveResponse } from '@/store/stores/collectionModule/CollectionTypes'
import MissionHistoryDocument from '@/store/stores/collectionModule/documents/history/MissionHistoryDocument'
import useDisplayDependingOnLang from '@/components/hook/useDisplayDependingOnLang'
import { ROUND_CHECK_IN_MISSION_CODE_LIST } from '@/util/Const'
import useUserImageUpload from '@/store/hook/useUserImageUpload'
import UserImageUploadDocument from '@/store/stores/collectionModule/documents/userImageUpload/UserImageUploadDocument'
import IndexedDBAccessor from '@/store/stores/IndexedDBstore/IndexedDBAccessor'
import { LocationInfoType } from '@/store/stores/collectionModule/documents/GeneralTypes'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

type PointRankingKey =
  | 'TOTAL_POINT_RANKING'
  | `${number}_SEASON_POINT_RANKING`
  | `${number}_MONTHLY_POINT_RANKING`

export type RankingType = 'TOTAL' | 'SEASON' | 'MONTHLY'

export type RankingImageInfoType = {
  rankingType: RankingType
  year: number
  month: number
  startRanking: number
  endRanking: number
  imagePath: string
}

export type RankingUserImageDataType = {
  TOTAL: Array<RankingImageInfoType>
  SEASON: Record<number, Array<RankingImageInfoType>>
  MONTHLY: Record<number, Array<RankingImageInfoType>>
}

export type ShareRankingImageType = {
  displayName: string
  point: number
  ranking: {
    key: PointRankingKey
    type: RankingType
    rank: number
    title: string
    headline: string
    mothDate?: string
  }
}

// ポイントランキングを表示用の型
export type PointRankingType = {
  // ランキングのタイプ
  rankingType: RankingType

  // ランキングの表示順
  order: number

  // ランキングの種類
  category: PointRankingKeyType

  // ユニークにするためのキー
  key: PointRankingKey

  // タイトル
  title: string

  // タイトルの下の見出し文
  summary: string

  // 集計日時
  aggregationPeriod: string | null

  // 集計日時
  aggregationStartDate: number

  // 上位10位のユーザー情報
  rankings: RankInUserType[]

  // 月間の場合の月
  monthlyRankingMonth?: string

  // シーズンの場合の年
  seasonRankingYear?: number

  // 掲載終了日時
  displayEndDate?: string

  // ランキングの画面を開いたときに初期表示するかどうか
  isInitialDisplay: boolean

  postImageSNS: {
    // 投稿画像のヘッドライン
    headline: string

    // 投稿画像のタイトル
    title: string

    // 月間ランキングの年月を表示するための値
    imageMothDate?: string
  }
}

/**
 * 初期状態
 */
const initialState = {
  /**
   * ミッション一覧画面で表示する年度
   * 日本時間で2/1より前の日付の場合、前年度のミッションを表示する
   */
  selectedMissionYear:
    dayjs.tz(`${new Date().getFullYear()}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() >
    dayjs().valueOf()
      ? new Date().getFullYear() - 1
      : new Date().getFullYear(),
  pointRankings: [] as PointRankingType[],
  aggregationDate: '',
  /**
   * チェックイン失敗時に詳細情報を含めるモーダルを表示するかどうか
   */
  isShowCheckInFailedDetailModal: false,
  /**
   * 現在地情報
   */
  currentLocationInfo: null as LocationInfoType | null,
}

type CheckedInAreaType = {
  availableArea: AvailableAreaDocument
  alreadyChecked: boolean
}

/* eslint-disable class-methods-use-this */
/**
 * ミッション画面のStore
 */
class MissionPageStore implements StoreBase {
  createStore() {
    const state = reactive({ ...initialState })

    // hook
    const {
      fetchMissionProgresses,
      fetchPermanentPointMissionProgresses,
      seasonRoundAndEventCheckInProgresses,
      thisSeasonRoundAndEventCheckInProgresses,
      missionProgressesByMissionCode,
      clearMissionProgressCollectionModule,
      clearMissionProgresses,
    } = useMissionProgress()

    const { fetchAvailableAreas, missionAvailableAreas, clearAvailableAreas } = useAvailableArea()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()
    const { saveMissionHistory } = useHistory()

    const { fetchPointRankingData, pointRankingData, clearPointRankingData } = usePointRankingData()

    const { fetchRetrieveNameUsers, retrieveNameUsersByUserId, clearRetrieveNameUsers } =
      useUserRetrieveName()

    const { fetchUserImageList, userImageList, clearUserImageList } = useUserImageUpload()

    const { getDisplayDateJa, getDisplayMonthJa } = useDisplayDependingOnLang()
    const { getDisplayedConfirmMissionRanking } = IndexedDBAccessor()

    /**
     * ミッション一覧画面で表示する年度
     */
    const selectedMissionYear = computed({
      get: () => state.selectedMissionYear,
      set: (year: number) => {
        state.selectedMissionYear = year
      },
    })

    /**
     * チェックイン失敗時に詳細情報を含めるモーダルを表示するかどうか
     */
    const isShowCheckInFailedDetailModal = computed({
      get: () => state.isShowCheckInFailedDetailModal,
      set: (isShow: boolean) => {
        state.isShowCheckInFailedDetailModal = isShow
      },
    })

    /**
     * 現在地情報
     */
    const currentLocationInfo = computed({
      get: () => state.currentLocationInfo,
      set: (location: LocationInfoType | null) => {
        state.currentLocationInfo = location
      },
    })

    /**
     * ミッション画面に必要な情報を取得する。
     */
    const fetchMissionPageData = async (selectedYear: number) => {
      clearMissionProgressCollectionModule()
      await Promise.all([
        fetchMissionProgresses(selectedYear, true),
        fetchPermanentPointMissionProgresses(true),
        fetchAvailableAreas(),
      ])
    }

    /**
     * 開催中または直近で開催予定のラウンドチェックインミッション
     */
    const beingHeldOrNextSeasonRoundCheckInMission = computed(() => {
      const today = dayjs().valueOf()
      return orderBy(seasonRoundAndEventCheckInProgresses.value, 'startDate').find((mission) => {
        const isRoundCheckInMission = (ROUND_CHECK_IN_MISSION_CODE_LIST as Array<string>).includes(
          mission.missionCode || '',
        )
        return isRoundCheckInMission && today <= dayjs.tz(mission.endDate).valueOf()
      })
    })

    /**
     * 開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    const checkInMissionsBeingHeld = computed(() => {
      const today = dayjs().valueOf()
      return seasonRoundAndEventCheckInProgresses.value.filter(
        (mission) =>
          dayjs.tz(mission.startDate).valueOf() <= today &&
          today <= dayjs.tz(mission.endDate).valueOf(),
      )
    })

    /**
     * 今シーズンの開催中のラウンドチェックインまたはイベントチェックインミッション一覧
     */
    const checkInMissionsBeingHeldForThisSeason = computed(() => {
      const today = dayjs().valueOf()
      return thisSeasonRoundAndEventCheckInProgresses.value.filter(
        (mission) =>
          dayjs.tz(mission.startDate).valueOf() <= today &&
          today <= dayjs.tz(mission.endDate).valueOf(),
      )
    })

    /**
     * チェックインの処理
     */
    const checkIn = async () => {
      if (checkInMissionsBeingHeldForThisSeason.value.length === 0) return false

      let positionResult: GeolocationPosition
      try {
        // 現在地を取得
        positionResult = await getCurrentPosition()
        Logger.info(
          `MissionPageStore#checkIn currentGPS: ${positionResult.coords.latitude}, ${positionResult.coords.longitude}`,
        )
      } catch (e) {
        // HACK: ESLint: 'GeolocationPositionError' is not defined.(no-undef)エラーが表示さレてしまい、回避方法が不明なため、ひとまずエラーを無効化している
        // eslint-disable-next-line no-undef
        const positionError = e as GeolocationPositionError
        Logger.info(
          `MissionPageStore#checkIn getCurrentPositionError: code: ${positionError.code}, message: ${positionError.message}`,
        )
        createGeolocationError(positionError)
        return false
      }

      // チェックインできた場所のリスト
      const checkedInAreaList: Array<CheckedInAreaType> = []
      try {
        checkInMissionsBeingHeldForThisSeason.value.forEach((checkInProgress) => {
          const targetAvailableArea = missionAvailableAreas.value.find(
            (availableArea) => checkInProgress.operation?.[0] === availableArea.mission?.operation,
          )

          // ユーザーが利用可能エリア内にいるかを判定する
          if (targetAvailableArea && checkIsUserInArea(positionResult, targetAvailableArea)) {
            const checkedInArea: CheckedInAreaType = {
              availableArea: targetAvailableArea,
              alreadyChecked:
                checkInProgress.achievementCondition?.some((condition) => condition.isAchieved) ??
                false,
            }
            checkedInAreaList.push(checkedInArea)
          }
        })
      } catch (e) {
        MessageDialogStore.value.open({
          title: I18n.tc('common.errors.pointInPolygonError.title'),
          message: I18n.tc('common.errors.pointInPolygonError.message'),
        })
        return false
      }

      if (checkedInAreaList.length === 0) {
        // エラーモーダルを表示
        isShowCheckInFailedDetailModal.value = true
        // 現在地情報をセット
        currentLocationInfo.value = {
          lat: positionResult.coords.latitude,
          lng: positionResult.coords.longitude,
        }
        return false
      }

      if (checkedInAreaList.every((checkedInArea) => checkedInArea.alreadyChecked)) {
        MessageDialogStore.value.open({
          title: I18n.tc('MissionPage.checkIn.errors.alreadyCheckedIn.title'),
          message: I18n.tc('MissionPage.checkIn.errors.alreadyCheckedIn.message'),
        })
        return false
      }

      const promises: Array<Promise<SaveResponse<MissionHistoryDocument>>> = []
      checkedInAreaList.forEach((checkedInArea) => {
        if (checkedInArea.availableArea.mission?.operation) {
          // チェックインが成功した場所の操作ログを登録する
          // - シーズンランラウンドチェックイン、サーキットチェックイン
          // - イベントチェックイン
          promises.push(
            saveMissionHistory(
              checkedInArea.availableArea.id || '',
              'master_stadium_id',
              checkedInArea.availableArea.mission.operation,
            ),
          )
        }
      })
      await Promise.all(promises)
      return true
    }

    /**
     * ミッション画面のデータをクリアする
     */
    const clearMissionPageData = () => {
      clearMissionProgresses()
      clearAvailableAreas()
      clearRetrieveNameUsers()
      clearPointRankingData()
      clearUserImageList()
      Object.assign(state, initialState)
    }

    /**
     * カテゴリーごとのポイントランキング表示に必要なデータ
     */
    const pointRankings = computed({
      get: (): Array<PointRankingType> => state.pointRankings,
      set: (value: Array<PointRankingType>) => {
        state.pointRankings = value
      },
    })

    /**
     * ランキングの集計日時
     */
    const aggregationDate = computed({
      get: (): string => state.aggregationDate,
      set: (value: string) => {
        state.aggregationDate = value
      },
    })

    const getRankingDisplayInfo = async (
      category: PointRankingCategoryType,
      argAggregationDate: number,
      aggregationStartDate: number,
      aggregationEndDate: number,
      rankings: RankInUserType[],
    ): Promise<PointRankingType | undefined> => {
      const startDate = getDisplayDateJa(aggregationStartDate)
      const endDate = getDisplayDateJa(aggregationEndDate)
      const aggregationPeriod = `${startDate} ~ ${endDate}`

      if (category === 'TOTAL_POINT_RANKING') {
        return {
          rankingType: 'TOTAL',
          order: 1,
          category: 'totalPointRanking',
          key: 'TOTAL_POINT_RANKING',
          title: I18n.tc('MissionPage.RankingPage.total.title'),
          summary: I18n.tc('MissionPage.RankingPage.season.summary'),
          aggregationPeriod: null,
          rankings,
          aggregationStartDate,
          postImageSNS: {
            headline: I18n.tc('MissionPage.RankingPage.total.subTitle'),
            title: I18n.tc('MissionPage.RankingPage.total.title'),
          },
          isInitialDisplay: false,
        }
      }

      if (category === 'SEASON_POINT_RANKING') {
        // 日本時間で2/1より前の日付の場合、前年度を指定する
        const year =
          dayjs.tz(`${new Date().getFullYear()}-02-01`, 'Asia/Tokyo').startOf('day').valueOf() >
          dayjs().valueOf()
            ? new Date().getFullYear() - 1
            : new Date().getFullYear()

        // 集計開始年
        const aggregationStartYear = dayjs(aggregationStartDate).tz('Asia/Tokyo').year()

        // 今シーズンのランキング
        if (aggregationStartYear === year) {
          return {
            rankingType: 'SEASON',
            order: 2,
            category: 'seasonPointRanking',
            key: `${aggregationStartYear}_SEASON_POINT_RANKING`,
            title: `${I18n.t('MissionPage.RankingPage.season.title', {
              seasonYear: aggregationStartYear,
            })}`,
            summary: I18n.tc('MissionPage.RankingPage.season.summary'),
            aggregationPeriod,
            rankings,
            aggregationStartDate,
            seasonRankingYear: aggregationStartYear,
            postImageSNS: {
              headline: String(aggregationStartYear),
              title: `${I18n.t('MissionPage.RankingPage.season.title', {
                seasonYear: '',
              })}`.trim(),
            },
            isInitialDisplay: false,
          }
        }
        // 昨シーズンのランキング
        const displayEndDate = getDisplayDateJa(dayjs(aggregationEndDate).add(1, 'year').valueOf())
        const targetDisplayedConfirmSeason = dayjs(aggregationStartDate)
          .tz('Asia/Tokyo')
          .format('YYYY')
        const displayedConfirmSeason = await getDisplayedConfirmMissionRanking(
          'DISPLAYED_CONFIRM_SEASON_RANKING',
        )
        return {
          rankingType: 'SEASON',
          order: 5,
          category: 'seasonPointRanking',
          key: `${aggregationStartYear}_SEASON_POINT_RANKING`,
          title: `${I18n.t('MissionPage.RankingPage.season.title', {
            seasonYear: aggregationStartYear,
          })}`,
          summary: I18n.tc('MissionPage.RankingPage.season.summary'),
          aggregationPeriod,
          rankings,
          aggregationStartDate,
          seasonRankingYear: aggregationStartYear,
          displayEndDate,
          postImageSNS: {
            headline: String(aggregationStartYear),
            title: `${I18n.t('MissionPage.RankingPage.season.title', {
              seasonYear: '',
            })}`.trim(),
          },
          isInitialDisplay:
            argAggregationDate > aggregationEndDate &&
            (!displayedConfirmSeason ||
              Number(targetDisplayedConfirmSeason) > displayedConfirmSeason),
        }
      }

      if (category === 'MONTHLY_POINT_RANKING') {
        // 今月のランキング
        if (
          dayjs(aggregationStartDate).tz('Asia/Tokyo').month() === dayjs().tz('Asia/Tokyo').month()
        ) {
          const displayMonth = getDisplayMonthJa(aggregationStartDate, 'M月')
          return {
            rankingType: 'MONTHLY',
            order: 3,
            category: 'monthlyPointRanking',
            key: `${
              dayjs(aggregationStartDate).tz('Asia/Tokyo').month() + 1
            }_MONTHLY_POINT_RANKING`,
            title: `${I18n.t('MissionPage.RankingPage.monthly.title', {
              monthly: displayMonth,
            })}`,
            summary: I18n.tc('MissionPage.RankingPage.season.summary'),
            aggregationPeriod,
            rankings,
            aggregationStartDate,
            monthlyRankingMonth: dayjs(aggregationStartDate).tz('Asia/Tokyo').format('YYYY_MM'),
            postImageSNS: {
              headline: dayjs(aggregationStartDate).format('MMMM'),
              title: `${I18n.t('MissionPage.RankingPage.monthly.title', {
                monthly: '',
              })}`.trim(),
              imageMothDate: getDisplayDateJa(aggregationStartDate, 'YYYY / MM', 'MMM, YYYY'),
            },
            isInitialDisplay: false,
          }
        }
        // 先月のランキング
        const displayMonth = getDisplayMonthJa(aggregationStartDate, 'M月')
        const displayEndDate = getDisplayDateJa(
          dayjs(aggregationEndDate).add(1, 'month').endOf('month').valueOf(),
        )

        const targetDisplayedConfirmMonthly = dayjs(aggregationStartDate)
          .tz('Asia/Tokyo')
          .format('YYYYMM')
        const displayedConfirmMonthly = await getDisplayedConfirmMissionRanking(
          'DISPLAYED_CONFIRM_MONTHLY_RANKING',
        )
        return {
          rankingType: 'MONTHLY',
          order: 4,
          category: 'monthlyPointRanking',
          key: `${dayjs(aggregationStartDate).tz('Asia/Tokyo').month() + 1}_MONTHLY_POINT_RANKING`,
          title: `${I18n.t('MissionPage.RankingPage.monthly.title', {
            monthly: displayMonth,
          })}`,
          summary: I18n.tc('MissionPage.RankingPage.season.summary'),
          aggregationPeriod,
          aggregationStartDate,
          rankings,
          monthlyRankingMonth: dayjs(aggregationStartDate).tz('Asia/Tokyo').format('YYYY_MM'),
          displayEndDate,
          postImageSNS: {
            headline: dayjs(aggregationStartDate).format('MMMM'),
            title: `${I18n.t('MissionPage.RankingPage.monthly.title', {
              monthly: '',
            })}`.trim(),
            imageMothDate: getDisplayDateJa(aggregationStartDate, 'YYYY / MM', 'MMM, YYYY'),
          },
          isInitialDisplay:
            argAggregationDate > aggregationEndDate &&
            (!displayedConfirmMonthly ||
              Number(targetDisplayedConfirmMonthly) > displayedConfirmMonthly),
        }
      }
      return undefined
    }

    /**
     * ユーザー画像データのtypeから設定用データに変換したオブジェクトを返す
     */
    const convertUserImageTypeObject = (userImageUpload: UserImageUploadDocument) => {
      const typeKeys = userImageUpload.type?.split('_')

      if (!typeKeys) return {} as RankingImageInfoType

      const rankingPostedImageItem = {
        rankingType: typeKeys[1],
      } as RankingImageInfoType

      rankingPostedImageItem.imagePath = userImageUpload.path || ''

      if (rankingPostedImageItem.rankingType === 'TOTAL') {
        rankingPostedImageItem.year = 0
        rankingPostedImageItem.month = 0
        rankingPostedImageItem.startRanking = Number(typeKeys[2])
        rankingPostedImageItem.endRanking = Number(typeKeys[3])
      } else if (rankingPostedImageItem.rankingType === 'SEASON') {
        rankingPostedImageItem.year = Number(typeKeys[2])
        rankingPostedImageItem.month = 0
        rankingPostedImageItem.startRanking = Number(typeKeys[3])
        rankingPostedImageItem.endRanking = Number(typeKeys[4])
      } else if (rankingPostedImageItem.rankingType === 'MONTHLY') {
        rankingPostedImageItem.year = Number(typeKeys[2])
        rankingPostedImageItem.month = Number(typeKeys[3])
        rankingPostedImageItem.startRanking = Number(typeKeys[4])
        rankingPostedImageItem.endRanking = Number(typeKeys[5])
      }
      return rankingPostedImageItem
    }

    /**
     * ユーザー画像データを各タイプごとにまとめたデータ
     */
    const rankingUserImageData = computed(() =>
      userImageList.value.reduce((acc, userImage) => {
        const convertedUserImage = convertUserImageTypeObject(userImage)
        if (convertedUserImage.rankingType === 'TOTAL') {
          if (!acc.TOTAL) acc.TOTAL = [] as Array<RankingImageInfoType>
          acc.TOTAL.push(convertedUserImage)
        } else {
          if (!acc[convertedUserImage.rankingType])
            acc[convertedUserImage.rankingType] = {} as Record<number, Array<RankingImageInfoType>>
          const key =
            convertedUserImage.rankingType === 'SEASON'
              ? Number(`${convertedUserImage.year}`)
              : Number(
                  `${convertedUserImage.year}${String(convertedUserImage.month).padStart(2, '0')}`,
                )
          if (acc[convertedUserImage.rankingType][key]) {
            acc[convertedUserImage.rankingType][key].push(convertedUserImage)
          } else {
            acc[convertedUserImage.rankingType][key] = [convertedUserImage]
          }
        }
        return acc
      }, {} as RankingUserImageDataType),
    )

    /**
     * ポイントランキングデータを取得する
     */
    const fetchPointRankingPageData = async () => {
      fetchUserImageList('^RANKING_')

      await fetchPointRankingData()

      const arr: Array<PointRankingType> = []
      let userIds: string[] = []

      // 集計日時をセット
      // 01:00(JST)にバッチが動いて集計処理をしているが、ポイントの集計範囲は前日の00:00-23:59となるため、00:00を表示する
      const aggregationStartOfDay = dayjs(pointRankingData.value[0].aggregationDate)
        .tz('Asia/Tokyo')
        .startOf('day')
        .valueOf()
      aggregationDate.value = getDisplayDateJa(
        aggregationStartOfDay,
        'YYYY/MM/DD HH:mm',
        'MMM DD, YYYY HH:mm',
      )

      const sortedPointRankingData = orderBy(pointRankingData.value, 'aggregationStartDate', 'desc')

      // eslint-disable-next-line no-restricted-syntax
      for (const ranking of sortedPointRankingData) {
        if (ranking.category) {
          // eslint-disable-next-line no-await-in-loop
          const rankingDisplayInfo = await getRankingDisplayInfo(
            ranking.category,
            ranking.aggregationDate,
            ranking.aggregationStartDate,
            ranking.aggregationEndDate,
            ranking.rankings?.slice(0, 10),
          )

          if (!rankingDisplayInfo) return
          arr.push(rankingDisplayInfo)

          const ids = ranking.rankings?.slice(0, 10).map((rank) => rank.userId)
          userIds = [...userIds, ...ids]
        }
      }

      pointRankings.value = orderBy(arr, 'order', 'asc')

      // 自分のIDも含める
      const ownUserId = LoginStore.value.userId || ''

      const filteredUserIds = uniq([...userIds, ownUserId])
      await fetchRetrieveNameUsers(filteredUserIds)
    }

    return {
      // useAvailableArea
      missionAvailableAreas,
      // useMissionProgress
      missionProgressesByMissionCode,
      // Store
      selectedMissionYear,
      isShowCheckInFailedDetailModal,
      currentLocationInfo,
      fetchMissionPageData,
      checkIn,
      checkInMissionsBeingHeld,
      checkInMissionsBeingHeldForThisSeason,
      beingHeldOrNextSeasonRoundCheckInMission,
      clearMissionPageData,
      fetchPointRankingPageData,
      pointRankings,
      aggregationDate,
      retrieveNameUsersByUserId,
      userImageList,
      rankingUserImageData,
    }
  }
}

const value: ValueType<MissionPageStore> = {}

export default {
  createStore: new MissionPageStore().createStore,
  value: value as Required<typeof value>,
}
