



























































import VueRouter from 'vue-router'
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  inject,
  WritableComputedRef,
  reactive,
  Ref,
} from '@vue/composition-api'
import MypageLinksSection from '@/components/MypageTopPage/MypageTopPane/MypageLinksSection.vue'
import MypageFavoritePlayerLinkSection from '@/components/MypageTopPage/MypageTopPane/MypageFavoritePlayerLinkSection.vue'
import MypageFavoriteTeamLinkSection from '@/components/MypageTopPage/MypageTopPane/MypageFavoriteTeamLinkSection.vue'
import ReadOnlyAlertModalSection from '@/components/MypageTopPage/MypageTopPane/ReadOnlyAlertModalSection.vue'
import PaymentPendingAlertModalSection from '@/components/MypageTopPage/MypageTopPane/PaymentPendingAlertModalSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import MypagePageStore from '@/store/stores/pageStore/MypagePage/MypagePageStore'
import PlayerDocument from '@/store/stores/collectionModule/documents/player/PlayerDocument'
import CloudFrontUtil from '@/util/aws/CloudFrontUtil'
import TeamDocument from '@/store/stores/collectionModule/documents/team/TeamDocument'
import I18n from '@/locales/I18n'
import DeviceInfo from '@/util/DeviceInfo'
import MypagePaidPlanCompleteModalSection from '@/components/MypageTopPage/MypageTopPane/MypagePaidPlanCompleteModalSection.vue'
import MypageChangePaidPlanModalSection from '@/components/MypageTopPage/MypageTopPane/MypageChangePaidPlanModalSection.vue'
import Const from '@/util/Const'
import useInAppPurchase, {
  InAppPurchaseSubscriptionType,
} from '@/components/hook/inAppPurchase/useInAppPurchase'
import { PurchasePaymentMethodType } from '@/store/stores/pageStore/common/ContractInfoStore'

export interface MyPageLinkList {
  name: string
  link: string
  blank: boolean | null
  status: string | null
}
export type DataType = {
  memberInformationLinks: Array<MyPageLinkList>
  otherLinks: Array<MyPageLinkList>
}
/**
 * マイページ: トップ コンテンツペイン
 */
export default defineComponent({
  name: 'MypageTopPane',
  components: {
    MypageChangePaidPlanModalSection,
    MypagePaidPlanCompleteModalSection,
    MypageLinksSection,
    MypageFavoritePlayerLinkSection,
    MypageFavoriteTeamLinkSection,
    ReadOnlyAlertModalSection,
    PaymentPendingAlertModalSection,
  },
  inject: ['router'],
  setup() {
    const router = inject('router') as VueRouter
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const userStore = StoreUtil.useStore('UserStore')
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')

    const {
      isFreePlan,
      isPaidPlan,
      isCouponPlan,
      isTrialPlan,
      isReadOnlyPlan,
      hasNoPlan,
      paymentMethod,
      effectiveContractPlan,
      contractPlans,
    } = contractInfoStore
    const { ownBillingInfo, settlementFailedOwnBillingInfo } = userStore

    const state = reactive({
      paidPlanChangeModal: {
        title: '',
        description: '',
        url: '',
        modalId: '',
      },
      subscriptions: undefined as InAppPurchaseSubscriptionType | undefined,
    })

    /** リードオンリーモーダルを開くかどうか */
    const isOpenReadOnlyModal = ref(false)
    /** 決済未完了モーダルを開くかどうか */
    const isOpenPaymentPendingModal = ref(false)

    /** 有料プラン登録完了モーダルを開くかどうか */
    const isOpenPaidPlanCompleteModal = ref(false)

    /** プラン変更案内モーダルを開くかどうか */
    const isOpenChangePaidPlanModal = ref(false)

    /** 有料プランの種類 */
    const paidPlanType = ref('')

    /** プラン購入/復元完了モーダルのタイトル */
    const paidPlanCompleteModalTitle = ref('')

    /** 失敗した決済方法 */
    const failedPaymentMethod = ref(null) as Ref<PurchasePaymentMethodType | null>

    onMounted(async () => {
      if (isReadOnlyPlan) {
        const result = await mypagePageStore.fetchContractInfo()

        if (!result.isSuccess) {
          await MessageDialogStore.value.open({
            title: I18n.tc('MypagePage.errors.fetchContractInfoError.title'),
            message: I18n.tc('MypagePage.errors.fetchContractInfoError.message'),
            errorApiResponse: result.response,
          })
          // 契約情報を取得できなかった場合はログアウトする
          router.replace({ path: '/logout' })
          return
        }

        const inAppPurchaseInfo = mypagePageStore.ownContractInfo.value.inAppPurchase
        if (inAppPurchaseInfo?.appStore?.status === 'UNDER_CONTRACT') {
          failedPaymentMethod.value = 'AppStore'
        } else if (inAppPurchaseInfo?.googlePlay?.status === 'UNDER_CONTRACT') {
          failedPaymentMethod.value = 'GooglePlay'
        } else {
          failedPaymentMethod.value = 'CreditCard'
        }
        // 決済失敗状態の場合に表示されるモーダルを開く
        isOpenReadOnlyModal.value = isReadOnlyPlan
      }

      if (router.currentRoute.query.modalId === 'completeAnnualPlan') {
        isOpenPaidPlanCompleteModal.value = true
        paidPlanCompleteModalTitle.value = I18n.t('MypagePage.PaidCompleteModal.title', {
          plan: I18n.tc('MypagePage.PaidCompleteModal.annualPlan'),
        }).toString()
      } else if (router.currentRoute.query.modalId === 'competeMonthlyPlan') {
        isOpenPaidPlanCompleteModal.value = true
        paidPlanCompleteModalTitle.value = I18n.t('MypagePage.PaidCompleteModal.title', {
          plan: I18n.tc('MypagePage.PaidCompleteModal.monthlyPlan'),
        }).toString()
      } else if (router.currentRoute.query.modalId === 'completeRestore') {
        isOpenPaidPlanCompleteModal.value = true
        paidPlanCompleteModalTitle.value = I18n.tc('MypagePage.PaidCompleteModal.restoreTitle')
      }

      if (!isOpenReadOnlyModal.value) {
        // リードオンリーではない場合の処理
        /**
         * 決済未完了モーダルを開く
         * リードオンリー状態でかつ決済未完了状態というパターンは存在しないはずだが、このパターンの場合はリードオンリーモーダルを優先的に開く
         */
        isOpenPaymentPendingModal.value = !!ownBillingInfo.value?.isAccountPending
      }
    })

    /**
     * 決済失敗情報
     */
    const displayDeclineInfo = computed(() => {
      const declineCode = settlementFailedOwnBillingInfo.value?.settlementFailureReason?.declineCode
      const declineInfo = settlementFailedOwnBillingInfo.value?.declineInfo
      return {
        declineCode: declineCode ?? '',
        description: declineInfo?.description ?? '',
        nextUserAction: declineInfo?.nextUserAction ?? '',
      }
    })

    /**
     * 会員情報 リンクリスト
     * link: ブラウザとアプリで遷移先を分けている
     * ブラウザの場合はログイン画面を挟まない
     */
    const memberInformationLinks = computed(() => [
      {
        name: I18n.tc('MypagePage.MypageTopPage.memberInformation'),
        link: '/mypage/user-edit',
        isDisplay: true,
        modalId: '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.ticketList'),
        link: '/mypage/ticket-list',
        isDisplay: true,
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.paidMemberShipChange'),
        // TODO: アプリ内課金iOSをリリースする際、Androidのアプリ内課金の動線をなくす（コメント化しているlinkを有効にする）
        link: DeviceInfo.isCordova() ? '/mypage/switch-to-paid-plan' : '/paid-membership/edit',
        // eslint-disable-next-line no-nested-ternary
        // link: DeviceInfo.isAndroid()
        //   ? `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/paid-membership?returnApp=true`
        //   : DeviceInfo.isiOS()
        //     ? '/mypage/switch-to-paid-plan'
        //     : '/paid-membership/edit',
        isDisplay: isFreePlan || hasNoPlan,
        modalId: '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.paidPlanChange'),
        // eslint-disable-next-line no-nested-ternary
        link: DeviceInfo.isAndroid()
          ? `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/paid-plan-change?returnApp=true`
          : DeviceInfo.isiOS()
          ? '/mypage/paid-plan-change/edit-guide'
          : '/paid-plan-change/edit',
        blank: DeviceInfo.isAndroid(),
        isDisplay: !hasNoPlan && (isPaidPlan || isCouponPlan || isTrialPlan),
        modalId:
          paymentMethod === 'AppStore' || paymentMethod === 'GooglePlay' ? 'changePaidPlan' : '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.creditCardChange'),
        // eslint-disable-next-line no-nested-ternary
        link: DeviceInfo.isAndroid()
          ? `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/payment?returnApp=true`
          : DeviceInfo.isiOS()
          ? '/mypage/payment/edit-guide'
          : '/payment/edit',
        blank: DeviceInfo.isAndroid(),
        isDisplay:
          !hasNoPlan &&
          ((isPaidPlan && !(paymentMethod === 'AppStore' || paymentMethod === 'GooglePlay')) ||
            isCouponPlan ||
            isTrialPlan),
        modalId: '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.cancelPaidPlan'),
        // eslint-disable-next-line no-nested-ternary
        link: DeviceInfo.isAndroid()
          ? `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/cancel-paid-plan?returnApp=true`
          : DeviceInfo.isiOS()
          ? '/mypage/cancel-paid-plan/edit-guide'
          : '/cancel-paid-plan/edit',
        blank: DeviceInfo.isAndroid(),
        isDisplay: !hasNoPlan && (isPaidPlan || isCouponPlan || isTrialPlan),
        modalId:
          paymentMethod === 'AppStore' || paymentMethod === 'GooglePlay' ? 'cancelPaidPlan' : '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.OneTimePassRegistration'),
        link: '/mypage/one-time-pass-input',
        isDisplay: isFreePlan,
        modalId: '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.showReferralCode'),
        link: '/mypage/referral-code',
        isDisplay: true,
        modalId: '',
      },
    ])
    /**
     * 契約情報部 リンクリスト
     */
    const contractLinks = [
      {
        name: I18n.tc('MypagePage.MypageTopPage.contractDetails'),
        link: '/mypage/contract',
        isDisplay: true,
        modalId: '',
      },
      {
        name: I18n.tc('MypagePage.MypageTopPage.contractLog'),
        link: '/mypage/contract-log',
        isDisplay: true,
        modalId: '',
      },
    ]
    /** PC向けアプリかどうか */
    const isForPc = DeviceInfo.isForPC()

    /**
     * 選手顔写真のURL
     * @param player 選手情報
     */
    const playerPictureUrl = (player?: PlayerDocument) => {
      if (!player) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(player.playerPicture)
    }
    /**
     * チームロゴ画像のURL
     * @param team チーム情報
     */
    const teamLogoUrl = (team?: TeamDocument) => {
      if (!team) {
        return ''
      }
      return CloudFrontUtil.getSignedUrl(team.teamLogo)
    }
    /**
     * チーム表示名を取得する
     * @param target チーム
     */
    const getTeamName = (target: TeamDocument) => {
      if (target.teamShortName) {
        return target.teamShortName
      }
      return target.teamName
    }

    /**
     * リードオンリー状態で表示されるモーダルを閉じる
     */
    const hideReadOnlyModal = () => {
      isOpenReadOnlyModal.value = false
      // オーバーレイを閉じた場合はログアウト状態にする
      router.replace({ path: '/logout' })
    }

    /**
     * 決済未完了状態で表示されるモーダルを閉じる
     */
    const hidePaymentPendingModal = () => {
      isOpenPaymentPendingModal.value = false
    }

    /**
     * パラメーターを削除する
     */
    const deleteQuery = () => {
      router.push({ query: {} })
    }

    /**
     * 有料プラン登録完了モーダルの閉じるボタンが押下されたときの処理
     */
    const handleCompletePaidPlanModalClose = () => {
      isOpenPaidPlanCompleteModal.value = false
      deleteQuery()

      // 最新の契約情報をでSFgoを使えるよう、ログアウトさせる
      router.replace({ path: '/logout' })
    }

    /**
     * アプリ内課金 プラン変更/解約案内モーダルに表示する情報をセット
     */
    const setChangePaidPlanModalInfo = (modalId: string) => {
      // プラン名
      const annualPlanName = contractPlans.value.find((plan) => plan.planGroupId === 'annualPlan')
        ?.name[I18n.locale]
      const monthlyPlanName = contractPlans.value.find((plan) => plan.planGroupId === 'monthlyPlan')
        ?.name[I18n.locale]
      // プランの料金（ブラウザの場合はストアに接続できないため、アプリの場合のみ表示する）
      const annualPrice = state.subscriptions?.annual?.pricing?.price ?? ''
      const monthlyPrice = state.subscriptions?.monthly?.pricing?.price ?? ''

      if (modalId === 'changePaidPlan') {
        // プラン変更案内画面のテキストをセット
        // 契約中の有料プラン名
        const currentPlanName =
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL
            ? annualPlanName
            : monthlyPlanName
        // 契約していない有料プラン名
        const otherPlanName =
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL
            ? monthlyPlanName
            : annualPlanName
        // 契約していない有料プランの料金（ブラウザの場合はストアに接続できないため、アプリの場合のみ表示する）
        const otherPlanPrice =
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL
            ? monthlyPrice
            : annualPrice

        // モーダルに表示するタイトルをセット
        state.paidPlanChangeModal.title = DeviceInfo.isCordova()
          ? I18n.t('MypagePage.MypageTopPage.InAppPurchaseModal.PaidPlanChange.title.app', {
              plan: otherPlanName,
              price: otherPlanPrice,
            }).toString()
          : I18n.t('MypagePage.MypageTopPage.InAppPurchaseModal.PaidPlanChange.title.web', {
              plan: otherPlanName,
            }).toString()
        // モーダルに表示する説明をセット
        state.paidPlanChangeModal.description =
          paymentMethod === 'AppStore'
            ? I18n.tc('MypagePage.MypageTopPage.InAppPurchaseModal.PaidPlanChange.description.iOS')
            : I18n.t(
                'MypagePage.MypageTopPage.InAppPurchaseModal.PaidPlanChange.description.android',
                {
                  currentPlan: currentPlanName,
                  newPlan: otherPlanName,
                },
              ).toString()
      } else if (modalId === 'cancelPaidPlan') {
        // プラン解約案内画面のテキストをセット
        // プラン名
        const planName =
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL
            ? annualPlanName
            : monthlyPlanName
        // プランの料金（ブラウザの場合はストアに接続できないため、アプリの場合のみ表示する）
        const planPrice =
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.IOS.ANNUAL ||
          effectiveContractPlan?.planGroupId ===
            Const.IN_APP_PURCHASE_SUBSC_PLAN_GROUP_ID.ANDROID.ANNUAL
            ? annualPrice
            : monthlyPrice

        // モーダルに表示するタイトルをセット
        state.paidPlanChangeModal.title = DeviceInfo.isCordova()
          ? I18n.t('MypagePage.MypageTopPage.InAppPurchaseModal.CancelPaidPlan.title.app', {
              plan: planName,
              price: planPrice,
            }).toString()
          : I18n.t('MypagePage.MypageTopPage.InAppPurchaseModal.CancelPaidPlan.title.web', {
              plan: planName,
            }).toString()
        // モーダルに表示する説明をセット
        state.paidPlanChangeModal.description =
          paymentMethod === 'AppStore'
            ? I18n.tc('MypagePage.MypageTopPage.InAppPurchaseModal.CancelPaidPlan.description.iOS')
            : I18n.tc(
                'MypagePage.MypageTopPage.InAppPurchaseModal.CancelPaidPlan.description.android',
              )
      }
      // URL
      state.paidPlanChangeModal.url =
        paymentMethod === 'AppStore'
          ? Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.IOS
          : Const.EXTERNAL_LINKS.IN_APP_PURCHASE.SUBSCRIPTION_MANAGEMENT.ANDROID
    }

    /**
     * 別の画面へ遷移する代わりに、モーダルを表示させる場合の処理
     */
    const handleOpenModalClicked = (modalId: string) => {
      state.paidPlanChangeModal.modalId = modalId
      setChangePaidPlanModalInfo(modalId)

      isOpenChangePaidPlanModal.value = true
    }

    const handleChangePaidPlanModalClose = () => {
      state.paidPlanChangeModal.modalId = ''
      isOpenChangePaidPlanModal.value = false
    }

    return {
      state,
      memberInformationLinks,
      displayDeclineInfo,
      contractLinks,
      isForPc,
      fetchMypagePageData: mypagePageStore.fetchMypagePageData,
      user: mypagePageStore.user,
      clearMypagePageData: mypagePageStore.clearMypagePageData,
      fetchPlayers: mypagePageStore.fetchPlayers,
      players: mypagePageStore.players,
      favoritePlayer: mypagePageStore.favoritePlayer as WritableComputedRef<PlayerDocument>,
      fetchTeams: mypagePageStore.fetchTeams,
      teams: mypagePageStore.teams,
      favoriteTeam: mypagePageStore.favoriteTeam as WritableComputedRef<TeamDocument>,
      paymentMethod: paymentMethod as PurchasePaymentMethodType | null,
      playerPictureUrl,
      teamLogoUrl,
      getTeamName,
      isReadOnlyPlan,
      isOpenReadOnlyModal,
      isOpenPaymentPendingModal,
      hideReadOnlyModal,
      hidePaymentPendingModal,
      isOpenPaidPlanCompleteModal,
      handleCompletePaidPlanModalClose,
      paidPlanType,
      isOpenChangePaidPlanModal,
      failedPaymentMethod,
      handleOpenModalClicked,
      handleChangePaidPlanModalClose,
      paidPlanCompleteModalTitle,
    }
  },
  data() {
    return {
      /**
       * その他 リンクリスト
       */
      otherLinks: [
        {
          name: this.$tc('MypagePage.MypageTopPage.myPageForBrowser'),
          link: `${process.env.VUE_APP_BROWSER_BASE_URL}#/login/mypage`,
          blank: true,
          isDisplay: DeviceInfo.isiOS(),
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.language'),
          link: '/mypage/language',
          isDisplay: true,
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.ecSiteConnect'),
          link: '/mypage/ec-site-connect',
          isDisplay: true,
          isDisabled: MypagePageStore.value.ecConnectStatus.value === 'connected',
          status: MypagePageStore.value.ecConnectStatus,
          subName:
            MypagePageStore.value.ecConnectStatus.value === 'connected' ||
            MypagePageStore.value.ecConnectStatus.value === 'connectedGuestOnly' ||
            MypagePageStore.value.ecConnectStatus.value === 'connectError'
              ? I18n.tc(
                  `MypagePage.MypageTopPage.ecSite.${MypagePageStore.value.ecConnectStatus.value}`,
                )
              : '',
          hasError: MypagePageStore.value.ecConnectStatus.value === 'connectError',
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.setting'),
          link: '/mypage/details-setting',
          isDisplay: true,
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.blockedUserList'),
          link: '/mypage/blocked-user-list',
          // TODO: ブロックしたユーザー一覧対応時にisDisplay: trueとする
          isDisplay: process.env.VUE_APP_INCLUDE_NEXT_RELEASE === 'true',
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.aboutUsing'),
          link: '/mypage/about-using',
          isDisplay: true,
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.contactUs'),
          link: '/mypage/contact-us',
          isDisplay: true,
        },
        {
          name: this.$tc('MypagePage.MypageTopPage.logout'),
          link: '/logout',
          isDisplay: true,
        },
      ],
    }
  },
  async mounted() {
    const loginStore = StoreUtil.useStore('LoginStore')
    this.fetchTargetUserData(loginStore.loginId)
  },
  methods: {
    /**
     * 指定された会員IDのマイページのデータを取得する。
     * @param loginId 会員ID
     * @return 処理完了を待機するためのPromise
     */
    async fetchTargetUserData(loginId: string) {
      this.clearMypagePageData()
      const loader = this.$loading.show()
      const result = await this.fetchMypagePageData(loginId, true)
      if (DeviceInfo.isCordova()) {
        const { sfgoSubscriptions, initInAppPurchase } = useInAppPurchase()
        await initInAppPurchase()
        this.state.subscriptions = sfgoSubscriptions.value
      }
      loader.hide()
      if (!result?.isSuccess) {
        await MessageDialogStore.value.open({
          title: this.$tc('MypagePage.errors.fetchMypagePageDataError.title') as string,
          message: this.$tc('MypagePage.errors.fetchMypagePageDataError.message') as string,
          errorApiResponse: result?.response,
        })
      }
    },
    closeErrorModal() {
      /**
       * 確認画面へ遷移
       * @event success-confirm
       */
      this.$emit('error-move')
    },
  },
})
