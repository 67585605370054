var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-one-time-pass-pane" },
    [
      _c("sub-header-section", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.OneTimePassPage.title"),
          backLink: "/login",
        },
      }),
      _c(
        "div",
        { staticClass: "app-signup-one-time-pass-pane__main" },
        [
          _c("one-time-pass-input-section", {
            attrs: { oneTimePassScreen: "AppSignup" },
            on: { handlerSubmit: _vm.setOneTimePassPlanAndOnSubmit },
          }),
          _c("p", { staticClass: "app-signup-one-time-pass-pane__message" }, [
            _vm._v(
              " " + _vm._s(_vm.$tc("SignupPage.OneTimePassPage.memo")) + " "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }