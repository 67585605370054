import { computed } from '@vue/composition-api'
import { sortBy } from 'lodash'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import BillingInfoDocument, {
  type PaymentStatusErrorType,
} from '@/store/stores/collectionModule/documents/billingInfo/BillingInfoDocument'

/**
 * 支払い情報を操作するための処理を取得する。
 */
export default function useBillingInfo() {
  const billingInfoCollectionModule = CollectionModule.createStore(BillingInfoDocument)

  const paymentStatusErrors: PaymentStatusErrorType[] = [
    'Error',
    'ProcessCallError',
    'Requesting',
    'Cancel',
  ]

  /**
   * 請求ステータスが確定している支払情報を取得する
   */
  const fetchFixedBillingInfo = () =>
    billingInfoCollectionModule.fetch({
      query: {
        filter: {
          billingStatus: 'Fixed',
        },
      },
    })

  /**
   * 請求ステータスがまだ確定していない請求情報を取得する
   */
  const fetchProvisionalBillingInfo = () =>
    billingInfoCollectionModule.fetch({
      query: {
        filter: {
          billingStatus: 'Provisional',
        },
      },
    })

  /**
   * ログインユーザーの請求情報かどうか
   * 決済に失敗したことがある場合、今後一切請求されることはない請求データが billingStatus: 'Provisional' かつ paymentStatus: 'Waiting' のまま残った状態になる。
   * Provisionalの請求情報について、今後一切請求か発生しないかどうかを判別できる項目がないため、Provisionalとなってるデータについては請求日時が現在日時以降となっているデータのみ抽出対象とする。
   */
  const isOwnBillingInfo = (billingInfo: BillingInfoDocument) => {
    if (billingInfo.billingStatus === 'Provisional') {
      return (
        billingInfo.isOwnOrgData &&
        billingInfo?.billingDate &&
        billingInfo.billingDate >= new Date().getTime()
      )
    }
    return billingInfo.isOwnOrgData
  }

  /**
   * ログインユーザーの請求情報を取得
   * アプリ内課金の請求情報は対象外とする
   */
  const ownBillingInfoData = computed(() =>
    billingInfoCollectionModule.data.filter(
      (billingInfo) => isOwnBillingInfo(billingInfo) && !billingInfo.isInAppPurchasePayment,
    ),
  )

  /**
   * ログインユーザーの直近で決済に失敗している請求情報を取得
   */
  const settlementFailedOwnBillingInfo = computed(() =>
    sortBy(ownBillingInfoData.value, 'billingDate', 'desc').find(
      (billingInfo) =>
        billingInfo.paymentStatus &&
        paymentStatusErrors.some((status) => status === billingInfo.paymentStatus),
    ),
  )

  /**
   * ログインユーザーの決済日が近い請求情報を1件取得
   * アプリ内課金の請求情報は対象外とする
   */
  const ownBillingInfo = computed(() =>
    sortBy(billingInfoCollectionModule.data, 'billingDate').find(
      (billingInfo) => isOwnBillingInfo(billingInfo) && !billingInfo.isInAppPurchasePayment,
    ),
  )

  /**
   * ログインユーザーの決済日が近い年額プランの請求情報を1件取得
   * アプリ内課金の請求情報は対象外とする
   * TODO: SFGO-XXX 日割りプランの支払い周期をYEARで作る場合は考慮が必要になるかもしれない
   */
  const ownAnnualBillingInfo = computed(() =>
    sortBy(billingInfoCollectionModule.data, 'billingDate').find(
      (billingInfo) =>
        isOwnBillingInfo(billingInfo) &&
        billingInfo.paymentCycle === 'YEAR' &&
        !billingInfo.isInAppPurchasePayment,
    ),
  )

  /**
   * ログインユーザーの決済日が近い月額プランの請求情報を1件取得
   * アプリ内課金の請求情報は対象外とする
   */
  const ownMonthlyBillingInfo = computed(() =>
    sortBy(billingInfoCollectionModule.data, 'billingDate').find(
      (billingInfo) =>
        isOwnBillingInfo(billingInfo) &&
        billingInfo.paymentCycle === 'MONTH' &&
        !billingInfo.isInAppPurchasePayment,
    ),
  )

  /**
   * 取得した請求情報をクリアする
   */
  const clearBillingInfo = () => {
    billingInfoCollectionModule.clearData()
  }

  return {
    fetchFixedBillingInfo,
    fetchProvisionalBillingInfo,
    ownBillingInfoData,
    settlementFailedOwnBillingInfo,
    ownBillingInfo,
    ownAnnualBillingInfo,
    ownMonthlyBillingInfo,
    clearBillingInfo,
  }
}
