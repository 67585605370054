var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "service-button-list-parts" }, [
    _c("ul", { staticClass: "service-button-list-parts__list" }, [
      _c(
        "li",
        { staticClass: "service-button-list-parts__item" },
        [
          _c("atom-icon-router-button", {
            attrs: {
              to: "",
              colorType: "secondary",
              linkText: _vm.$tc("SignupPage.IndexPage.signupWithGoogle"),
              imgPath: require("@/assets/img/icon/icon_google.svg"),
              iconPosition: "left",
            },
            on: { emitClick: _vm.startGoogleOauthFlow },
          }),
        ],
        1
      ),
      _vm.isiOS
        ? _c(
            "li",
            { staticClass: "service-button-list-parts__item" },
            [
              _c("atom-icon-router-button", {
                attrs: {
                  to: "",
                  colorType: "secondary",
                  linkText: _vm.$tc("SignupPage.IndexPage.signupWithApple"),
                  imgPath: require("@/assets/img/icon/icon_apple.svg"),
                  iconPosition: "left",
                },
                on: { emitClick: _vm.onStartAppleOauthFlow },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }