import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import InitialUserDocument from '@/store/stores/collectionModule/documents/user/InitialUserDocument'
import UserDocument from '@/store/stores/collectionModule/documents/user/UserDocument'
import UserRegisterTokenDocument, {
  RegistMethodType,
} from '@/store/stores/collectionModule/documents/user/UserRegisterTokenDocument'
import UserValidateTokenDocument from '@/store/stores/collectionModule/documents/user/UserValidateTokenDocument'
import { UserLang } from '@/store/stores/collectionModule/documents/GeneralTypes'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import i18n from '@/locales/I18n'
import useHistory from '@/store/hook/useHistory'

type ProcessType = 'start' | 'end'

/**
 * ユーザー情報を操作するための処理を取得する。
 */
export default function useUser() {
  // Collection modules
  const userValidateTokenCollectionModule = CollectionModule.createStore(UserValidateTokenDocument)
  const initialUserCollectionModule = CollectionModule.createStore(InitialUserDocument)
  const userCollectionModule = CollectionModule.createStore(UserDocument)
  const userRegisterTokenCollectionModule = CollectionModule.createStore(UserRegisterTokenDocument)
  const { saveMissionHistory } = useHistory()

  /**
   * ユーザ情報を取得する。
   * @return APIレスポンス
   */
  const fetchUser = (loginId: string) =>
    userCollectionModule.fetch({ targetId: loginId, isSaveInStore: true })

  // computed
  /**
   * 取得したユーザー情報
   */
  const user = computed(() => userCollectionModule.data[0])

  // methods
  /**
   * 取得した会員情報をクリアする
   */
  const clearUsers = () => {
    userCollectionModule.clearData()
  }

  /**
   * ユーザー登録ページのURLに付与するトークンを生成する
   * @return APIレスポンス
   */
  const registerToken = async (
    memberType: MemberType,
    registMethod: RegistMethodType,
    email: string,
    reCaptchaToken?: string,
  ) => {
    const { locale } = i18n

    const requestData = new UserRegisterTokenDocument({
      reCaptchaToken,
      registMethod,
      organizationName: email,
      lang: locale,
      mailAddress: registMethod === 'MAIL_ADDRESS' ? email : null,
      additionalData: {
        memberType,
      },
    })
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/manage/regist_token`,
    }
    return userRegisterTokenCollectionModule.save(requestData, options)
  }

  /**
   * ユーザー登録トークンの有効確認を行う
   * - 有効な場合、登録トークンドキュメントの内容を返却する
   * - 無効な場合、無効理由を返却する
   * @return APIレスポンス
   */
  const validateToken = async (tokenId: string) =>
    userValidateTokenCollectionModule.fetch({
      url: `${process.env.VUE_APP_API_BASE_URL as string}/manage/regist_token/${tokenId}/validate`,
    })

  /**
   * ユーザー登録の共通のオプションを生成する
   */
  const generateRegisterUserCommonOption = (tokenId: string, password: string) => {
    const uuid = uuidv4()

    const requestData = new InitialUserDocument({
      tokenId: tokenId as string,
      username: uuid,
      displayName: uuid,
      password: password as string,
    })
    const options = {
      url: `${process.env.VUE_APP_API_BASE_URL as string}/manage/service_registration/initial_user`,
    }
    return { requestData, options }
  }

  /**
   * 新規会員情報を登録する
   */
  const registerUser = async (tokenId: string, password: string) => {
    const { requestData, options } = generateRegisterUserCommonOption(tokenId, password)
    return initialUserCollectionModule.save(requestData, options)
  }

  /**
   * 外部アカウント連携で新規会員情報を登録する
   */
  const registerUserWithExternalLink = async (
    tokenId: string,
    password: string,
    mailAddress: string,
    externalLinkUserId: string,
    registMethod: Exclude<RegistMethodType, 'MAIL_ADDRESS'>,
  ) => {
    const { requestData, options } = generateRegisterUserCommonOption(tokenId, password)

    requestData.mailAddress = mailAddress
    if (registMethod === 'GOOGLE') {
      requestData.snsAccountInfo = {
        googleUserId: externalLinkUserId,
      }
    }
    if (registMethod === 'APPLE') {
      requestData.snsAccountInfo = {
        appleUserId: externalLinkUserId,
      }
    }

    return initialUserCollectionModule.save(requestData, options)
  }

  /**
   * 利用するワンタイムパスをユーザー情報にセットしたデータを返す
   * @param userData
   * @param oneTimePassCode
   */
  const getUserAddedOneTimePassInfo = (userData: UserDocument, oneTimePassCode: string) =>
    new UserDocument({
      ...userData,
      additionalData: {
        ...userData.additionalData,
        effectiveCoupon: {
          type: 'watchingVideo',
          couponCode: oneTimePassCode,
        },
      },
    })

  // methods
  const confirmUser = (
    familyNameValue: string,
    firstNameValue: string,
    familyNameKanaValue: string,
    firstNameKanaValue: string,
    userDisplayNameValue: string,
    birthDayValue: string,
    genderValue: string,
    countryValue: string,
    prefectureValue: string,
    zipcodeValue: string,
  ) => {
    const birthDay = `${birthDayValue} 00:00:00`.replace(/-/g, '/')
    const birthDayMillisec = new Date(birthDay).getTime()

    if (userCollectionModule.data[0].additionalData) {
      userCollectionModule.data[0].additionalData.familyName = familyNameValue
      userCollectionModule.data[0].additionalData.firstName = firstNameValue
      userCollectionModule.data[0].additionalData.familyNameKana = familyNameKanaValue
      userCollectionModule.data[0].additionalData.firstNameKana = firstNameKanaValue
      userCollectionModule.data[0].additionalData.userDisplayName = userDisplayNameValue
      userCollectionModule.data[0].additionalData.birthDay = birthDayMillisec
      userCollectionModule.data[0].additionalData.gender = genderValue
      userCollectionModule.data[0].additionalData.country = countryValue
      userCollectionModule.data[0].additionalData.prefecture = prefectureValue
      userCollectionModule.data[0].additionalData.zipcode = zipcodeValue
    } else {
      userCollectionModule.data[0].additionalData = {
        familyName: familyNameValue,
        firstName: firstNameValue,
        familyNameKana: familyNameKanaValue,
        firstNameKana: firstNameKanaValue,
        userDisplayName: userDisplayNameValue,
        birthDay: birthDayMillisec,
        gender: genderValue,
        country: countryValue,
        prefecture: prefectureValue,
        zipcode: zipcodeValue,
        favoritePlayerId: '',
        favoriteTeamId: '',
      }
    }
  }

  /**
   * 利用するワンタイムパスをユーザー情報に保存する
   * @param oneTimePassCode
   */
  const confirmUserOneTimePassInfo = (oneTimePassCode: string) => {
    userCollectionModule.data[0] = getUserAddedOneTimePassInfo(user.value, oneTimePassCode)
  }

  const saveFavoritePlayer = async (playerId: string) => {
    // 「あとでお気に入り選手を選択する」は「未設定」扱いとするため、「あとでお気に入り選手を選択する」を選択している場合は、playerIdをブランクに変換してからDBに保存する
    const newPlayerId = playerId === 'selectFavoritePlayerLater' ? '' : playerId

    if (userCollectionModule.data[0].additionalData) {
      userCollectionModule.data[0].additionalData.favoritePlayerId = newPlayerId
    } else {
      userCollectionModule.data[0].additionalData = {
        familyName: '',
        firstName: '',
        familyNameKana: '',
        firstNameKana: '',
        userDisplayName: '',
        birthDay: new Date().getTime(),
        country: '',
        prefecture: '',
        zipcode: '',
        favoritePlayerId: newPlayerId,
        favoriteTeamId: '',
      }
    }

    const result = await userCollectionModule.save(userCollectionModule.data[0])

    // お気に入り選手登録ミッションの操作ログ登録
    if (result.isSuccess && result.data?.favoritePlayerId) {
      saveMissionHistory(result.data.id || '', 'manage_user', 'register_player')
    }

    return result
  }

  const saveFavoriteTeam = async (teamId: string) => {
    // 「あとでお気に入りチームを選択する」は「未設定」扱いとするため、「あとでお気に入りチームを選択する」を選択している場合は、teamIdをブランクに変換してからDBに保存する
    const newTeamId = teamId === 'selectFavoriteTeamLater' ? '' : teamId

    if (userCollectionModule.data[0].additionalData) {
      userCollectionModule.data[0].additionalData.favoriteTeamId = newTeamId
    } else {
      userCollectionModule.data[0].additionalData = {
        familyName: '',
        firstName: '',
        familyNameKana: '',
        firstNameKana: '',
        userDisplayName: '',
        birthDay: new Date().getTime(),
        country: '',
        prefecture: '',
        zipcode: '',
        favoritePlayerId: '',
        favoriteTeamId: newTeamId,
      }
    }
    const result = await userCollectionModule.save(userCollectionModule.data[0])

    // お気に入り選手登録ミッションの操作ログ登録
    if (result.isSuccess && result.data?.favoriteTeamId) {
      saveMissionHistory(result.data.id || '', 'manage_user', 'register_team')
    }

    return result
  }
  /**
   * 会員の言語を保存する。
   * @param userLanguage 言語
   */
  const saveLanguage = (userLanguage: UserLang) => {
    userCollectionModule.data[0].lang = userLanguage
    return userCollectionModule.save(userCollectionModule.data[0])
  }
  /**
   * プラン登録手続き状態を管理する。
   * プラン登録手続き中の場合、additionalDataに会員登録タイプを保存しておき、プラン登録手続き完了後に削除する。
   * @param memberType 会員登録タイプ
   * @param process 手続き開始か終了か
   *
   */
  const saveRegistrationTypeInProgress = (memberType: MemberType, process: ProcessType) => {
    let requestData = userCollectionModule.data[0]
    if (process === 'start') {
      // プラン登録手続きを開始する場合、additionalDataに会員登録タイプを保存する
      requestData = new UserDocument({
        ...userCollectionModule.data[0],
        additionalData: {
          ...userCollectionModule.data[0].additionalData,
          registrationTypeInProgress: memberType,
        },
      })
    }
    if (process === 'end') {
      // プラン登録手続きを終了する場合、additionalDataから会員登録タイプ削除する
      if (requestData.additionalData) {
        Vue.delete(requestData.additionalData, 'registrationTypeInProgress')
      }
    }

    return userCollectionModule.save(requestData)
  }

  /**
   * マイページのユーザー表示名を保存する
   * @param userData
   * @param userDisplayName
   */
  const saveUserDisplayName = (userData: UserDocument, userDisplayName: string) => {
    const requestData = new UserDocument({
      ...userData,
      additionalData: {
        ...userData.additionalData,
        userDisplayName,
      },
    })
    return userCollectionModule.save(requestData)
  }

  /**
   * ワンタイムパスをユーザー情報に保存する
   * @param userData
   * @param oneTimePassCode
   */
  const saveOneTimePassUsage = (userData: UserDocument, oneTimePassCode: string) => {
    const requestData = getUserAddedOneTimePassInfo(userData, oneTimePassCode)
    return userCollectionModule.save(requestData)
  }

  /**
   * 会員情報の変更を保存する。
   */
  const saveUser = () => userCollectionModule.save(userCollectionModule.data[0])

  return {
    registerToken,
    validateToken,
    registerUser,
    registerUserWithExternalLink,
    fetchUser,
    confirmUser,
    confirmUserOneTimePassInfo,
    user,
    saveUser,
    saveLanguage,
    saveFavoritePlayer,
    saveFavoriteTeam,
    saveRegistrationTypeInProgress,
    clearUsers,
    saveUserDisplayName,
    saveOneTimePassUsage,
  }
}
