var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isIncludeNextRelease
    ? _c(
        "div",
        { staticClass: "mypage-point-history-page" },
        [
          _c("sub-header-section", {
            attrs: {
              pageTitle: _vm.$tc("MypagePage.MypagePointHistoryPage.pageTitle"),
              backLink: "/mypage/point-expiration-date",
            },
          }),
          _c(
            "div",
            { staticClass: "mypage-point-history-page__body" },
            [_c("mypage-point-history-pane")],
            1
          ),
          _c("global-navigation-pane", { attrs: { current: "mypage" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }