































































































import {
  computed,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  ref,
  Ref,
} from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { PluginApi } from 'vue-loading-overlay'
import VueRouter from 'vue-router'
import VuePullRefresh from 'vue-pull-refresh'
import ImgSliderSection, {
  ImgSliderLinkData,
} from '@/components/HomePage/HomePane/ImgSliderSection.vue'
import CountdownSection, {
  UpComingEventDataType,
} from '@/components/HomePage/HomePane/CountdownSection.vue'
import YoutubeListSection from '@/components/HomePage/HomePane/YoutubeListSection.vue'
import SnsContentSection from '@/components/HomePage/HomePane/SnsContentSection.vue'
import LimitedVideoAlertModalSection from '@/components/HomePage/HomePane/LimitedVideoAlertModalSection.vue'
import LimitedVideoModalSection from '@/components/HomePage/HomePane/LimitedVideoModalSection.vue'
import HighlightsSceneSection from '@/components/HomePage/HomePane/HighlightsSceneSection.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import useVideoContents from '@/store/hook/videoPlatformContents/useVideoContents'
import useRewards, { type RewardDataType } from '@/store/hook/videoPlatformContents/useRewards'
import useEvent from '@/store/hook/useEvent'
import useRaces from '@/store/hook/useRaces'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import InAppBrowserWrapper, { InAppBrowserResult } from '@/util/inAppBrowser/InAppBrowserWrapper'
import InAppYoutubeBrowser from '@/util/inAppBrowser/InAppYoutubeBrowser'
import { VideoLinkCardData } from './HomePane/parts/VideoLinkCardParts.vue'
import { DisplayLocationType } from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import DeviceInfo from '@/util/DeviceInfo'
import LocalStorageAccessor from '@/util/localstorage/LocalStorageAccessor'
import Const from '@/util/Const'
import useRealtimeMessaging, { RTMCallbackParamType } from '@/components/hook/useRealtimeMessaging'
import Logger from '@/util/logger/Logger'
import NoticeNewArrivalsModalSection from '@/components/HomePage/HomePane/NoticeNewArrivalsModalSection.vue'
import HomeLinkButtonsSection from '@/components/HomePage/HomePane/HomeLinkButtonsSection.vue'
import RewardNoticeModalSection from '@/components/HomePage/HomePane/RewardNoticeModalSection.vue'
import IndexedDBAccessor from '@/store/stores/IndexedDBstore/IndexedDBAccessor'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'
import UserInfoPromptModalSection from '@/components/HomePage/HomePane/UserInfoPromptModalSection.vue'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

/**
 * HOME ペイン
 */
export default defineComponent({
  name: 'HomePane',
  components: {
    UserInfoPromptModalSection,
    RewardNoticeModalSection,
    HomeLinkButtonsSection,
    NoticeNewArrivalsModalSection,
    ImgSliderSection,
    YoutubeListSection,
    SnsContentSection,
    CountdownSection,
    LimitedVideoAlertModalSection,
    LimitedVideoModalSection,
    HighlightsSceneSection,
    VuePullRefresh,
  },
  inject: ['router', 'loading'],
  props: {
    enabledCarouselList: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const store = StoreUtil.useStore('HomePageStore')
    const notificationStore = StoreUtil.useStore('NotificationStore')
    const userStore = StoreUtil.useStore('UserStore')

    const { newArrivalHighlightAndCommentData } = store
    const { fetchNotificationData } = notificationStore
    const { fetchReceivedData, attachedDataIdList, profileAndFavoriteMissionCompleted } = userStore

    const {
      setDisplayedNewArrivals,
      getDisplayedNewArrivals,
      setNewRewardsLastDisplayedDate,
      getNewRewardsLastDisplayedDate,
      setDisplayedUserInfoPrompt,
      getDisplayedUserInfoPrompt,
    } = IndexedDBAccessor()
    const {
      fetchVideoContents,
      videoContents,
      fetchAutoLinkSfOfficialWebContents,
      autoLinkSfOfficialWebContents,
    } = useVideoContents()
    const { fetchRewardContentsByAttachedDataId, fetchRankSpecifiedRewards, rewardDataList } =
      useRewards()
    const { fetchUpcomingEvent, events } = useEvent()
    const {
      fetchRacesForSpecifiedRange,
      fetchMovieInfo,
      raceList,
      contentsInfos,
      angleMovieInfos,
    } = useRaces()
    const { initRTM, subscribeRTM, unSubscribeRTM } = useRealtimeMessaging()
    const { getCurrentRank } = useMembershipCard()

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    /** アプリインストール後、オンボーディング画面を表示したかどうか */
    const hasDisplayedOnboarding = ref(true)
    /** アプリインストール後、機能アップデートのお知らせモーダルを表示したかどうか */
    const hasDisplayedNewArrivals = ref(true)
    /** ライブ配信中のレースが存在するかどうか */
    const hasLiveRace = ref(false)
    /** ライブ配信中の動画再生画面の遷移先 */
    const liveRaceLink = ref('')
    /** 新着特典情報 */
    const newArrivalsRewards = ref<Array<RewardDataType>>([])
    /** 現在時刻 */
    const now = ref<number>(dayjs().valueOf())
    /** 会員情報入力催促モーダルを表示したかどうか */
    const showUserInfoPromptModal = ref(false)

    /** 新機能追加のお知らせページのURL */
    // const newReleasePageUrl =
    //   I18n.locale === 'ja'
    //     ? Const.EXTERNAL_LINKS.NEW_RELEASE.JA
    //     : Const.EXTERNAL_LINKS.NEW_RELEASE.EN
    // リリースページを作成しない場合は、リリースページへのリンクを非表示にする
    const newReleasePageUrl = ''

    /** 有料会員についてのURL */
    const aboutSfgoLink =
      I18n.locale === 'ja' ? Const.EXTERNAL_LINKS.ABOUT_SFGO.JA : Const.EXTERNAL_LINKS.ABOUT_SFGO.EN

    /** HOME画面を開いた日を日本時間に変換し、日本時間の00:00:00を取得 */
    const startDateOfHomeOpened = dayjs(now.value).tz('Asia/Tokyo').startOf('day').valueOf()
    /** HOME画面を開いた日を日本時間に変換し、日本時間の23:59:59を取得 */
    const endDateOfHomeOpened = dayjs(now.value).tz('Asia/Tokyo').endOf('day').valueOf()

    /**
     * vue-pull-refreshによるプロパティ設定
     */
    const refreshConfig = {
      errorLabel: I18n.t('HomePage.refreshConfig.errorLabel'),
      startLabel: I18n.t('HomePage.refreshConfig.startLabel'),
      readyLabel: I18n.t('HomePage.refreshConfig.readyLabel'),
      loadingLabel: I18n.t('HomePage.refreshConfig.loadingLabel'),
    }

    /**
     * 会員種別取得
     */
    const membershipType = computed(() => (ContractInfoStore.value.isFreePlan ? 'free' : 'paid'))

    /**
     * 現在のランク
     */
    const currentRank = computed(() =>
      membershipType.value === 'free'
        ? 'free'
        : getCurrentRank(mypagePageStore.currentOwnedPoints.value),
    )

    /**
     * ライブ配信中映像のレースIDを取得
     * ライブ配信中の映像が存在する場合は、そのレースのレースIDを返す
     */
    const getLiveMovieRaceId = () => {
      const isLiveContentsInfo = contentsInfos.value.find((contentsInfo) =>
        contentsInfo.isLiveBroadcasting(),
      )
      if (isLiveContentsInfo) {
        return isLiveContentsInfo.matchId
      }

      return angleMovieInfos.value.find((angleMovieInfo) => angleMovieInfo.isLiveBroadcasting())
        ?.matchId
    }

    /**
     * ライブ配信中のレースがあるかをチェック
     * ライブ配信中のレースがある場合は、対象レース動画のURLをセットする
     */
    const checkHasLiveRace = async () => {
      const raceIds = raceList.value
        .map((race) => race.id)
        .filter((raceId): raceId is string => typeof raceId === 'string')
      await fetchMovieInfo(raceIds)

      // ライブ配信中のレースIDを取得
      const liveRaceId = getLiveMovieRaceId()
      if (liveRaceId) {
        // ライブ配信中のレースが存在する場合の処理

        // ライブ配信中のレースありにする
        hasLiveRace.value = true

        // ライブ配信中の動画再生画面へのリンクをセットする
        const targetRace = raceList.value.find((race) => race.id === liveRaceId)
        const championshipMasterId = targetRace?.championshipMasterId
        liveRaceLink.value = championshipMasterId
          ? `/race-video/${championshipMasterId}/${liveRaceId}`
          : ''
      } else {
        // ライブ配信中のレースなしにする
        hasLiveRace.value = false
        // ライブ配信中の動画再生画面へのリンクをクリアする
        liveRaceLink.value = ''
      }
    }

    /**
     * 特典一覧ページへ遷移する
     */
    const handleShowRewardPageClicked = () => {
      newArrivalsRewards.value = []
      setNewRewardsLastDisplayedDate({
        displayedDate: now.value,
      })
      router.replace({ path: '/reward' })
    }

    /**
     * 特典のお知らせモーダルの閉じる処理
     */
    const handleNoticeModalCloseClicked = () => {
      newArrivalsRewards.value = []
      setNewRewardsLastDisplayedDate({
        displayedDate: now.value,
      })
    }

    /**
     * 新着特典情報を取得する
     */
    const getNewArrivalsRewards = async () => {
      const lastDisplayedDate = await getNewRewardsLastDisplayedDate()
      const lastMonthDate = dayjs().subtract(1, 'month').valueOf()
      const referenceDate = lastDisplayedDate || lastMonthDate

      // 表示対象の新着特典を抽出
      return rewardDataList.value.filter(
        (reward) => reward.distributionDateUnixTime > referenceDate,
      )
    }

    /**
     * 会員情報入力催促モーダルを表示するかどうかをチェックする
     */
    const isShowUserInfoPromptModal = async () => {
      const displayed = await getDisplayedUserInfoPrompt()
      if (displayed) {
        // 1度表示済みの場合は表示しない
        return false
      }

      // プロフィール/お気に入りミッションで必要な情報が入力されている場合は表示しない
      return !profileAndFavoriteMissionCompleted()
    }

    onMounted(async () => {
      store.clearHomePageStore()
      const loader = loading.show()
      // HOME画面を表示するために必要となるデータを取得する
      // ヘッドライン、動画コンテンツを取得する
      // 受信データを取得する
      // マイページのデータを取得する
      // カウントダウンの情報を取得する
      try {
        const results = await Promise.all([
          store.fetchHomePageData(),
          fetchVideoContents(),
          fetchAutoLinkSfOfficialWebContents(),
          fetchReceivedData(),
          mypagePageStore.fetchMypagePageData('', true),
          fetchUpcomingEvent(),
          fetchRacesForSpecifiedRange(startDateOfHomeOpened, endDateOfHomeOpened),
        ])

        // ユーザー指定・特典画像データを取得する
        // ランク指定・特典画像データを取得する
        const rewardResults = await Promise.all([
          fetchRankSpecifiedRewards(currentRank.value),
          fetchRewardContentsByAttachedDataId(attachedDataIdList.value),
        ])

        const failedResult = [...results, ...rewardResults.flat()].find(
          (result) => result?.isSuccess === false,
        )
        if (failedResult) {
          loader.hide()
          await MessageDialogStore.value.open({
            title: I18n.tc('HomePage.errors.fetchHomePageDataError.title'),
            message: I18n.tc('HomePage.errors.fetchHomePageDataError.message'),
            errorApiResponse: failedResult.response,
          })
          // エラーの場合は、後続の処理を行わない（初回起動時のオンボーディングを表示しない）
          return
        }
      } catch (e) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('HomePage.errors.fetchHomePageDataError.title'),
          message: I18n.tc('HomePage.errors.fetchHomePageDataError.message'),
        })
        // エラーの場合は、後続の処理を行わない（初回起動時のオンボーディングを表示しない）
        return
      }

      // ライブ配信中のレースがあるかをチェック
      await checkHasLiveRace()
      loader.hide()

      // 最後に特典を表示した日時以降に配布された特典だけを取り出す
      newArrivalsRewards.value = (await getNewArrivalsRewards()) ?? []

      if (!LocalStorageAccessor.hasDisplayedOnboarding && hasLiveRace.value) {
        /**
         * オンボーディングを一度も表示していない場合、フラグをfalseにしてオンボーディング画面を表示する
         *  ただし、ライブ配信中のレースが存在しない場合はHOME画面からサーキットモードの切り替えができないためオンボーディング表示は不要（ちなみにhasLiveRace.valueがtrueの場合のみサーキットモードを表示するようにCountdownSection.vueでも制御している）
         */
        hasDisplayedOnboarding.value = false
      }
      const appVersion = await getDisplayedNewArrivals()
      if (appVersion !== process.env.VUE_APP_SFGO_APP_VERSION) {
        // 該当バージョンの機能アップデートのお知らせモーダルを一度も表示していない場合、お知らせモーダルを表示する
        // 機能アップデートのお知らせモーダルを表示しない場合はコメントアウトする
        // hasDisplayedNewArrivals.value = false
      }
      // 会員情報入力催促モーダルを出したことがない場合は表示する
      showUserInfoPromptModal.value = await isShowUserInfoPromptModal()

      // お知らせのリアルタイムメッセージを受信
      await initRTM()
      subscribeRTM('information', async (data: RTMCallbackParamType) => {
        Logger.debug(
          `HomePane#subscribeRTM: Receive notification event. event: ${JSON.stringify(data)}`,
        )
        fetchNotificationData()
      })
    })

    /**
     * カウントダウン表示用のデータを生成する
     */
    const upComingEventData: Ref<UpComingEventDataType | undefined> = computed(() => {
      if (events.value.length === 0) return undefined
      return {
        eventId: events.value[0].id,
        type: events.value[0].additionalData?.type,
        starDate: new Date(dayjs(events.value[0].startDate).format('YYYY/MM/DD HH:mm')),
        eventName: events.value[0].additionalData?.eventName,
        circuit: events.value[0].additionalData?.circuit,
        isShowTicketButton: events.value[0].additionalData?.isShowTicketButton,
      }
    })

    /**
     * 無料会員向け有料動画モーダルフラグ
     */
    const limitedVideoAlertModalEnabled = ref<boolean>(false)
    const videoModalEnabled = ref<boolean>(false)
    const videoId = ref<string>('')

    const inAppBrowser = new InAppYoutubeBrowser()

    /**
     * 動画モーダルを表示する
     * @param youtubeId Youtube動画ID
     */
    const showVideoModal = (youtubeId: string) => {
      if (InAppBrowserWrapper.isAvailable()) {
        // アプリで動作している場合、InAppBrowserを利用してYoutube動画を再生する
        inAppBrowser.showYoutube(youtubeId, async (result: InAppBrowserResult) => {
          if (result?.isNetworkError) {
            await MessageDialogStore.value.open({
              title: I18n.t('apiNotification.dialog.0.title') as string,
              message: I18n.t('apiNotification.dialog.0.message') as string,
            })
          } else if (result?.isError) {
            await MessageDialogStore.value.open({
              title: I18n.t('HomePage.errors.VideoPlayError.title') as string,
              message: I18n.t('HomePage.errors.VideoPlayError.message') as string,
            })
          }
        })
      } else {
        videoModalEnabled.value = true
        videoId.value = youtubeId
      }
    }

    /**
     * SUPER FORUMULA公式Youtube または、 会員限定動画のカードが選択された時に呼び出される。
     * @param videoCardData 選択されたVideoカードのデータ
     */
    const onYoutubeVideoCardSelected = (videoCardData: VideoLinkCardData) => {
      if (videoCardData.membership === 'paid' && membershipType.value === 'free') {
        // 無料会員が有料会員向けのコンテンツを選択した場合、動画を参照できない旨のメッセージを表示して終了する
        limitedVideoAlertModalEnabled.value = true
        return
      }
      limitedVideoAlertModalEnabled.value = false
      showVideoModal(videoCardData.link)
    }

    /**
     * 無料会員向け有料動画モーダル非表示
     */
    const hideLimitedVideoAlertModal = () => {
      limitedVideoAlertModalEnabled.value = false
    }

    /**
     * 動画モーダルを閉じる
     */
    const hideVideoModal = () => {
      videoModalEnabled.value = false
    }

    onUnmounted(() => {
      // お知らせに関連するリアルタイムメッセージ受信を停止
      unSubscribeRTM()

      inAppBrowser.close()
    })

    /**
     * 動画プラットフォームコンテンツ情報を、動画カードのデータに指定できる形式に変換する
     *
     * @param displayLocation 動画カードの表示箇所
     */
    const convertLinkData = (displayLocation: DisplayLocationType): Array<VideoLinkCardData> => {
      const userLang = mypagePageStore.user.value.lang || DeviceInfo.getLanguage()
      return videoContents.value
        .filter((v) => v.additionalData?.displayLocation === displayLocation)
        .filter((videoContent) => videoContent.canDisplay(mypagePageStore.user.value.lang))
        .map((v) => ({
          img: v.videoThumbnailPath ?? '',
          title: v.title?.[userLang] ?? '',
          link: v.videoId ?? '',
          date: dayjs(v._createdDate).format('YYYY.MM.DD'),
          membership: v.additionalData?.isPaidMemberOnly ? 'paid' : 'free',
        }))
    }

    /**
     * SUPER FORMULA 公式Youtubeの動画のリスト
     */
    const youtubeLinksData = computed(() => convertLinkData('sfOfficial'))

    /**
     * 会員限定動画のリスト
     */
    const limitedVideoLinksData = computed(() => convertLinkData('membersOnly'))

    /**
     * モータースポーツYouTube動画のリスト
     */
    const motorSportYoutubeLinksData = computed(() => convertLinkData('motorsport'))

    /**
     * ヘッドラインのデータのリスト
     */
    const headlineLinksData = computed((): Array<ImgSliderLinkData> => {
      const userLang = mypagePageStore.user.value.lang || DeviceInfo.getLanguage()
      return [...videoContents.value, ...autoLinkSfOfficialWebContents.value]
        .sort((a, b) => (b.sortDate || 0) - (a.sortDate || 0))
        .filter((headlines) => headlines.additionalData?.displayLocation === 'headline')
        .filter((videoContent) => videoContent.canDisplay(mypagePageStore.user.value.lang))
        .map((headline) => {
          const isBingo = headline.videoId === process.env.VUE_APP_SFBINGO_URL
          return {
            videoContentsId: headline.id as string,
            linkType: headline.videoPlatform === 'sfOfficialWeb' ? 'link' : 'router',
            link:
              mypagePageStore.user.value.lang === 'ja' || isBingo
                ? headline.videoId ?? ''
                : `${headline.videoId}/#googtrans(ja|en)`,
            img: headline.videoThumbnailPath ?? '',
            text: headline.title[userLang] ?? '',
          }
        })
    })
    /**
     * vue-pull-refresh
     * HOME画面を表示するために必要な以下のデータを取得する
     * - SNS（Twitter/Instagram）
     * - ヘッドライン/動画コンテンツ
     * - カウントダウン
     */
    const onRefresh = () => {
      if (!hasDisplayedOnboarding.value || !hasDisplayedNewArrivals.value) {
        // オンボーディングまたは新着通知が表示されている場合は、vue-pull-refreshを動かさない
        return
      }

      // eslint-disable-next-line consistent-return
      return new Promise<void>((resolve) => {
        setTimeout(async () => {
          store.clearHomePageStore()
          // 現在時刻を更新する
          now.value = dayjs().valueOf()

          // 開いてるタブ（currentSnsTab）に応じて、fetchする。
          // 初期値の開いているタブがDRIVERなのでdefaultとして指定
          switch (store.currentSnsTab.value) {
            case 'team':
              if (store.snsPostDisplayTeam.value?.teamId) {
                await store.fetchHomePageSnsFeed(store.snsPostDisplayTeam.value.teamId)
              }
              break
            case 'official':
              await store.fetchHomePageSnsFeed('SF')
              break
            default:
              if (store.snsPostDisplayPlayer.value?.playerId) {
                await store.fetchHomePageSnsFeed(store.snsPostDisplayPlayer.value.playerId)
              }
              break
          }

          const results = await Promise.all([
            store.fetchHomePageData(),
            fetchVideoContents(),
            fetchAutoLinkSfOfficialWebContents(),
            fetchReceivedData(),
            mypagePageStore.fetchMypagePageData(),
            fetchUpcomingEvent(),
            fetchRacesForSpecifiedRange(startDateOfHomeOpened, endDateOfHomeOpened),
          ])

          // ユーザー指定・特典画像データを取得する
          // ランク指定・特典画像データを取得する
          const rewardResults = await Promise.all([
            fetchRankSpecifiedRewards(currentRank.value),
            fetchRewardContentsByAttachedDataId(attachedDataIdList.value),
          ])

          const failedResult = [...results, ...rewardResults.flat()].find(
            (result) => result?.isSuccess === false,
          )

          if (failedResult) {
            await MessageDialogStore.value.open({
              title: I18n.t('HomePage.errors.fetchHomePageDataError.title') as string,
              message: I18n.t('HomePage.errors.fetchHomePageDataError.message') as string,
              errorApiResponse: failedResult.response,
            })
          }

          // ライブ配信中のレースがあるかをチェック
          await checkHasLiveRace()

          newArrivalsRewards.value = (await getNewArrivalsRewards()) ?? []

          if (!LocalStorageAccessor.hasDisplayedOnboarding && hasLiveRace.value) {
            /**
             * オンボーディングを一度も表示していない場合、フラグをfalseにしてオンボーディング画面を表示する
             *  ただし、ライブ配信中のレースが存在しない場合はHOME画面からサーキットモードの切り替えができないためオンボーディングも表示されない
             */
            hasDisplayedOnboarding.value = false
          }

          resolve()
        }, 1000)
      })
    }

    /**
     * アプリインストール後オンボーディング画面を表示し、閉じた場合に発火
     */
    const closeOnboardingScreen = () => {
      LocalStorageAccessor.hasDisplayedOnboarding = true
      hasDisplayedOnboarding.value = true
    }

    /**
     * 新機能追加のお知らせモーダルを非表示にする
     */
    const closeNewArrivalsModal = (pageTransition: boolean) => {
      setDisplayedNewArrivals({
        version: process.env.VUE_APP_SFGO_APP_VERSION,
      })
      hasDisplayedNewArrivals.value = true

      if (pageTransition) {
        // 表示する新着機能に応じて遷移先を変更する
        router.replace({ name: 'Mission' })
      }
    }

    /**
     * 会員情報入力催促モーダルを非表示にする
     */
    const closeShowUserInfoPromptModal = (destination: string) => {
      setDisplayedUserInfoPrompt({
        displayed: true,
      })
      showUserInfoPromptModal.value = false

      if (destination) {
        // 遷移先が存在する場合、対象の画面に遷移する
        router.replace({ path: destination })
      }
    }

    return {
      newArrivalHighlightAndCommentData,
      hasLiveRace,
      liveRaceLink,
      newReleasePageUrl,
      aboutSfgoLink,
      limitedVideoAlertModalEnabled,
      onYoutubeVideoCardSelected,
      hideLimitedVideoAlertModal,
      videoModalEnabled,
      hideVideoModal,
      videoId,
      membershipType,
      youtubeLinksData,
      limitedVideoLinksData,
      motorSportYoutubeLinksData,
      headlineLinksData,
      upComingEventData,
      onRefresh,
      refreshConfig,
      hasDisplayedOnboarding,
      closeOnboardingScreen,
      hasDisplayedNewArrivals,
      closeNewArrivalsModal,
      closeShowUserInfoPromptModal,
      newArrivalsRewards,
      handleShowRewardPageClicked,
      handleNoticeModalCloseClicked,
      showUserInfoPromptModal,
    }
  },
})
