





















































import {
  defineComponent,
  ref,
  watch,
  Ref,
  reactive,
  inject,
  computed,
  PropType,
} from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import AtomInputText from '@/components/atoms/input/AtomInputText.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import StoreUtil from '@/store/StoreUtil'
import CouponDocument from '@/store/stores/collectionModule/documents/coupon/CouponDocument'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useOneTimePassValidation from '@/components/hook/oneTimePass/useOneTimePassValidation'
import I18n from '@/locales/I18n'
import LocationErrorModalSection from '@/components/common/modal/LocationErrorModalSection.vue'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import { OneTimePassScreenType } from '@/store/stores/pageStore/MypagePage/OneTimePassPageStore'

/**
 * マイページ: ワンタイムパス: 入力セクション
 */
export default defineComponent({
  name: 'OneTimePassInputSection',
  components: {
    CommonModalSection,
    LocationErrorModalSection,
    AtomInputText,
    AtomInputButton,
    FormErrorMessageParts,
  },
  props: {
    /**
     * ワンタイムパス画面の種類
     */
    oneTimePassScreen: {
      type: String as PropType<OneTimePassScreenType>,
      default: 'Mypage',
    },
  },
  setup(props, { emit }) {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const {
      fetchTargetCoupon,
      fetchTargetCouponWithoutAuthorized,
      targetOneTimePass,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      clearCoupons,
      fetchTargetAvailableArea,
      getTargetAvailableArea,
    } = oneTimePassPageStore
    const { validateOneTimePass } = useOneTimePassValidation()

    const loading = inject('loading') as PluginApi

    const state = reactive({
      /** 入力したワンタイムパスコード */
      oneTimePassCode: '',
      /** エラー */
      oneTimePassError: false,
      /** エラーメッセージ */
      oneTimePassErrorMessage: '',
      /** 位置情報エラーモーダルを出すかどうか */
      isShowLocationErrorModal: false,
    })

    /** 取得したワンタイムパス情報 */
    const targetOneTimePassItem = ref(undefined) as Ref<CouponDocument | undefined>

    /**
     * 入力したワンタイムパスコードに紐づく店舗名またはエリア名
     */
    const oneTimePassAvailableStoreName = computed(() => {
      const area = getTargetAvailableArea(targetOneTimePassItem.value?.availableArea?.areaId ?? '')
      return area?.storeOrAreaName
    })

    /**
     * ワンタイムパス登録ボタン押下時処理
     */
    const handlerSubmit = async () => {
      clearCoupons()

      const loader = loading.show()
      if (state.oneTimePassCode) {
        // 入力したコードに紐づくワンタイムパス情報を取得
        const result =
          props.oneTimePassScreen !== 'AppSignup'
            ? await fetchTargetCoupon(state.oneTimePassCode)
            : await fetchTargetCouponWithoutAuthorized(state.oneTimePassCode)
        if (!result.isSuccess && result.response?.isNetworkError) {
          loader.hide()
          // ネットワークエラーでクーポンを取得できなかった場合は、エラーメッセージを表示する
          await MessageDialogStore.value.open?.({
            errorApiResponse: result.response,
            isVisibleCancelButton: false,
          })
          return
        }
      }

      // 入力したコードに紐づくワンタイムパス情報
      targetOneTimePassItem.value = targetOneTimePass(state.oneTimePassCode)

      // バリデーションチェックを実行
      const validationResult = validateOneTimePass(
        state.oneTimePassCode,
        targetOneTimePassItem.value,
      )
      state.oneTimePassError = validationResult.isError
      state.oneTimePassErrorMessage = validationResult.errorMessage

      // エラーがある場合、画面遷移しない
      if (state.oneTimePassError) {
        loader.hide()
        return
      }

      if (props.oneTimePassScreen === 'AppSignup') {
        loader.hide()
        // アプリ内会員登録画面の場合、親の処理を呼び、後続処理を実行しない
        emit('handlerSubmit', targetOneTimePassItem.value)
        return
      }

      const oneTimePassAvailableAreaId = targetOneTimePassItem.value?.availableArea?.areaId ?? ''
      const checkLocationTiming = targetOneTimePassItem.value?.availableArea?.checkLocationTiming

      const fetchTargetAvailableAreaResult = await fetchTargetAvailableArea(
        oneTimePassAvailableAreaId,
      )
      if (!fetchTargetAvailableAreaResult.isSuccess) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.fetchAvailableAreaDataError.title'),
          message: I18n.tc('common.errors.fetchAvailableAreaDataError.message'),
        })
        return
      }

      if (checkLocationTiming === 'watchingVideo') {
        const result = await checkIsInAreaCanUseOneTimePass(oneTimePassAvailableAreaId)
        if (result.isInArea) {
          // ワンタイムパス利用可能エリア内の場合、ワンタイムパスの利用履歴を登録する
          emit('onSaveOneTimePassUsage', targetOneTimePassItem.value)
        } else if (!result.isInArea && result.isDisplayLatLng) {
          // ワンタイムパス利用可能エリア外の場合、緯度経度情報などのエラーを表示する
          // 例外処理が発生した際には別のエラーモーダルが表示されるため、ワンタイムパス入力モーダル表示を切り替えない
          state.isShowLocationErrorModal = true
        }
      } else {
        emit('handlerSubmit', targetOneTimePassItem.value)
      }
      loader.hide()
    }

    /**
     * ワンタイムパスの入力値が変化したタイミングでバリデーションエラーを消す
     */
    watch(
      () => state.oneTimePassCode,
      () => {
        state.oneTimePassError = false
      },
    )

    return {
      // OneTimePassInputSection
      state,
      oneTimePassAvailableStoreName,
      handlerSubmit,
      // OneTimePassPageStore
      currentLocationInfo,
    }
  },
  methods: {
    /**
     * 位置情報エラーモーダルを閉じる
     */
    emitClose() {
      this.state.isShowLocationErrorModal = false
    },
  },
})
