var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-signup-input-page" },
    [
      _c("header-pane", {
        attrs: {
          pageTitle: _vm.$tc("SignupPage.pageTitle"),
          colorType: "light",
        },
      }),
      _c("app-signup-input-pane", {
        attrs: {
          email: _vm.email,
          tokenId: _vm.validTokenId,
          keyIdentifyOneTimePass: _vm.keyIdentifyOneTimePass,
          method: _vm.method,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }