



























import { defineComponent } from '@vue/composition-api'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import usePasswordResetToken from '@/store/hook/usePasswordResetToken'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import useReCaptcha from '@/components/hook/useReCaptcha'
import I18n from '@/locales/I18n'

type DataType = {
  email: string
  showEMailError: boolean
  eMailErrorMessage: string
}

/**
 * パスワードリセット メールアドレス入力セクション
 */
export default defineComponent({
  name: 'PasswordResetMailInputSection',
  components: {
    AtomInputButton,
    FieldsetEmailParts,
  },
  setup() {
    const { registerToken } = usePasswordResetToken()
    const { getReCaptchaToken } = useReCaptcha()

    return {
      registerToken,
      getReCaptchaToken,
    }
  },
  data(): DataType {
    return {
      email: '',
      showEMailError: false,
      eMailErrorMessage: '',
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async sendCertificationMail() {
      /**
       * 新しいパスワードのバリデーション チェック
       */
      /** エラーを初期化する */
      this.showEMailError = false
      const regex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      )
      if (!regex.test(this.email)) {
        this.eMailErrorMessage = this.$t('PasswordResetPage.MailInputPage.errorEmail') as string
        this.showEMailError = true
      }

      /**
       * パスワードリセットメール送信
       */
      if (!this.showEMailError) {
        const loader = this.$loading.show()
        const reCaptchaToken = await this.getReCaptchaToken()
        // reCaptchaのトークン取得に失敗した場合、エラーを表示して処理を終了する
        if (!reCaptchaToken) {
          loader.hide()
          MessageDialogStore.value.open({
            title: I18n.tc('SignupPage.common.errors.getReCaptchaTokenError.title'),
            message: I18n.tc('SignupPage.common.errors.getReCaptchaTokenError.message'),
          })
          return {
            isSuccess: false,
            res: { response: { data: { error_code: 'NETWORK_ERROR' } } },
          }
        }
        const apiResult = await this.registerToken(this.email, reCaptchaToken)
        loader.hide()
        if (apiResult.isSuccess) {
          /**
           * 完了通知
           */
          this.$emit('success-regist')
        } else {
          // API通信不具合
          await MessageDialogStore.value.open({
            title: this.$t('PasswordResetPage.errors.title') as string,
            message: this.$t('PasswordResetPage.errors.message') as string,
            errorApiResponse: apiResult?.response,
          })
        }
      }
    },
  },
})
