












import { defineComponent, inject, onMounted, PropType, ref } from '@vue/composition-api'
import type VueRouter from 'vue-router'
import { PluginApi } from 'vue-loading-overlay'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'
import AppSignupInputPane from '@/components/AppSignupPage/AppSignupInputPane.vue'
import StoreUtil from '@/store/StoreUtil'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import i18n from '@/locales/I18n'
import Const from '@/util/Const'
import { RegistMethodType } from '@/store/stores/collectionModule/documents/user/UserRegisterTokenDocument'

/**
 * アプリ新規会員登録: 会員情報入力ページ
 */
export default defineComponent({
  name: 'AppSignupInputPage',
  components: { AppSignupInputPane, HeaderPane },
  props: {
    /**
     * 登録トークン管理APIで発行されたトークンID
     */
    tokenId: {
      type: String,
      default: '',
    },
    /**
     * 登録方法
     */
    method: {
      type: String as PropType<RegistMethodType>,
      default: 'MAIL_ADDRESS',
    },
    /**
     * OAuth 認可URLに指定するためのstate値
     */
    state: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')

    const router = inject('router') as VueRouter
    const loading = inject('loading') as PluginApi

    // 登録メールアドレス
    const email = ref('')
    // 前の画面で入力したワンタイムパスを特定するためのキー
    const keyIdentifyOneTimePass = ref('')
    // 有効なトークンID
    const validTokenId = ref('')

    /**
     * トークンが有効かどうかをチェックする
     */
    const checkValidateToken = async () => {
      // 登録方法がメールの場合
      const loader = loading.show()
      const validationResult = await signupPageStore.validateToken(props.tokenId)
      loader.hide()
      const responseData = validationResult?.data[0]
      if (
        !validationResult.isSuccess ||
        !responseData.isValid ||
        !responseData.manageRegistToken?.mailAddress
      ) {
        await MessageDialogStore.value.open({
          title: i18n.tc('SignupPage.AppSignupInputPage.errors.urlInvalidError.title'),
          message: i18n.tc('SignupPage.AppSignupInputPage.errors.urlInvalidError.message'),
        })
        return { isValid: false, responseData }
      }
      return { isValid: true, responseData }
    }

    /**
     * 登録トークンを生成する
     */
    const generateRequestToken = async (
      registMethod: RegistMethodType | undefined,
      emailAddress: string,
    ) => {
      const loader = loading.show()
      const result = await signupPageStore.registerToken(
        Const.MEMBER_TYPE.FREE,
        registMethod as RegistMethodType,
        emailAddress,
      )
      loader.hide()

      if (!result.isSuccess) {
        await MessageDialogStore.value.open({
          title: i18n.tc('SignupPage.common.errors.generateTokenError.title'),
          message: i18n.tc('SignupPage.common.errors.generateTokenError.message'),
          errorApiResponse: result?.response,
        })
      }

      return result
    }

    onMounted(async () => {
      if (props.method === 'MAIL_ADDRESS') {
        // トークンが有効かどうかをチェックする
        const result = await checkValidateToken()
        if (!result.isValid) {
          router.replace({ path: '/login' })
        }

        // 認証済みのメールアドレスをセットする
        email.value = result.responseData.manageRegistToken?.mailAddress ?? ''
        validTokenId.value = props.tokenId
        // 有効期限をキーにセットする
        keyIdentifyOneTimePass.value = result.responseData.manageRegistToken?.tokenExpirationDate
          ? String(result.responseData.manageRegistToken.tokenExpirationDate)
          : ''
      } else {
        // 登録方法がSNSの場合
        let registMethod: RegistMethodType | undefined
        let AuthorizedUserEmailAddress = ''
        if (props.method === 'GOOGLE') {
          registMethod = 'GOOGLE'
          AuthorizedUserEmailAddress = signupPageStore.googleUserInfo.value.email ?? ''
          // stateをキーにセットする
          keyIdentifyOneTimePass.value = props.state
        } else if (props.method === 'APPLE') {
          registMethod = 'APPLE'
          AuthorizedUserEmailAddress = signupPageStore.appleUserInfo.value?.email ?? ''
        }

        // 登録トークンを生成する
        const result = await generateRequestToken(registMethod, AuthorizedUserEmailAddress)
        if (!result.isSuccess) {
          router.replace({ path: '/login' })
        }

        email.value = AuthorizedUserEmailAddress
        validTokenId.value = result.data?.tokenId ?? ''
      }
    })

    return {
      email,
      validTokenId,
      keyIdentifyOneTimePass,
    }
  },
})
