






















































import { computed, defineComponent, reactive, Ref } from '@vue/composition-api'
import CurrentPlanCardSection from '@/components/SignupPage/common/CurrentPlanCardSection.vue'
import StoreUtil from '@/store/StoreUtil'
import ContractPlanDocument from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import useOneTimePassContract from '@/components/hook/useOneTimePassContract'
import useOneTimePassErrorHandling from '@/components/hook/error/useOneTimePassErrorHandling'
import useHistory from '@/store/hook/useHistory'
import useGeolocation from '@/components/hook/useGeolocation'
import useErrorHandling from '@/components/hook/useErrorHandling'
import ContractInfoStore from '@/store/stores/pageStore/common/ContractInfoStore'
import CommonModalSection from '@/components/common/modal/CommonModalSection.vue'
import LocationErrorModalSection from '@/components/common/modal/LocationErrorModalSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'

/**
 * マイページ: ワンタイムパス: 確認ペイン
 */
export default defineComponent({
  name: 'OneTimePassConfirmPane',
  components: {
    LocationErrorModalSection,
    CommonModalSection,
    CurrentPlanCardSection,
    ActionButtonsSection,
    AtomInputButton,
  },
  setup() {
    const oneTimePassPageStore = StoreUtil.useStore('OneTimePassPageStore')
    const userStore = StoreUtil.useStore('UserStore')
    const errorStore = StoreUtil.useStore('ErrorStore')
    const {
      oneTimePass,
      oneTimePassPlan,
      ownContractInfo,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      getTargetAvailableArea,
    } = oneTimePassPageStore
    const { user } = userStore
    const { createOneTimePassContract } = useOneTimePassContract()
    const { createContractError } = useOneTimePassErrorHandling()
    const { saveContractInfoHistory } = useHistory()
    const { getCurrentPosition, checkIsUserInArea } = useGeolocation()
    const { createGeolocationError } = useErrorHandling()

    const state = reactive({
      /** 位置情報エラーモーダルを出すかどうか */
      isShowLocationErrorModal: false,
    })

    /**
     * 入力したワンタイムパスコードに紐づく店舗名またはエリア名
     */
    const oneTimePassAvailableStoreName = computed(() => {
      const area = getTargetAvailableArea(oneTimePass.value?.availableArea?.areaId ?? '')
      return area?.storeOrAreaName
    })

    return {
      // OneTimePassConfirmPane
      state,
      oneTimePassAvailableStoreName,
      // ErrorStore
      errorStore,
      // oneTimePassPageStore
      oneTimePass,
      oneTimePassPlan: oneTimePassPlan as Ref<ContractPlanDocument>,
      ownContractInfo,
      currentLocationInfo,
      checkIsInAreaCanUseOneTimePass,
      getTargetAvailableArea,
      // userStore
      user,
      // useOneTimePassContract
      createOneTimePassContract,
      // useOneTimePassErrorHandling
      createContractError,
      /// useHistory
      saveContractInfoHistory,
      // useGeolocation
      getCurrentPosition,
      checkIsUserInArea,
      // useErrorHandling
      createGeolocationError,
    }
  },
  methods: {
    /**
     * サブミット時の処理
     */
    async handlerSubmit() {
      const loader = this.$loading.show()

      /**
       * サーキットなどの現地でのみ利用可能なワンタイムパスの場合の処理
       */
      const oneTimePassAvailableAreaId = this.oneTimePass?.additionalData?.availableArea?.areaId
      if (oneTimePassAvailableAreaId) {
        /** 現地でのみ利用可能なワンタイムパスの場合、以下の処理を行う */
        const result = await this.checkIsInAreaCanUseOneTimePass(oneTimePassAvailableAreaId)

        if (!result.isInArea) {
          // エリア判定失敗時の処理
          loader.hide()
          if (result.isDisplayLatLng) {
            // ワンタイムパス利用可能エリア外の場合、緯度経度情報などのエラーを表示する
            // 例外処理が発生した際には別のエラーモーダルが表示されるため、ワンタイムパス入力モーダル表示を切り替えない
            this.state.isShowLocationErrorModal = true
          }
          return
        }
      }

      /** ワンタイムパスで契約情報契約プランを登録 */
      const result = await this.createOneTimePassContract(this.ownContractInfo.contractInfoId)

      if (!result.isSuccess) {
        loader.hide()
        await this.createContractError(result.response?.data)
        // ワンタイムパス画面に遷移する
        this.$emit('handlerCancel')
        return
      }

      // 契約情報変更履歴を登録する
      await this.saveContractInfoHistory(this.ownContractInfo.contractInfoId as string, this.user)

      // Pusher通知が動かないことも一応想定し、fetchUserContractInfoを実行する
      const contractInfoResults = await ContractInfoStore.value.fetchUserContractInfo()
      const failedResult = contractInfoResults.find((v) => !v?.isSuccess)
      if (failedResult) {
        loader.hide()
        await MessageDialogStore.value.open({
          title: I18n.tc('common.errors.fetchUpdatedContractInfoError.title'),
          message: I18n.tc('common.errors.fetchUpdatedContractInfoError.message'),
        })
        // 契約情報取得に失敗した場合は、強制ログアウトする
        await this.$router.replace({ path: '/logout' })
      }

      this.$emit('handlerSubmit')
      loader.hide()
    },
    /**
     * キャンセル処理
     */
    handlerCancel() {
      this.$emit('handlerCancel')
    },
    /**
     * 位置情報エラーモーダルを閉じる
     */
    emitClose() {
      this.state.isShowLocationErrorModal = false
    },
  },
})
