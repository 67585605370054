








import { computed, defineComponent, inject, onBeforeMount } from '@vue/composition-api'
import { PluginApi } from 'vue-loading-overlay'
import RewardListSection from '@/components/RewardPage/RewardPane/RewardListSection.vue'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import I18n from '@/locales/I18n'
import StoreUtil from '@/store/StoreUtil'
import useRewards from '@/store/hook/videoPlatformContents/useRewards'
import useMembershipCard from '@/components/MypageTopPage/hook/useMembershipCard'

export default defineComponent({
  name: 'RewardPane',
  components: { RewardListSection },
  setup() {
    const loading = inject('loading') as PluginApi
    const mypagePageStore = StoreUtil.useStore('MypagePageStore')
    const contractInfoStore = StoreUtil.useStore('ContractInfoStore')
    const userStore = StoreUtil.useStore('UserStore')

    const { getCurrentRank } = useMembershipCard()
    const { fetchRewardContentsByAttachedDataId, fetchRankSpecifiedRewards, rewardDataList } =
      useRewards()
    const { fetchReceivedData, attachedDataIdList } = userStore

    const { isFreePlan } = contractInfoStore

    // 現在の会員ランク
    const currentRank = computed(() =>
      isFreePlan ? 'free' : getCurrentRank(mypagePageStore.currentOwnedPoints.value),
    )

    const targetRankRewards = computed(() => rewardDataList.value)

    onBeforeMount(async () => {
      const loader = loading.show()
      try {
        // ランク指定・特典画像データを取得する
        // 受信データを取得する
        const results = await Promise.all([
          fetchReceivedData(),
          fetchRankSpecifiedRewards(currentRank.value),
        ])

        // ユーザー指定・特典画像データを取得する
        const fetchRewardContentsResults = await fetchRewardContentsByAttachedDataId(
          attachedDataIdList.value,
        )

        loader.hide()

        const failedResult = [...results, ...fetchRewardContentsResults].find(
          (result) => result?.isSuccess === false,
        )

        if (failedResult) {
          await MessageDialogStore.value.open({
            title: I18n.tc('RewardPage.errors.fetchRewardPageDataError.title'),
            message: I18n.tc('RewardPage.errors.fetchRewardPageDataError.message'),
            errorApiResponse: failedResult.response,
          })
          return
        }
      } catch (e) {
        loader.hide()

        await MessageDialogStore.value.open({
          title: I18n.tc('RewardPage.errors.fetchRewardPageDataError.title'),
          message: I18n.tc('RewardPage.errors.fetchRewardPageDataError.message'),
        })
      }
    })

    return {
      rewardDataList: targetRankRewards,
    }
  },
})
