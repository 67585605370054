









import { defineComponent } from '@vue/composition-api'
import AppSignupTopPane from '@/components/AppSignupPage/AppSignupTopPane.vue'
import HeaderPane from '@/components/pc/common/Header/HeaderPane.vue'

/**
 * アプリ新規会員登録: 新規会員登録ページ
 */
export default defineComponent({
  name: 'AppSignupTopPage',
  components: { HeaderPane, AppSignupTopPane },
  methods: {
    moveToAppConfirmationSentPage() {
      /**
       * 認証メール送信画面へ遷移
       */
      this.$router.replace({ name: 'AppConfirmationSentPage' })
    },
  },
})
