
































import { defineComponent, reactive } from '@vue/composition-api'
import SubHeaderSection from '@/components/common/Header/SubHeaderSection.vue'
import MailInputSection from '@/components/SignupPage/EmailAuthenticationPane/MailInputSection.vue'
import ServiceButtonListParts from '@/components/AppSignupPage/AppSignupTopPane/parts/ServiceButtonListParts.vue'
import useUser from '@/store/hook/useUser'
import Const from '@/util/Const'
import MessageDialogStore from '@/store/stores/pageStore/common/MessageDialogStore'
import OAuthCodeFlowManager from '@/util/oauth/OAuthCodeFlowManager'
import useAppleSignInFlow from '@/components/AppSignupPage/hook/useAppleSignInFlow'

/**
 * アプリ新規会員登録: 新規会員登録ペインのコンポーネント
 */
export default defineComponent({
  name: 'AppSignupTopPane',
  components: { ServiceButtonListParts, MailInputSection, SubHeaderSection },
  setup() {
    const { appleOauthFlow } = useAppleSignInFlow()
    const { registerToken } = useUser()

    const state = reactive({
      isSendingMail: false,
    })

    /**
     * Appleの認可を開始する
     */
    const startAppleOauthFlow = () => appleOauthFlow()

    return {
      // AppSignupTopPane
      state,
      startAppleOauthFlow,
      // useUser
      registerToken,
    }
  },
  methods: {
    async onFormSubmit(email: string) {
      this.state.isSendingMail = true
      const loader = this.$loading.show()
      // ユーザー登録ページのURLに付与するトークンを生成し、入力したメールアドレス宛てにメール送信
      const result = await this.registerToken(Const.MEMBER_TYPE.FREE, 'MAIL_ADDRESS', email)
      this.state.isSendingMail = false
      loader.hide()
      if (!result.isSuccess) {
        await MessageDialogStore.value.open({
          title: this.$tc('SignupPage.common.errors.generateTokenError.title'),
          message: this.$tc('SignupPage.common.errors.generateTokenError.message'),
          errorApiResponse: result?.response,
        })
        return
      }

      // ボタンが押下されたことを通知する
      this.$emit('formSubmit')
    },
    /**
     * Googleの認可を開始する
     */
    async startGoogleOauthFlow() {
      OAuthCodeFlowManager.createOAuthCodeFlowManager('google')
      const oAuthCodeFlowManager = OAuthCodeFlowManager.getOAuthCodeFlowManager()
      oAuthCodeFlowManager?.startOauthCodeFlow(['profile', 'email'])
    },
  },
})
