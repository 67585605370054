import { computed, shallowReactive } from '@vue/composition-api'
import { unionWith, cloneDeep } from 'lodash'
import deepMerge from 'deepmerge'
import APIAccessor from '@/util/APIAccessor'
import APIResponse from '@/util/APIResponse'
import DocumentWrapper from '@/store/stores/collectionModule/documents/DocumentWrapper'
import LoginStore from '@/store/stores/loginStore/LoginStore'
import {
  BaseOptions,
  DeleteOptions,
  DeleteResponse,
  FetchOptions,
  initDeleteOptions,
  initFetchOptions,
  initSaveOptions,
  Response,
  SaveOptions,
  SaveResponse,
} from '@/store/stores/collectionModule/CollectionTypes'

/* eslint-disable class-methods-use-this */
class CollectionModule {
  createStore<T extends DocumentWrapper>(documentClass: { new (addProps?: Partial<T>): T }) {
    const state = shallowReactive({
      data: [] as T[],
    })

    const DocumentClass: { new (addProps?: Partial<T>): T } = documentClass
    /**
     *指定されたdocumentのインスタンス
     */
    const document: T = new DocumentClass()

    const setData = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dataList: any[],
      options?: { isUnionExistData: boolean; isAppendData: boolean },
    ) => {
      let result = [...dataList]
      if (options?.isUnionExistData) {
        result = unionWith(result, state.data, (newData, oldData) => newData.id === oldData.id)
      } else if (options?.isAppendData) {
        result = [...result, ...state.data]
      }
      state.data = result
    }

    /**
     * 取得してきたデータを空にする
     */
    const clearData = () => setData([])

    /**
     * アクセス先のURLを取得する
     * @param options オプション
     */
    const getUrl = (options: BaseOptions) => {
      if (options.url) {
        return options.url
      }
      const { baseUrl, baseSfgoApiUrl } = LoginStore.value

      if (document._isSfgoApi && !options.isSfgoApi) {
        // sfgo-web-apiのURLを生成する
        if (options.path) {
          return `${baseSfgoApiUrl}/${options.path}`
        }
        return `${baseSfgoApiUrl}/${document._path}`
      }

      const { orgId } = LoginStore.value
      if (!baseUrl || !orgId) return ''

      // fl-ux-apiのURLを生成する
      if (options.path) {
        return `${baseUrl}/${orgId}/${options.path}`
      }
      if (document._removeOrgPath) {
        return `${baseUrl}/${document._path}`
      }
      return `${baseUrl}/${orgId}/${document._path}`
    }
    /**
     * 主キーの値をキーにしたstate.data配列のMap
     */
    const dataMap = computed(() =>
      state.data.reduce((acc, cur) => {
        if (cur.id) {
          acc[cur.id] = cur as T
        }
        return acc
      }, Object.create(null) as Record<string, T>),
    )

    /**
     * 指定されたFetchOptionsを元に、デフォルト値などを設定したFetchOptionsを生成する。
     * @param options FetchOptions
     */
    const makeFetchOptions = (options: FetchOptions = {}) => {
      const initOptions = initFetchOptions()
      const _options = { ...initOptions, ...options }
      if (!options.query) {
        _options.query = {
          ..._options.query,
        }
      }
      if (_options.query && !_options.targetId) {
        // 1件取得以外のfetch処理の場合のデフォルトクエリ設定
        if (!_options.query.limit) {
          // limitが未指定の場合、デフォルト値を設定
          _options.query.limit = 100000
        }
        if (_options.query.filter) {
          if (
            !options.includeDeleted &&
            !Object.keys(_options.query.filter).includes('_deletedFlag')
          ) {
            // includeDeleted: false の場合、論理削除データは検索対象としない
            _options.query.filter._deletedFlag = { $ne: true }
          }
        } else if (!options.includeDeleted) {
          // filterの指定がない場合
          // includeDeleted: false の場合、論理削除データは検索対象としない
          _options.query.filter = { _deletedFlag: { $ne: true } }
        }
      }
      return _options
    }

    /**
     * createStore実行時に指定されたDocumentに対してfetch処理を行う。
     * オプションにlimitが未指定の場合、10000を設定する。
     * デフォルトでは、論理削除データは検索対象としない。オプションのfilterに、自動的に
     * <code>
     * _deletedFlag = { $ne: true }
     * </code>
     * を追加する。
     * 論理削除されたデータを含めたい場合、オプションの includeDeleted に true を指定する。
     * @param options fetch処理時に指定するオプション。詳細はFetchOptionsを参照のこと
     * @return fetch時に取得したデータとaxiosのレスポンスを返す
     */
    const fetch = async (options: FetchOptions = {}): Promise<Response<T>> => {
      const initOptions = initFetchOptions()
      const _options = { ...initOptions, ...options }
      if (!options.query) {
        _options.query = {
          ..._options.query,
        }
      }
      if (_options.query && !_options.targetId) {
        // 1件取得以外のfetch処理の場合のデフォルトクエリ設定
        if (!_options.query.limit) {
          if (_options.query.count) {
            _options.query.limit = 0
          } else if (_options.query.limit === 0) {
            // limitに0が指定されている場合はパラメータにlimitを含めない
            delete Object.assign(_options.query).limit
          } else {
            // limitが未指定の場合、デフォルト値を設定
            _options.query.limit = 10000
          }
        }
        if (_options.query.filter) {
          if (
            !options.includeDeleted &&
            !Object.keys(_options.query.filter).includes('_deletedFlag')
          ) {
            // includeDeleted: false の場合、論理削除データは検索対象としない
            _options.query.filter._deletedFlag = { $ne: true }
          }
        } else if (!options.includeDeleted) {
          // filterの指定がない場合
          // includeDeleted: false の場合、論理削除データは検索対象としない
          _options.query.filter = { _deletedFlag: { $ne: true } }
        }

        if (options.excludeFilter) {
          // filterをリクエストに含めない場合
          delete Object.assign(_options.query).filter
        }
      }

      const { accessToken, refreshToken } = LoginStore.value
      const { targetId } = _options
      /**
       * FetchOptionsに"url"か"path"が設定済の場合、urlまたはpathをベースにurlを生成した上接尾辞にidは付与しない。
       * FetchOptionsに"url"か"path"が未設定の場合、ドキュメントの_pathをベースにurlを生成した上
       *   targetIdがある場合、接尾辞にidを付与
       *   targetIdがない場合、接尾辞にidは付与しない。
       * @param param _options
       * @return url 生成したURL
       */
      const url = (param: FetchOptions) => {
        if (param.url || param.path) return getUrl(_options)
        const publicScopePath = param.includePublicScope ? '/public' : ''
        if (targetId) return `${getUrl(_options)}${publicScopePath}/${targetId}`
        return `${getUrl(_options)}${publicScopePath}`
      }
      let response: APIResponse | null = null

      if (!document._isSfgoApi && !_options.isSfgoApi) {
        // fl-ux-apiの場合
        response = await APIAccessor.request(
          {
            method: 'get',
            url: url(_options),
            query: _options.query,
          },
          accessToken,
        )
      } else {
        // sfgo-web-apiの場合
        let query
        const { query: originalQuery } = options

        if (_options.query?.additionalQuery) {
          const { additionalQuery, ...fluxQuery } = _options.query
          query = { ...additionalQuery, ...fluxQuery }
        } else {
          query = originalQuery // 分割代入した `originalQuery` を使用
        }
        response = await APIAccessor.sfgoApiRequest(
          {
            method: 'get',
            url: url(_options),
            query,
          },
          refreshToken,
        )
      }

      let data: Array<T> = []
      let isSuccess = false
      let count: number | null = null
      if (response.status === 200 && response.data) {
        isSuccess = true
        if (Array.isArray(response.data.results)) {
          data = response.data.results.map((d: Partial<T>) => new DocumentClass(d))
        } else {
          data[0] = new DocumentClass(response.data)
        }
        if (_options.isSaveInStore) {
          setData(data, {
            isUnionExistData: options.isUnionExistData ?? false,
            isAppendData: options.isAppendData ?? false,
          })
        }
        if (response.data.count != null) {
          // eslint-disable-next-line prefer-destructuring
          count = response.data.count
        }
      }
      return {
        isSuccess,
        data,
        count,
        response,
      }
    }

    /**
     * fetch処理を、並列で実行する
     * parallel: 10 limit: 100 の場合の処理フロー
     * （１）、pageが1から「10」までを並列で取得する
     * （２）、（１）で取得したデータの数が全て、100なら、ループを終了
     * （３）、次のpage文を並列で取得する
     * （４）、２と３をくり返す
     * @param fetchOptions fetchの処理のオプション
     * @param parallel fetch処理の並列数
     * @param limit 一回のfetchの上限
     * @return 並列で取得したデータとaxiosのレスポンスを、取得した回数分配列の状態で返す
     */
    const fetchSplit = async (
      fetchOptions: FetchOptions = {},
      parallel: number,
      limit: number,
    ): Promise<Array<Response<T>>> => {
      // fetch時にfetch結果のデータを既存のデータに追加するため、前回取得したデータに追加されないよう、データをクリアしておく
      clearData()

      let shouldLoopEnd = false
      let page = 0
      const results: Array<Response<T>> = []
      const promise: Array<Promise<Response<T>>> = []

      while (!shouldLoopEnd) {
        // limitとpageはこの処理をする上で必須なので、オプションを追加。
        // 取得したデータはそのままCollectionModule#stateに追加するため、isAppendData を true に指定する。
        // ※ isUnionExistData は、取得対象のデータが多い場合に処理速度が低下するため、利用しない。
        // なお、この分割データ取得中にデータが追加された場合に、
        // ページングによる全データ取得が正しく動作しなくなるため、createdDateの昇順でソートしてデータを取得する。
        const addOption = {
          query: {
            limit,
            page,
            sort: '_createdDate',
          },
          isAppendData: true,
        }

        // 引数で渡ってきたoptionを残しつつ、addOptionの内容を追加もしくは上書きする
        const options = deepMerge(fetchOptions, addOption)
        promise.push(fetch(options))

        if (promise.length === parallel) {
          // eslint-disable-next-line no-await-in-loop
          const apiResults = await Promise.all(promise)
          shouldLoopEnd = !apiResults.every((result) => result.data.length === limit)
          results.push(...apiResults)
          promise.length = 0
        }
        page += 1
      }
      return results
    }

    /**
     * filter: { [key]: { $in: dataArray } }のfetch処理を、並列で実行する
     * @param filterInKey filter対象のキー
     * @param filterInValue filter対象の配列データ
     * @param fetchOptions fetchの処理のオプション
     * @param parallel fetch処理の並列数
     * @param limit 一回のfetchの上限
     * @param clearOldData  実行時に、storeの値をクリアしてから実行するかどうか。初期値はtrue
     * @return 並列で取得したデータとaxiosのレスポンスを、取得した回数分配列の状態で返す
     */
    const fetchSplitFilterIn = async (
      fetchOptions: FetchOptions = {},
      parallel: number,
      limit: number,
      filterInKey: string,
      filterInValue: Array<string>,
      clearOldData = true,
    ): Promise<Array<Response<T>>> => {
      // fetch時にfetch結果のデータを既存のデータに追加するため、前回取得したデータに追加されないよう、データをクリアしておく
      if (clearOldData) clearData()

      let shouldLoopEnd = false
      let start = 0
      const results: Array<Response<T>> = []
      let promise: Array<Promise<Response<T>>> = []

      while (!shouldLoopEnd) {
        const targetFilterData = filterInValue.slice(start, start + limit)

        const addOption = {
          query: {
            filter: { [filterInKey]: { $in: targetFilterData } },
          },
          isAppendData: true,
        }

        // 引数で渡ってきたoptionを残しつつ、addOptionの内容を追加もしくは上書きする
        const options = deepMerge(fetchOptions, addOption)
        if (targetFilterData.length > 0) {
          promise.push(fetch(options))
        }

        if (promise.length === parallel || targetFilterData.length !== limit) {
          if (promise.length > 0) {
            // eslint-disable-next-line no-await-in-loop
            const apiResults = await Promise.all(promise)
            results.push(...apiResults)
            promise = []
          }

          shouldLoopEnd = targetFilterData.length !== limit
        }

        start += limit
      }
      return results
    }

    /**
     * createStore実行時に指定されたDocumentに対してsave処理を行う
     * @param savedDocument 保存するDocument。
     * @param options save処理時に指定するオプション。詳細はSaveOptionsを参照のこと
     * @param fetchOptions POSTリクエストで検索処理を行うAPIの場合にqueryなどのfetchオプションを指定する
     * @return save時に取得したデータとaxiosのレスポンスを返す
     */
    const save = async (
      savedDocument: T,
      options: SaveOptions = {},
      fetchOptions?: FetchOptions,
    ): Promise<SaveResponse<T>> => {
      const initOptions = initSaveOptions()
      const _options = { ...initOptions, ...options }
      const _fetchOptions = makeFetchOptions(fetchOptions)

      const { accessToken, refreshToken } = LoginStore.value
      let response: APIResponse | null = null
      const data = savedDocument.getAPISchema()

      if (savedDocument.id) {
        // idが設定されている場合はput処理を行う
        // SaveOptionsに"url"か"path"が設定済の場合、接尾辞にidは付与しない。
        const url =
          _options.url || _options.path
            ? getUrl(_options)
            : `${getUrl(_options)}/${savedDocument.id}`

        if (!document._isSfgoApi && !_options.isSfgoApi) {
          // fl-ux-apiの場合
          response = await APIAccessor.request(
            {
              method: 'put',
              url,
              data,
            },
            accessToken,
          )
        } else {
          // sfgo-web-apiの場合
          response = await APIAccessor.sfgoApiRequest(
            {
              method: 'put',
              url,
              data,
            },
            refreshToken,
          )
        }
      } else {
        // POST処理を行う
        // eslint-disable-next-line no-lonely-if
        if (!document._isSfgoApi && !_options.isSfgoApi) {
          // fl-ux-apiの場合
          response = await APIAccessor.request(
            {
              method: 'post',
              url: getUrl(_options),
              query: _fetchOptions.query,
              data,
            },
            accessToken,
          )
        } else {
          // sfgo-web-apiの場合
          response = await APIAccessor.sfgoApiRequest(
            {
              method: 'post',
              url: getUrl(_options),
              query: _fetchOptions.query,
              data,
            },
            refreshToken,
          )
        }
      }

      let resData
      let isSuccess = false
      // POST/PUTで成功ステータスが異なるため、正しく判定する
      if (response.status >= 200 && response.status < 300) {
        isSuccess = true
        if (_options.reload) {
          await fetch(_options.reloadOptions)
        }
        // put処理の場合は返り値が返ってこないのでput時に使用したDocumentを返す
        if (savedDocument.id) {
          resData = savedDocument
        } else {
          resData = new DocumentClass(response.data)
        }
      }
      return {
        isSuccess,
        data: (resData as Required<T>) || null,
        response,
      }
    }

    /**
     * createStore実行時に指定されたDocumentに対して並列でsave処理を行う
     * @param savedDocument 保存するDocument。
     * @param options save処理時に指定するオプション。詳細はSaveOptionsを参照のこと
     * @param splitField データ分割対象のフィールド
     * @param parallel fetch処理の並列数
     * @param limit 一回のfetchの上限
     * @param fetchOptions POSTリクエストで検索処理を行うAPIの場合にqueryなどのfetchオプションを指定する
     * @return save時に取得したデータとaxiosのレスポンスを返す
     */
    const saveSplit = async (
      savedDocument: T,
      options: SaveOptions = {},
      splitField: keyof T,
      parallel: number,
      limit: number,
      fetchOptions?: FetchOptions,
    ): Promise<Array<SaveResponse<T>>> => {
      let shouldLoopEnd = false
      let start = 0
      const results: Array<SaveResponse<T>> = []
      let promise: Array<Promise<SaveResponse<T>>> = []

      while (!shouldLoopEnd) {
        const splitFieldRequestData = Array.isArray(savedDocument[splitField])
          ? (savedDocument[splitField] as unknown as Array<T[keyof T]>).slice(start, start + limit)
          : []

        if (splitFieldRequestData.length > 0) {
          const requestData = cloneDeep(savedDocument)
          promise.push(
            save(
              Object.assign(requestData, { [splitField]: splitFieldRequestData }),
              options,
              fetchOptions,
            ),
          )
        }

        if (promise.length === parallel || splitFieldRequestData.length !== limit) {
          if (promise.length > 0) {
            // eslint-disable-next-line no-await-in-loop
            const apiResults = await Promise.all(promise)
            results.push(...apiResults)
            promise = []
          }

          shouldLoopEnd = splitFieldRequestData.length !== limit
        }

        start += limit
      }

      return results
    }

    /**
     * 指定したidのデータを削除する。
     * @param id 削除対象のID
     * @param options オプション
     */
    const remove = async (id: string, options: DeleteOptions = {}): Promise<DeleteResponse> => {
      const initOptions = initDeleteOptions()
      const _options = { ...initOptions, ...options }

      const { accessToken, refreshToken } = LoginStore.value

      let url = ''
      if (_options.url || _options.path) {
        url = getUrl(_options)
      } else {
        url = _options.isSoftDelete ? `${getUrl(_options)}/${id}/soft` : `${getUrl(_options)}/${id}`
      }

      let response: APIResponse | null = null

      if (!document._isSfgoApi && !_options.isSfgoApi) {
        // fl-ux-apiの場合
        response = await APIAccessor.request(
          {
            method: 'delete',
            url,
          },
          accessToken,
        )
      } else {
        // sfgo-web-apiの場合
        response = await APIAccessor.sfgoApiRequest(
          {
            method: 'delete',
            url,
          },
          refreshToken,
        )
      }

      let isSuccess = false
      if (response.status === 204) {
        isSuccess = true
        if (_options.reload) {
          await fetch(_options.reloadOptions)
        }
      }
      return {
        isSuccess,
        response,
      }
    }
    return {
      /**
       * fetch処理で取得した値
       * 単一のデータを取得する場合でも配列で取得されることに注意する
       */
      get data() {
        return state.data as T[]
      },
      /**
       * データを設定する。
       * CollectionModule#fetchを利用せずにデータ取得をした場合に利用する。
       * @param value Documentのリスト
       */
      set data(value: Array<T>) {
        state.data = value
      },
      fetch,
      fetchSplit,
      fetchSplitFilterIn,
      save,
      saveSplit,
      remove,
      dataMap,
      setData,
      clearData,
    }
  }
}

export default {
  createStore: new CollectionModule().createStore,
}
