var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-blocked-user-list-section" }, [
    _c(
      "ul",
      { staticClass: "mypage-blocked-user-list-section__list" },
      _vm._l(_vm.dummyListData, function (user) {
        return _c(
          "li",
          {
            key: user.id,
            staticClass: "mypage-blocked-user-list-section__item",
          },
          [_c("mypage-blocked-user-item-section", { attrs: { user: user } })],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "mypage-blocked-user-list-section__pagination" },
      [
        _c("pagination-section", {
          attrs: { totalPages: _vm.totalPage, currentPage: _vm.currentPage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }