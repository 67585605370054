



























import { defineComponent } from '@vue/composition-api'
import AtomIconRouterButton from '@/components/atoms/AtomIconRouterButton.vue'
import DeviceInfo from '@/util/DeviceInfo'

export default defineComponent({
  name: 'ServiceButtonListParts',
  components: { AtomIconRouterButton },
  setup() {
    const isiOS = DeviceInfo.isiOS()

    return {
      isiOS,
    }
  },
  methods: {
    /**
     * Googleの認可を開始する
     */
    startGoogleOauthFlow() {
      this.$emit('onStartGoogleOauthFlow')
    },
    /**
     * Appleの認可を開始する
     */
    onStartAppleOauthFlow() {
      this.$emit('onStartAppleOauthFlow')
    },
  },
})
