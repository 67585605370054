import { computed, reactive } from '@vue/composition-api'
import { StoreBase, ValueType } from '@/store/StoreBase'
import useUser from '@/store/hook/useUser'
import useContractInfo from '@/store/hook/useContractInfo'
import useContractPlan from '@/store/hook/useContractPlan'
import useContractInfoPlan from '@/store/hook/useContractInfoPlan'
import useContractInfoPlanCoupon from '@/store/hook/useContractInfoPlanCoupon'
import useCoupon from '@/store/hook/useCoupon'
import Const from '@/util/Const'
import { MemberType } from '@/store/stores/pageStore/SignupPage/SignupType'
import useStripe from '@/components/hook/useStripe'
import ContractPlanDocument, {
  PaidPlanGroupType,
} from '@/store/stores/collectionModule/documents/plan/ContractPlanDocument'
import useAvailableArea from '@/store/hook/useAvailableArea'
import useContractInfoCreditCard from '@/store/hook/useContractInfoCreditCard'
import useGoogleUserInfo from '@/store/hook/useGoogleUserInfo'
import type { AppleSignInResponse } from '@/components/hook/apple/useSignInWithApple'

/**
 * 初期状態
 */
const initialState = {
  flowPathMemberType: Const.MEMBER_TYPE.FREE,
  memberType: Const.MEMBER_TYPE.FREE,
  selectedPlanGroupId: 'annualPlan',
  paymentMethodId: '',
  inputtedCouponCode: '',
  couponPlan: null,
  selectableContractPlans: null,
  appleUserInfo: null,
}

/* eslint-disable class-methods-use-this */
/**
 * SFgoの会員登録画面のStore
 */
class SignupPageStore implements StoreBase {
  createStore() {
    const state = reactive({
      /**
       * 会員登録画面流入時の会員登録タイプ（ステップ1 認証メール送信画面に入ってきた時の会員登録タイプを保持）
       */
      flowPathMemberType: Const.MEMBER_TYPE.FREE as MemberType,
      /**
       * 会員登録タイプ
       */
      memberType: Const.MEMBER_TYPE.FREE as MemberType,
      /**
       * 選択中のプラン
       */
      selectedPlanGroupId: 'annualPlan' as PaidPlanGroupType,
      /**
       * 支払い方法ID
       */
      paymentMethodId: '',
      /**
       * ユーザーが入力したクーポンコード
       */
      inputtedCouponCode: '',
      /**
       * クーポンのプラン（正しいクーポンコードを入力した際に取得できる）
       */
      couponPlan: null as null | ContractPlanDocument,
      /**
       * 選択できるプラン一覧
       */
      selectableContractPlans: null as null | Array<ContractPlanDocument>,
      /**
       * Apple IDで認可したユーザー情報
       */
      appleUserInfo: null as null | AppleSignInResponse,
    })

    // hook
    const stripe = useStripe()
    const {
      registerToken,
      validateToken,
      registerUser,
      registerUserWithExternalLink,
      fetchUser,
      saveRegistrationTypeInProgress,
      clearUsers,
    } = useUser()
    const {
      fetchContractInfo,
      ownContractInfo,
      updateContractInfo,
      getContractInfoParams,
      clearContractInfo,
    } = useContractInfo()
    const { updateCreditCard } = useContractInfoCreditCard()
    const {
      fetchContractPlans,
      getCouponPlan,
      getCouponPlanWithCouponDocument,
      contractPaidPlans,
      selectedContractPlanIds,
      contractPlanIdForTrial,
      monthlyPlanPriceJpy,
      annualPlanPriceJpy,
      clearContractPlans,
    } = useContractPlan()
    const {
      fetchContractInfoPlans,
      contractInfoPlans,
      saveContractInfoPlan,
      updateContractInfoPlan,
      clearContractInfoPlans,
    } = useContractInfoPlan()
    const { saveContractInfoPlanCoupon } = useContractInfoPlanCoupon()
    const { fetchTargetCoupon, coupons, targetCoupon, clearCoupons } = useCoupon()
    const { fetchAvailableAreas, getTargetAvailableArea, clearAvailableAreas } = useAvailableArea()
    const { fetchGoogleUserInfo, googleUserInfo, clearGoogleUserInfo } = useGoogleUserInfo()

    /**
     * 会員登録画面流入時の会員登録タイプ
     * return { MemberType } 会員登録タイプ
     */
    const flowPathMemberType = computed({
      get: (): MemberType => state.flowPathMemberType,
      set: (type: MemberType) => {
        state.flowPathMemberType = type
      },
    })

    /**
     * 会員登録タイプ
     * return { MemberType } 会員登録タイプ
     */
    const memberType = computed({
      get: (): MemberType => state.memberType,
      set: (type: MemberType) => {
        state.memberType = type
      },
    })

    /**
     * 選択中の契約プラングループID
     * return { ContractPlanDocument } 契約プラン情報
     */
    const selectedPlanGroupId = computed({
      get: (): PaidPlanGroupType => state.selectedPlanGroupId,
      set: (planGroupId: PaidPlanGroupType) => {
        state.selectedPlanGroupId = planGroupId
      },
    })

    /**
     * 支払い方法ID
     * return { string } 支払い方法ID
     */
    const paymentMethodId = computed({
      get: (): string => state.paymentMethodId,
      set: (stripePaymentMethodId: string) => {
        state.paymentMethodId = stripePaymentMethodId
      },
    })

    /**
     * ユーザーが入力したクーポンコード
     * return { string } ユーザーが入力したクーポンコード
     */
    const inputtedCouponCode = computed({
      get: (): string => state.inputtedCouponCode,
      set: (couponCode: string) => {
        state.inputtedCouponCode = couponCode
      },
    })

    /**
     * クーポンのプラン（正しいクーポンコードを入力した際に取得できる）
     * return { ContractPlanDocument } 契約プラン情報
     */
    const couponPlan = computed({
      get: (): ContractPlanDocument | null => state.couponPlan as ContractPlanDocument | null,
      set: (plan: ContractPlanDocument | null) => {
        state.couponPlan = plan
      },
    })

    /**
     * 選択できるプラン一覧
     * 正しいクーポンコードが入力された場合、ここにクーポンのプランを追加する
     * return { Array<ContractPlanDocument> } 契約プラン情報
     */
    const selectableContractPlans = computed({
      get: (): Array<ContractPlanDocument> =>
        state.selectableContractPlans as Array<ContractPlanDocument>,
      set: (plans: Array<ContractPlanDocument>) => {
        state.selectableContractPlans = plans
      },
    })

    /**
     * Apple IDで認可したユーザー情報
     * return { Array<ContractPlanDocument> } Apple IDで認可したユーザー情報
     */
    const appleUserInfo = computed({
      get: (): AppleSignInResponse | null => state.appleUserInfo,
      set: (userInfo: AppleSignInResponse | null) => {
        state.appleUserInfo = userInfo
      },
    })

    /**
     * 選択中の契約プラン情報
     * return { ContractPlanDocument } 契約プラン情報
     */
    const getSelectedContractPlan = computed(() =>
      selectableContractPlans.value.find(
        (contractPlan) => contractPlan.planGroupId === state.selectedPlanGroupId,
      ),
    )

    /**
     * 会員登録ページのデータをクリアする
     */
    const clearSignupPageData = () => {
      clearUsers()
      clearContractInfo()
      clearContractPlans()
      clearContractInfoPlans()
      clearCoupons()
      clearAvailableAreas()
      clearGoogleUserInfo()
      Object.assign(state, initialState)
    }

    return {
      registerToken,
      validateToken,
      registerUser,
      registerUserWithExternalLink,
      fetchUser,
      saveRegistrationTypeInProgress,
      fetchContractInfo,
      ownContractInfo,
      updateContractInfo,
      getContractInfoParams,
      updateCreditCard,
      clearSignupPageData,
      fetchContractPlans,
      getCouponPlan,
      getCouponPlanWithCouponDocument,
      contractPaidPlans,
      selectedContractPlanIds,
      contractPlanIdForTrial,
      monthlyPlanPriceJpy,
      annualPlanPriceJpy,
      fetchContractInfoPlans,
      contractInfoPlans,
      saveContractInfoPlan,
      updateContractInfoPlan,
      saveContractInfoPlanCoupon,
      fetchTargetCoupon,
      coupons,
      targetCoupon,
      clearCoupons,
      fetchAvailableAreas,
      getTargetAvailableArea,
      stripe,
      flowPathMemberType,
      memberType,
      selectedPlanGroupId,
      paymentMethodId,
      inputtedCouponCode,
      couponPlan,
      selectableContractPlans,
      appleUserInfo,
      getSelectedContractPlan,
      fetchGoogleUserInfo,
      googleUserInfo,
    }
  }
}

const value: ValueType<SignupPageStore> = {}

export default {
  createStore: new SignupPageStore().createStore,
  value: value as Required<typeof value>,
}
