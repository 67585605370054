var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mypage-blocked-user-list-item" }, [
    _vm._m(0),
    _c("div", { staticClass: "mypage-blocked-user-list-item__main" }, [
      _c(
        "p",
        {
          staticClass: "mypage-blocked-user-list-item__type",
          class: "mypage-blocked-user-list-item__type--" + _vm.user.type,
        },
        [_vm._v(" " + _vm._s(_vm.roleLabels[_vm.user.type]) + " ")]
      ),
      _c("p", { staticClass: "mypage-blocked-user-list-item__name" }, [
        _vm._v(_vm._s(_vm.user.name)),
      ]),
    ]),
    _c("div", { staticClass: "mypage-blocked-user-list-item__tool" }, [
      _c(
        "button",
        {
          staticClass: "mypage-blocked-user-list-item__button",
          class: {
            "mypage-blocked-user-list-item__button--cancel": !_vm.isBlocking,
          },
          on: { click: _vm.handleClicked },
        },
        [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mypage-blocked-user-list-item__avatar" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/CanselPaidPlanPage/unusable01.jpg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }