var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isIncludeNextRelease
    ? _c("div", { staticClass: "mypage-blocked-user-list-page" }, [
        _c(
          "div",
          { staticClass: "mypage-blocked-user-list-page__header" },
          [
            _c("sub-header-section", {
              attrs: {
                pageTitle: _vm.$tc(
                  "MypagePage.MypageBlockedUserListPage.pageTitle"
                ),
                backLink: "/mypage",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mypage-blocked-user-list-page__body" },
          [_c("mypage-blocked-user-list-pane")],
          1
        ),
        _c(
          "div",
          { staticClass: "mypage-blocked-user-list-page__footer" },
          [_c("global-navigation-pane", { attrs: { current: "mypage" } })],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }