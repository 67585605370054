var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-coupon-plan-card-parts" }, [
    _c("div", { staticClass: "modal-coupon-plan-card-parts__card" }, [
      _c("div", { staticClass: "modal-coupon-plan-card-parts__main" }, [
        _c("p", { staticClass: "modal-coupon-plan-card-parts__title" }, [
          _vm._v(_vm._s(_vm.couponData.title)),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__subtitle" }, [
          _vm._v(_vm._s(_vm.couponData.subtitle)),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__description" }, [
          _vm._v(_vm._s(_vm.couponData.description)),
        ]),
        _c("p", { staticClass: "modal-coupon-plan-card-parts__period" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$tc(
                  "common.flowLineToPaidPlan.discount.oneTime.adaptationPeriod"
                )
              ) + "："
            ),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.couponData.period))]),
        ]),
      ]),
      _c("div", { staticClass: "modal-coupon-plan-card-parts__side" }, [
        _c("p", { staticClass: "modal-coupon-plan-card-parts__price" }, [
          _c("span", [_vm._v(_vm._s(_vm.couponData.price))]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$tc("common.flowLineToPaidPlan.discount.oneTime.priceUnit")
              )
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }