






















































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import I18n from '@/locales/I18n'
import FieldsetEmailParts from '@/components/common/form/FieldsetEmailParts.vue'
import FieldsetPasswordParts from '@/components/common/form/FieldsetPasswordParts.vue'
import FieldsetCheckboxParts from '@/components/common/form/FieldsetCheckboxParts.vue'
import ActionButtonsSection from '@/components/common/form/ActionButtonsSection.vue'
import AtomInputButton from '@/components/atoms/input/AtomInputButton.vue'
import FormErrorMessageParts from '@/components/common/form/FormErrorMessageParts.vue'
import Const from '@/util/Const'
import StoreUtil from '@/store/StoreUtil'

type InputDataType = {
  email: string
  password: string
  agreement: boolean
}

/**
 * 新規会員登録 会員情報入力セクション
 */
export default defineComponent({
  name: 'SignupFormSection',
  components: {
    FieldsetEmailParts,
    FieldsetPasswordParts,
    FieldsetCheckboxParts,
    ActionButtonsSection,
    AtomInputButton,
    FormErrorMessageParts,
  },
  props: {
    /**
     * 無料会員登録処理実行中かどうか
     */
    isRegistering: {
      type: Boolean,
      required: true,
    },
    /**
     * 登録トークン管理APIで発行されたトークンID
     */
    tokenId: {
      type: String,
      required: true,
    },
    /**
     * 認証メール送信画面で入力したメールアドレス
     */
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const signupPageStore = StoreUtil.useStore('SignupPageStore')

    const inputData = ref<InputDataType>({
      email: '',
      password: '',
      agreement: false,
    })

    const validationProvider = ref<InstanceType<typeof ValidationObserver>>()

    /** 以下の場合は「会員登録」ボタンを押せない
     * 1. バリデーションエラーがある
     * 2. パスワード未入力
     * 3. 規約未同意
     * 4. 無料会員登録処理実行中
     */
    const submitDisabled = computed(
      () =>
        Number(validationProvider.value?.errors.length) > 0 ||
        !inputData.value.password ||
        !inputData.value.agreement ||
        props.isRegistering,
    )
    const onAgreementChecked = (agreementValue: boolean) => {
      inputData.value.agreement = agreementValue
    }

    const submitText = computed(() =>
      signupPageStore.memberType.value === Const.MEMBER_TYPE.PAID
        ? I18n.tc('SignupPage.RegistrationPage.RegistrationButtonForPaid')
        : I18n.tc('SignupPage.RegistrationPage.RegistrationButton'),
    )

    watch(
      () => props.email,
      () => {
        inputData.value.email = props.email
      },
    )

    return {
      validationProvider,
      inputData,
      submitDisabled,
      onAgreementChecked,
      submitText,
    }
  },
  methods: {
    onFormSubmit() {
      if (Number(this.validationProvider?.errors.length) === 0) {
        /**
         * ボタンが押下されたことを通知する
         */
        this.$emit('formSubmit', this.inputData.password)
      }
    },
  },
})
