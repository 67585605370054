var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-overlay-section" }, [
    _c(
      "div",
      { staticClass: "loading-overlay-section__body" },
      _vm._l(_vm.progressList, function (progressItem, index) {
        return _c("loading-overlay-progress-parts", {
          key: "progress" + index,
          attrs: { progressItem: progressItem },
        })
      }),
      1
    ),
    _vm.cautionMessage
      ? _c("p", { staticClass: "loading-overlay-section__caution" }, [
          _vm._v(_vm._s(_vm.cautionMessage)),
        ])
      : _vm._e(),
    _vm.buttonLabel
      ? _c("div", { staticClass: "loading-overlay-section__buttons" }, [
          _c(
            "button",
            {
              staticClass: "loading-overlay-section__button",
              on: { click: _vm.handleButtonClicked },
            },
            [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }