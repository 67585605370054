import { computed } from '@vue/composition-api'
import CollectionModule from '@/store/stores/collectionModule/CollectionModule'
import VideoPlatformContentsDocument from '@/store/stores/collectionModule/documents/videoPlatformContents/VideoPlatformContentsDocument'
import useVideoPlatformContentsParams from '@/store/hook/videoPlatformContents/useVideoPlatformContentsParams'

/**
 * ヘッドライン、動画コンテンツを操作するための処理
 * NOTE: useVideoContentsとなっているが、ヘッドラインの情報もここで管理する
 */
export default function useVideoContents() {
  const videoContentsCollectionModule = CollectionModule.createStore(VideoPlatformContentsDocument)
  const autoLinkSfOfficialWebContentsCollectionModule = CollectionModule.createStore(
    VideoPlatformContentsDocument,
  )

  const { generateDisplayFilter } = useVideoPlatformContentsParams()

  /**
   * ヘッドライン、動画コンテンツ情報一覧取得
   */
  const fetchVideoContents = async () =>
    videoContentsCollectionModule.fetch({
      query: {
        filter: {
          $and: [generateDisplayFilter(), { videoType: null }],
        },
        // 作成日時が新しい順にソートする
        sort: '-_createdDate',
      },
    })

  /**
   * 自動で登録されたヘッドライン情報一覧取得
   * - 最大１０件まで表示するため、limit:10を指定している
   */
  const fetchAutoLinkSfOfficialWebContents = async () =>
    autoLinkSfOfficialWebContentsCollectionModule.fetch({
      query: {
        filter: { videoType: 'autoLinkSfOfficialWeb' },
        // 作成日時が新しい順にソートする
        // 連携処理で記事の公開日時を開始日時にセットしている
        sort: '-startDate',
        limit: 10,
      },
    })

  /**
   * ヘッドライン、動画コンテンツ情報一覧
   */
  const videoContents = computed(() => videoContentsCollectionModule.data)

  /**
   * 自動で登録されたヘッドライン情報一覧
   */
  const autoLinkSfOfficialWebContents = computed(
    () => autoLinkSfOfficialWebContentsCollectionModule.data,
  )

  /**
   * 取得したヘッドライン、動画コンテンツ情報をクリアする
   */
  const clearVideoContents = () => {
    videoContentsCollectionModule.clearData()
    autoLinkSfOfficialWebContentsCollectionModule.clearData()
  }

  return {
    fetchVideoContents,
    fetchAutoLinkSfOfficialWebContents,
    videoContents,
    autoLinkSfOfficialWebContents,
    clearVideoContents,
  }
}
