var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-confirmation-sent-pane" },
    [
      _c("sub-header-section", {
        attrs: { pageTitle: _vm.$tc("SignupPage.Steps.Step1") },
      }),
      _c(
        "div",
        { staticClass: "app-confirmation-sent-pane__main" },
        [
          _c("confirmation-sent-section", {
            on: { onMoveToEmailAuthentication: _vm.moveToEmailAuthentication },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }